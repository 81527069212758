import 'src/sections/homePageSections/FeedbackSection/FeedbackSection.scss'

import { FC } from 'react'
import baia from 'src/assets/images/clients/baia.svg'
import classicCarClub from 'src/assets/images/clients/classic-car-club.svg'
import slide2 from 'src/assets/images/clients/guild-preview.png'
import slideDesktop2 from 'src/assets/images/clients/guild-preview.png'
import guildRow from 'src/assets/images/clients/guild.svg'
import SwiperCore, { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import feedbackPattern1 from './images/feedback-pattern-1.png'
import slide1 from './images/feedback-slide-image-1.jpg'
import slide3 from './images/feedback-slide-image-3.jpg'
import slideDesktop3 from './images/feedback-slide-image-3.jpg'
import slideDesktop1 from './images/feedback-slider-image-desktop-1.jpg'
import { ReactComponent as NavArrow } from './images/swiper-nav-arrow.svg'
import { useFeedbacks } from '../../../components/App/Feedbacks'

SwiperCore.use([Pagination, EffectCoverflow, Autoplay, Navigation])

type IFeature = {
  image: string[]
  companyLogo: string
  personName: string
  clientName: string
  feedbackText: string[]
  isInvert?: boolean | undefined
}

const feedback: IFeature[] = [
  {
    image: [slide1, slideDesktop1],
    companyLogo: classicCarClub,
    personName: 'Zac Moseley',
    clientName: 'Director and Owner — Classic Car Club Manhattan',
    feedbackText: [
      'When we were looking at CRM solutions, like Salesforce and HubSpot, these tools were more directly sales-oriented. PeopleVine had more features for Membership Management than anyone else.',
    ],
  },
  {
    image: [slide2, slideDesktop2],
    companyLogo: guildRow,
    personName: 'Mike Healy',
    clientName: 'Principal — Guild Row',
    isInvert: true,
    feedbackText: [
      'We are in the business of building community around hands-on making classes and experiences. For our members, for our staff, and for ourselves it is hard to imagine a more powerful tool than PeopleVine.',
    ],
  },
  {
    image: [slide3, slideDesktop3],
    companyLogo: baia,
    personName: 'Betsy Fabian',
    clientName: 'Membership Manager — Baia Beach Club',
    isInvert: true,
    feedbackText: [
      'So you as a member, as our customer, can access all of your membership information, you can access all of your offerings, all of our restaurants, you can book appointments, you can order drinks to the pool,  you can... you name it. You can access all of your \nmembership benefits through Peoplevine.',
      'That’s from the member side, but for us mainly, it’s going to be our database, \nit’s going to be our Google, where we store and find everything about our members.',
    ],
  },
]

type FeedbackText = {
  data: string[]
}

const FeedbackText: FC<FeedbackText> = ({ data = [] }) => {
  const textLength = data?.reduce((a, b) => a + b, '')
  const className = (textLength ?? '')?.length > 350 ? 'swiper-slide__text long' : 'swiper-slide__text'

  return (
    <p className={className}>
      {data.map((item, index) => (
        <span className='text' key={index}>
          {item}
          {index === data.length - 1 && <span className='swiper-slide__quotes'>“</span>}
        </span>
      ))}
    </p>
  )
}

export type FeedbackSectionProps = {
  theme?: 'light' | 'grey' | 'dark'
  hasTitle?: boolean
}

export const FeedbackSection: FC<FeedbackSectionProps> = ({ theme = 'dark', hasTitle = false }) => {
  const feedbackContent = useFeedbacks()

  return (
    <section className={`feedback feedback-${theme}`}>
      <div className='ornament-block'>
        <img src={feedbackPattern1} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='feedback__wrapper'>
          {hasTitle && <h2 className='feedback__title section-main-title'>Testimonials</h2>}
          <div className='feedback-swiper-pagination' />
          <div className='feedback-slider'>
            <Swiper
              effect='coverflow'
              spaceBetween={200}
              slidesPerView={1}
              // loop={true}
              navigation={{
                nextEl: '.feedback .swiper-nav .swiper-nav-button--next',
                prevEl: '.feedback .swiper-nav .swiper-nav-button--prev',
              }}
              // autoplay={{ delay: 10000 }}
              speed={600}
              pagination={{
                clickable: true,
                el: '.feedback-swiper-pagination',
                renderBullet: function (index, className) {
                  const clientsList = ['Classic Car Club Manhattan', 'Zero Bond', 'MILĀ']
                  return `<span class='${className}'><span class='swiper-pagination-bullet__text'>${clientsList[index]}</span></span>`
                },
              }}
            >
              {feedbackContent?.map((item, index) => {
                const textArr = item?.feedbackText?.map(item => item?.text ?? '')
                return (
                  <SwiperSlide key={item?.id}>
                    <div className='swiper-slide__picture'>
                      <img src={item?.mobile_slide?.url} alt='client-image' className='swiper-slide__image swiper-slide__image--mobile' />
                      <img src={item?.decktop_slide?.url} alt='client-image' className='swiper-slide__image swiper-slide__image--desktop' />
                    </div>
                    <div className='swiper-slide__content'>
                      <img src={item?.logo?.url} alt='club-logo' className={`swiper-slide__logo ${item?.isInvert ? 'inverted' : ''}`} />
                      <h5 className='swiper-slide__person-name'>{item?.person}</h5>
                      <p className='swiper-slide__client-name'>{item?.client}</p>
                      {textArr && <FeedbackText data={textArr} />}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <div className='swiper-nav'>
              <span className='swiper-nav-button swiper-nav-button--prev'>
                <NavArrow className='swiper-nav-button__icon' />
              </span>
              <span className='swiper-nav-button swiper-nav-button--next'>
                <NavArrow className='swiper-nav-button__icon' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
