import './Pricing.scss'

import { FC } from 'react'
import Button from '../../components/Form/Button'
import { patternGenerator } from '../../components/Patterns/patternsGenerator'

export const Pricing: FC = () => {
  const patterns = patternGenerator({ quantity: 8, theme: 'dark' })

  return (
    <div className='pricing-head pricing-head-capterra' role={'presentation'}>
      <div className='ornament-block'>{patterns}</div>
      <div className='container'>
        <div className='pricing-head__wrapper'>
          <h1 className='pricing-head__title main-title'>Pricing</h1>
          <div className='pricing-head-block'>
            <div className='pricing-head-plan'>
              <div className='pricing-head-plan__title'>
                <h5 className='pricing-head-plan__name section-tertiary-title'>Base</h5>
              </div>
              <p className='pricing-head-plan__text'>All the power of Peoplevine ready to go out-the-box for the DIY user.</p>
              <ul className='pricing-head-plan__list'>
                <li className='pricing-head-plan__list-item'>Branded Portal</li>
                <li className='pricing-head-plan__list-item'>Standard Data Migration</li>
                <li className='pricing-head-plan__list-item'>Email and Chat Support</li>
              </ul>
              <p className='pricing-head-plan__price'>
                <span className='pricing-head-plan__price-amount section-secondary-title'>$675</span>
                <span className='pricing-head-plan__price-period'>/mo</span>
              </p>
              <Button to={'/form'}>Request a demo</Button>
            </div>
            <div className='pricing-head-plan'>
              <div className='pricing-head-plan__title'>
                <h5 className='pricing-head-plan__name section-tertiary-title'>Plus</h5>
                <span className='pricing-head-plan__label capture'>Most popular</span>
              </div>
              <p className='pricing-head-plan__text'>
                Go beyond and experience true partnership and full support from the Peoplevine team with Peoplevine Plus plans.
              </p>
              <ul className='pricing-head-plan__list'>
                <li className='pricing-head-plan__list-item'>Custom Member Portal</li>
                <li className='pricing-head-plan__list-item'>Branded Mobile App</li>
                <li className='pricing-head-plan__list-item'>3rd Party Integrations</li>
                <li className='pricing-head-plan__list-item'>Access to APIs</li>
                <li className='pricing-head-plan__list-item'>Dedicated Support</li>
              </ul>
              <Button to={'/form'}>Contact sales</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
