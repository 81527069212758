import clubs from 'src/assets/icons/businesses/private-clubs.svg'
import hotels from 'src/assets/icons/businesses/boutique-hotels.svg'
import restaurants from 'src/assets/icons/businesses/icon_restaurant.svg'
import { ReferredField } from '../interfaces'
// import coworking from 'src/assets/icons/businesses/coworking.svg'
// import hospitality from 'src/assets/icons/businesses/non-hospitality.svg'
import network from 'src/assets/icons/amenities/network.svg'
import flower from 'src/assets/icons/amenities/flower.svg'
import planning from 'src/assets/icons/amenities/planning.svg'
import ahc from 'src/assets/icons/amenities/ahc-direct.svg'
import houseaccount from 'src/assets/icons/amenities/house-accountv2.svg'
import creditcard from 'src/assets/icons/amenities/credit-card.svg'
import globe from 'src/assets/icons/amenities/children.svg'
import ticket from 'src/assets/icons/amenities/ticket.svg'
import other from 'src/assets/icons/amenities/group2.svg'
import hand from 'src/assets/icons/amenities/hand.svg'
import room from 'src/assets/icons/amenities/room_service.svg'
import ReCAPTCHA from "react-google-recaptcha";
import { NonceProvider } from 'react-select'
export const VALUES_SCREEN_ONE = [
    'business',
    'firstName',
    'lastName',
    'phone',
    'email',
    'company',
    'website',
    'country',
    'role',
    'referral',
    'referral_client',
    'referred_by',
    'timeline'
]

export const TOTAL_MEMBERS = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1-150',
        label: '1-150',
    },
    {
        value: '151-500',
        label: '151-500',
    },
    {
        value: '500-2000',
        label: '500-2000',
    },
    {
        value: '2000+',
        label: '2000+',
    },
]

export const BOOKABLE_AMENITIES = [
    {
        value: 'Dining Reservations',
        label: 'Dining Reservations',
    },
    {
        value: 'Events/Programming',
        label: 'Events/Programming',
    },
    {
        value: 'Spa Services',
        label: 'Spa Services',
    },
    {
        value: 'Activities/Wellness',
        label: 'Activities/Wellness',
    },
    {
        value: 'Amenities',
        label: 'Amenities',
    },
    {
        value: 'Workspaces/Conference Rooms',
        label: 'Workspaces/Conference Rooms',
    },
    {
        value: 'Hotel Rooms',
        label: 'Hotel Rooms',
    },
]

export const businesses = [
    {
        id: 1,
        title: "Private Members' Clubs",
        image: clubs,
    },
    {
        id: 2,
        title: 'Luxury Hotels & Residences',
        image: hotels,
    },
    {
        id: 3,
        title: 'Dining Club & Restaurant VIPs',
        image: restaurants,
    },
    // {
    //   id: 4,
    //   title: 'Coworking',
    //   image: coworking,
    // },
    // {
    //   id: 5,
    //   title: 'Other',
    //   image: hospitality,
    // },
]

export const INTERESTED_OFFERING = [
    {
        value: 'Yes',
        label: 'Yes',
    },
    {
        value: 'No',
        label: 'No',
    },
]
export const POS_SYSTEMS = [
    {
        value: 'Micros Simphony',
        label: 'Micros Simphony',
    },
    {
        value: 'Toast',
        label: 'Toast',
    },
    {
        value: 'Lightspeed',
        label: 'Lightspeed',
    },
    {
        value: 'Square',
        label: 'Square',
    },
]
export const PMS_SYSTEMS = [
    {
        value: 'Opera Cloud',
        label: 'Opera Cloud',
    },
    {
        value: 'Mews',
        label: 'Mews',
    }
]
export const FB_SYSTEMS = [
    {
        value: 'SevenRooms',
        label: 'SevenRooms',
    }
]
export const SPA_SYSTEMS = [
    {
        value: 'Book4Time',
        label: 'Book4Time',
    }
]
export const ACCESS_CONTROL_SYSTEM = [
    {
        value: 'Salto',
        label: 'Salto',
    },
    {
        value: 'VizPin',
        label: 'VizPin',
    }
]

export const WISH_TO_ACCEPT_PAYMENTS = [
    {
        value: 'Credit card via Stripe',
        label: 'Credit card via Stripe',
    },
    {
        value: 'ACH/Direct Debit via GoCardless',
        label: 'ACH/Direct Debit via GoCardless',
    },
    {
        value: 'House account w/ 30-day invoice charging',
        label: 'House account w/ 30-day invoice charging',
    }
]

export const REFERRED_FIELDS: ReferredField[] = [
    {
      name: "referral_client",
      title: "Referral Client",
      placeholder: 'Enter referral client'
    },
    {
      name: "referred_by",
      title: "Referred By",
      placeholder: 'Enter referred by'
    },
  ]

  export const memberData = {
    "data": [
      { "id": 1, "name": "None" },
      { "id": 2, "name": "100" },
      { "id": 3, "name": "250" },
      { "id": 4, "name": "500" },
      { "id": 5, "name": "1000" },
      { "id": 6, "name": "+2500" }

    ]
  }
  export const propertyType = {
    "data": [
      { "id": 1, "name": "New" },
      { "id": 2, "name": "Existing" },
    ]
  }
  export const onboardingProcess = [
    {
      value: 'Self-signup and Pay',
      label: 'Self-signup and Pay',
    },
    {
      value: 'Member Application and Approval',
      label: 'Member Application and Approval',
    },
  ]

  export const amenitiesStrucure = [
    { id: 1, title: "Meeting rooms/workspaces", image: network },
    { id: 2, title: "Spa services", image: flower },
    { id: 3, title: "Appointments", image: planning },
    { id: 4, title: "Activities", image: globe },
    { id: 5, title: "Events", image: ticket },
    { id: 6, title: "Wellness classes", image: hand },
    { id: 7, title: "Private dining rooms", image: room },
    { id: 8, title: "Other", image: other }
  ];

  export const paymentsStructure = [
    { id: 1, title: "Credit Card<br> (requires Stripe account)", image: creditcard },
    { id: 2, title: "ACH/Direct<br> (requires GoCardless)", image: ahc },
    { id: 3, title: "House Account<br> (30 day invoicing)", image: houseaccount },
  ];

  export const accessControl = [
    { id: 1, title: "Hikvision", image: creditcard },
    { id: 2, title: "Salto", image: ahc },
    { id: 3, title: "Not Sure", image: houseaccount },
    { id: 4, title: "None", image: houseaccount },
  ];

  export const pointofSale = [
    {
      value: 'Lightspeed',
      label: 'Lightspeed',
    },
    {
      value: 'Micros Simphony',
      label: 'Micros Simphony',
    },
    {
      value: 'Toast',
      label: 'Toast',
    },
    {
      value: 'Not Sure',
      label: 'Not Sure',
    },
    {
      value: 'None',
      label: 'None',
    }
  ]

  export const propertyManagement = [
    {
      value: 'Mews',
      label: 'Mews',
    },
    {
      value: 'Opera Cloud',
      label: 'Opera Cloud',
    },
    {
      value: 'Not Sure',
      label: 'Not Sure',
    },
    {
      value: 'None',
      label: 'None',
    }
  ]

  export const bookingSystem = [
    {
      value: 'Book4time',
      label: 'Book4time',
    },
    {
      value: 'None',
      label: 'None',
    }
  ]

  export  const reservationSystem = [

    {
      value: 'Sevenrooms',
      label: 'Sevenrooms',
    },

    {
      value: 'Tablecheck',
      label: 'Tablecheck',
    },

    {
      value: 'Tripleseat',
      label: 'Tripleseat',
    },
    {
      value: 'Not Sure',
      label: 'Not Sure',
    },
    {
      value: 'None',
      label: 'None',
    }
  ]

  export const referredFields: ReferredField[] = [
    {
      name: "referral_client",
      title: "Referral Client",
      placeholder: 'Enter referral client'
    },
    {
      name: "referred_by",
      title: "Referred By",
      placeholder: 'Enter referred by'
    },
  ]