import { FC } from 'react'

import { patternGenerator } from '../../components/Patterns/patternsGenerator'
import Blog from '../../sections/blogPageSections/Blog/Blog'
import Heading from '../../sections/blogPageSections/Heading/Heading'

const BlogPage: FC = () => {
  const patterns = patternGenerator({ quantity: 15, theme: 'light' })

  return (
    <div className='blog-wrapper'>
      <div className='ornament-block'>{patterns}</div>
      <div className='container'>
        <section className='blog'>
          <Heading />
          <Blog />
        </section>
      </div>
    </div>
  )
}

export default BlogPage
