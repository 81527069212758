import './TwoSidesPlatformSection.scss'

import { FC } from 'react'
import platformDashboardPrimary from 'src/assets/images/crm-preview/events-left.png'
import crmPrimary from 'src/assets/images/crm-preview/people-list.png'
import twoSidesPlatformPattern2 from 'src/sections/homePageSections/TwoSidesPlatformSection/images/two-sides-pattern-2.png'
import twoSidesPlatformPattern3 from 'src/sections/homePageSections/TwoSidesPlatformSection/images/two-sides-pattern-3.png'

type TwoSidesPlatformSectionProps = {
  hasList?: boolean
}

export const TwoSidesPlatformSection: FC<TwoSidesPlatformSectionProps> = ({ hasList = false }) => (
  <section className='two-sides-platform capterra' role={'presentation'}>
    <div className='two-sides-platform-part two-sides-platform-part--dark'>
      <div className='ornament-block'>
        <img src={twoSidesPlatformPattern2} alt='ornament' className='ornament-block__image' />
        <img src={twoSidesPlatformPattern3} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='two-sides-platform-part__wrapper'>
          <h2 className='two-sides-platform-part__title section-main-title'>A Two-Sided Platform in the cloud</h2>
          <p className='two-sides-platform-part__text'>
            <span>
              PeopleVine is designed specifically to serve modern hospitality businesses. We are driven by experiences and culture. Our
              platform is designed to help you reach your goals the most efficient way possible.
            </span>
          </p>
          <div className='two-sides-platform-part-block'>
            <div className='two-sides-platform-part-block-item'>
              <h3 className='two-sides-platform-part-block-item__title section-secondary-title'>For ops teams</h3>
              <p className='two-sides-platform-part-block-item__text'>
                {hasList
                  ? 'Your CRM is the central hub to view member:'
                  : 'Your centralized hub for member management: ease your workflow, gain insights, and grow your business.'}
              </p>
              {hasList && (
                <ul className='two-sides-platform__list'>
                  <li className='two-sides-platform__list-item'>Member Portal</li>
                  <li className='two-sides-platform__list-item'>Member App</li>
                  <li className='two-sides-platform__list-item'>Card-on-file payments</li>
                </ul>
              )}
            </div>
            <div className='two-sides-platform-part-block-picture'>
              <img
                src={platformDashboardPrimary}
                alt='dashboard'
                className='two-sides-platform-part-block-picture__image two-sides-platform-part-block-picture__image--desktop'
              />
            </div>
            <div className='two-sides-platform-part-block-item'>
              <h3 className='two-sides-platform-part-block-item__title section-secondary-title'>For Members</h3>
              <p className='two-sides-platform-part-block-item__text'>
                The digital space of your business with customizable touchpoints through which your members can enroll, engage, and transact
              </p>
              {hasList && (
                <ul className='two-sides-platform__list'>
                  <li className='two-sides-platform__list-item'>Member Portal</li>
                  <li className='two-sides-platform__list-item'>Member App</li>
                  <li className='two-sides-platform__list-item'>Card-on-file payments</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='two-sides-platform-part two-sides-platform-part--light'>
      <div className='ornament-block'>
        <img src={twoSidesPlatformPattern2} alt='ornament' className='ornament-block__image' />
        <img src={twoSidesPlatformPattern3} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='two-sides-platform-part__wrapper'>
          <h2 className='two-sides-platform-part__title section-main-title'>A Two-Sided Platform in the cloud</h2>
          <p className='two-sides-platform-part__text'>
            <span>
              PeopleVine is designed specifically to serve modern hospitality businesses. We are driven by experiences and culture. Our
              platform is designed to help you reach your goals the most efficient way possible.
            </span>
          </p>
          <div className='two-sides-platform-part-block'>
            <div className='two-sides-platform-part-block-item'>
              <h3 className='two-sides-platform-part-block-item__title section-secondary-title'>For ops teams</h3>
              <p className='two-sides-platform-part-block-item__text'>
                {hasList
                  ? 'Your CRM is the central hub to view member:'
                  : 'Your centralized hub for member management: ease your workflow, gain insights, and grow your business.'}
              </p>
              {hasList && (
                <ul className='two-sides-platform__list'>
                  <li className='two-sides-platform__list-item'>Preferences & Attributes</li>
                  <li className='two-sides-platform__list-item'>Segmentation</li>
                  <li className='two-sides-platform__list-item'>Lifetime Spend</li>
                  <li className='two-sides-platform__list-item'>Reporting and Analytics</li>
                </ul>
              )}
            </div>
            <div className='two-sides-platform-part-block-picture'>
              <img
                src={crmPrimary}
                alt='dashboard'
                className='two-sides-platform-part-block-picture__image two-sides-platform-part-block-picture__image--desktop'
              />
            </div>
            <div className='two-sides-platform-part-block-item'>
              <h3 className='two-sides-platform-part-block-item__title section-secondary-title'>For Members</h3>
              <p className='two-sides-platform-part-block-item__text'>
                The digital space of your business with customizable touchpoints through which your members can enroll, engage, and transact
              </p>
              {hasList && (
                <ul className='two-sides-platform__list'>
                  <li className='two-sides-platform__list-item'>Preferences & Attributes</li>
                  <li className='two-sides-platform__list-item'>Segmentation</li>
                  <li className='two-sides-platform__list-item'>Lifetime Spend</li>
                  <li className='two-sides-platform__list-item'>Reporting and Analytics</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
