import 'src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection.scss'

import { FC } from 'react'

import { useContent } from '../../../components/App/Content'
import Button from '../../../components/Form/Button'

type IExperience = {
  image?: string
  isAnimated?: boolean
  slug?: string
  customClass?: string
}

export const CustomerExperienceSection: FC<IExperience> = ({ 
  image, 
  isAnimated = true,
  slug = 'home-customer-experience',
  customClass
}) => {
  const content = useContent(slug)

  return (
    <section className={`customer-experience ${customClass}`} role={`${isAnimated ? 'presentation' : ''}`}>
      { image && (
        <div className='customer-experience__picture'>
          <img src={image} alt='people eating' className='customer-experience__image' />
        </div>
      )}
      <div className='container'>
        <div className='customer-experience__wrapper'>
          <div className='customer-experience-block'>
            <h2 className='customer-experience-block__title main-title'>{content?.description}</h2>
            <Button to={content?.button?.link?.url}>{content?.button?.link?.title}</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
