import { FC } from 'react'
import DefaultLayout from 'src/layouts'
import Hero from 'src/sections/developerPageSections/Hero/Hero'
import Resources from 'src/sections/developerPageSections/Resources/Resources'

import { DoYouIntegrateSection } from '../../sections/productPageSections/DoYouIntegrateSection/DoYouIntegrateSection'

const DeveloperPage: FC = () => {
  return (
    <DefaultLayout isHeaderFixed={true} isFooterAnimated={false}>
      <Hero />
      <Resources />
      <DoYouIntegrateSection text={`Can we build it with`} />
    </DefaultLayout>
  )
}

export default DeveloperPage
