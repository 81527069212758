import './ContactForm.scss'

import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import * as Yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg'
import clubs from 'src/assets/icons/businesses/private-clubs.svg'
import hotels from 'src/assets/icons/businesses/boutique-hotels.svg'
import residentials from 'src/assets/icons/businesses/icon_restaurant.svg'
// import coworking from 'src/assets/icons/businesses/coworking.svg'
// import hospitality from 'src/assets/icons/businesses/non-hospitality.svg'
import countries from 'src/data/countries.json'
import Select from 'react-select'
import { patternGenerator } from '../../components/Patterns/patternsGenerator'
import { useSurveyApi } from '../../components/Email'
import ReactGA from 'react-ga'

type SelectTypes = {
  value: string
  label: string
}

export type FormValues = {
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  company: string,
  website: string,
  city: string,
  role: string,
  state: string,
  enroll: string,
  country: string,
  timeline: string,
  referral: string,
  referral_client: string,
  new_property: string,
 // system_used: string,
  membership_type: string,
  member_count: string,
  otherAmenity: string,
  point_of_sale: string,
  property_management_system: string,
  reservation_system: string,
  booking_system: string,
  member_approval: string,
  referred_by: string,

}

export type BusinessesTypes = {
  id: number
  title: string
  image: string
}

export const roles = [
  {
    value: 'general-manager',
    label: 'General Manager',
  },
  {
    value: 'it',
    label: 'IT',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'memberships',
    label: 'Memberships',
  },
  {
    value: 'operations',
    label: 'Operations',
  },
  {
    value: 'owner',
    label: 'Owner',
  },
  {
    value: 'other',
    label: 'Other',
  }
];


export const timelines = [
  {
    value: '1–3 months',
    label: '1–3 months',
  },
  {
    value: '3–6 months',
    label: '3–6 months',
  },
  {
    value: '6+ months',
    label: '6+ months',
  },
]

export const referrals = [
  {
    value: 'Book4Time',
    label: 'Book4Time',
  },
  {
    value: 'Client Referral',
    label: 'Client Referral',
  },
  {
    value: 'Conference',
    label: 'Conference',
  },
  {
    value: 'GoCardless',
    label: 'GoCardless',
  },
  {
    value: 'Mews',
    label: 'Mews',
  },
  {
    value: 'Micros Simphony',
    label: 'Micros Simphony',
  },
  {
    value: 'NCR Aloha',
    label: 'NCR Aloha',
  },
  {
    value: 'OpenTable',
    label: 'OpenTable',
  },
  {
    value: 'Opera',
    label: 'Opera',
  },
  {
    value: 'Search',
    label: 'Search',
  },
  {
    value: 'SevenRooms',
    label: 'SevenRooms',
  },
  {
    value: 'Social Media',
    label: 'Social Media',
  },
  {
    value: 'Square',
    label: 'Square',
  },
  {
    value: 'Stanza Podcast',
    label: 'Stanza Podcast',
  },
  {
    value: 'Stripe',
    label: 'Stripe',
  },
  {
    value: 'Toast',
    label: 'Toast',
  },
  {
    value: 'Word of Mouth',
    label: 'Word of Mouth',
  },
];

export const businesses = [
  {
    id: 1,
    title: "Private Social Members' Club",
    image: clubs,
  },
  {
    id: 2,
    title: 'Luxury Hotels & Branded Residences',
    image: hotels,
  },
  {
    id: 3,
    title: 'Dining Club & Restaurant VIPs',
    image: residentials,
  },
  // {
  //   id: 4,
  //   title: 'Coworking',
  //   image: coworking,
  // },
  // {
  //   id: 5,
  //   title: 'Other',
  //   image: hospitality,
  // },
]

type SelectFieldProps = {
  options: SelectTypes[]
  placeholder?: string
  required?: boolean
  onChangeCallback?: (value: string) => void | null
}

const colorStyles = {
  menuList: styles => ({
    ...styles,
    background: '#202123',
    border: '1px solid #55555e',
    borderRadius: '4px',
    "::-webkit-scrollbar": {
      width: "10px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#55555e"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#2d3a37"
    }
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: '#81848e',
    cursor: 'pointer',
    background: isFocused ? '#2d3a37' : isSelected ? '#2d3a37' : undefined,
    zIndex: 1,
  }),
}

export const SelectField: FC<FieldProps & SelectFieldProps> = ({ options = [], field, form, placeholder, required = false, onChangeCallback = null }) => (
  <div className='input-select'>
    {placeholder && (
      <span className='input-select-label'>
        {placeholder}
        {required && <span className='login-form__label-required'> *</span>}
      </span>
    )}
    <Select
      className={`input-select-wrapper ${form?.errors[field.name] && 'login-form__select-field--error'}`}
      captureMenuScroll={true}
      options={options}
      name={field.name}
      value={options.find(option => option.value === field.value.value)}
      onChange={option => {
        form.setFieldValue(field.name, option?.value)

        if (onChangeCallback) onChangeCallback(option?.value!)
      }}
      onBlur={field.onBlur}
      styles={colorStyles}
    />
    <ErrorMessage name={field.name} component='span' className='login-form__error-message' />
  </div>
)

const patterns = patternGenerator({ quantity: 4, theme: 'dark' })

export const ContactForm: FC = () => {
  const [business, setBusiness] = useState('Private Members Clubs')
  const location = useLocation()
  const buttonIdGTM = location.pathname.replace('/capterra/', '')
  const errorMessage = 'This field is required'
  const { sendEmail, success } = useSurveyApi()
  const onSubmit = (formValues: FormValues): void => {
    sendEmail(1878, {
      1489: `${formValues?.company}`,
      2770: `${formValues?.firstName}`,
      2771: `${formValues?.lastName}`,
      2773: `${formValues?.email}`,
      2838: `${formValues?.phone}`,
      15855: `${business}`,
      15856: `${formValues?.website}`,
      16164: `${formValues.role}`,
      18615: `${formValues.country}`,
      18978: `${formValues.timeline}`,
      18979: 'capterra',
      18616: 'capterra',
    })

    ReactGA.event({
      category: `capterra-form-submission ${buttonIdGTM}`,
      action: `${buttonIdGTM}`,
      label: 'capterra',
      value: 2,
    })
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    website: '',
    company: '',
    role: '',
    country: '',
    timeline: '',
    referral: '',
    referral_client: '',
    referred_by: '',
    leverageMembershipProgram: ''
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required(errorMessage),
    lastName: Yup.string().required(errorMessage),
    website: Yup.string().required(errorMessage),
    company: Yup.string().required(errorMessage),
    phone: Yup.string().required(errorMessage),
    country: Yup.string().required(errorMessage),
    referral: Yup.string().required(errorMessage),
    timeline: Yup.string().required(errorMessage),
    role: Yup.string().required(errorMessage),
    email: Yup.string().email('Invalid email format').required(errorMessage),
  })

  function handleCard(id: string): void {
    setBusiness(id)
  }
  const navigate = useNavigate()
  success && navigate(`/success/${buttonIdGTM}`, { state: { prevPath: location.pathname } })
  success === false && navigate(`/error`, { state: { prevPath: location.pathname } })
  return (
    <div className='capterra-contact' id='capterra-contact'>
      <div className='ornament-block'>{patterns}</div>
      <div className='container'>
        <div className='capterra-contact__wrapper'>
          <h2 className='capterra-contact__title section-main-title'>Contact form</h2>
          <div className='capterra-form-block'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {form => (
                <Form className='capterra-login-form'>
                  <p className='card-list-heading'>Which Vertical represents your business?</p>
                  <ul className='card-list'>
                    {businesses.map((item: BusinessesTypes) => (
                      <li
                        key={item.id}
                        className={`small-card small-card-${business === item.title ? 'selected' : ''}`}
                        onClick={() => handleCard(item.title)}
                      >
                        <div className='small-card-radio' />
                        <div className='small-card-image'>
                          <img src={item.image} alt='logo' />
                        </div>
                        <div className='small-card-text'>{item.title}</div>
                      </li>
                    ))}
                  </ul>
                  <div className='login-form__wrapper'>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='firstName'>
                            First name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.firstName && form?.touched.firstName && 'login-form__inputfield--error'}`}
                            name='firstName'
                            placeholder='Enter First name'
                          />
                        </div>
                        <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='lastName'>
                            Last name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.lastName && form?.touched.lastName && 'login-form__inputfield--error'}`}
                            name='lastName'
                            placeholder='Enter Last name'
                          />
                        </div>
                        <ErrorMessage name='lastName' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='phone'>
                            Phone
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='number'
                            className={`login-form__inputfield 
                        ${form?.errors.phone && form?.touched.phone && 'login-form__inputfield--error'}`}
                            name='phone'
                            placeholder='Enter your phone'
                          />
                        </div>
                        <ErrorMessage name='phone' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='email'>
                            Business email address
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.email && form?.touched.email && 'login-form__inputfield--error'}`}
                            name='email'
                            placeholder='Enter your Email'
                          />
                        </div>
                        <ErrorMessage name='email' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='company'>
                            Company Name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.company && form?.touched.company && 'login-form__inputfield--error'}`}
                            name='company'
                            placeholder='Enter Company Name'
                          />
                        </div>
                        <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='website'>
                            Company Website
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.website && form?.touched.website && 'login-form__inputfield--error'}`}
                            name='website'
                            placeholder='Enter Company Website'
                          />
                        </div>
                        <ErrorMessage name='lastName' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field
                          name={'country'}
                          placeholder='Country'
                          component={SelectField}
                          required={true}
                          options={countries.map(it => ({ label: it.name, value: it.name }))}
                        />
                      </div>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'role'} placeholder='What is your role?' component={SelectField} required={true} options={roles} />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field
                          name={'timeline'}
                          placeholder='When are you looking to launch?'
                          component={SelectField}
                          required={true}
                          options={timelines}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='login-form__button'>
                    <button type='submit' id={buttonIdGTM}>
                      Get free demo
                      <ArrowIcon />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
