import { useMemo } from "react";
import { useContent } from "src/components/App/Content"
import { ContentFragment } from "src/graphql";
import { IBrand } from "../interface";



const adapterBrandData = (brand: ContentFragment): IBrand => ({
    title: brand.title,
    description: brand.description,
    brandSections: brand.cards?.map(card => ({
        title: card?.title,
        description: card?.description,
        images: card?.media_slides?.map(image => ({
            url: image?.url,
            altText: image?.alternativeText,
            caption: image?.caption
        })),
        slug: card?.slug ?? ''
    }))
})

export const useBrands = (): IBrand[] => {
    const peoplevineBrand = useContent('peoplevine-brand');
    const rsvpBrand = useContent('rsvp-brand');


    const brandsSection = useMemo(() => {

        const brands: IBrand[] = []

        if (peoplevineBrand?.id)
            brands.push(adapterBrandData(peoplevineBrand))

        if (rsvpBrand?.id)
            brands.push(adapterBrandData(rsvpBrand))


        return brands;
    }, [peoplevineBrand?.id, rsvpBrand?.id])

    return brandsSection
}
