import { ImgHTMLAttributes, ReactNodeArray } from 'react'
import pattern1 from '../../assets/images/patterns/pattern-1.png'
import pattern2 from '../../assets/images/patterns/pattern-2.png'
import pattern3 from '../../assets/images/patterns/pattern-3.png'
import pattern4 from '../../assets/images/patterns/pattern-4.png'
import pattern5 from '../../assets/images/patterns/pattern-5.png'
import pattern6 from '../../assets/images/patterns/pattern-6.png'

type PatternGeneratorProps = {
  quantity: number
  theme: string
}

const BREAKPOINT = 1200

const patternImages = [pattern1, pattern2, pattern3, pattern4, pattern5, pattern6] as const

function calculateOpacity(theme: 'light' | 'medium' | unknown): number {
  switch (theme) {
    case 'light':
      return 0.5
    case 'medium':
      return 0.75
    default:
      return 1
  }
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export function patternGenerator({ quantity, theme }: PatternGeneratorProps): ReactNodeArray {
  const patterns = []
  const opacity = calculateOpacity(theme)

  for (let i = 0; i < quantity; i++) {
    const patternNumber = getRandomInt(0, patternImages.length - 1)
    const styles = {
      top: `${getRandomInt(0, 100)}%`,
      left: `${getRandomInt(0, 100)}%`,
      opacity,
    }

    const Pattern = ({ alt, ...props }: ImgHTMLAttributes<HTMLImageElement>) => (
      <img alt={alt} src={patternImages[patternNumber]} {...props} />
    )

    patterns.push(<Pattern key={i} alt='ornament' className='ornament-block__image' style={styles} />)
  }

  return patterns
}

export const patternScrolling = (blocks: NodeListOf<HTMLDivElement>): void => {
  let lastScrollPosition = 0
  let patternTranslate = 0
  let isDesktop = true

  document.addEventListener('resize', () => {
    if (window.innerWidth >= BREAKPOINT) {
      isDesktop = true
    }
  })

  document.addEventListener('load', () => {
    if (window.innerWidth >= BREAKPOINT) {
      isDesktop = true
    }
  })

  if (isDesktop) {
    document.addEventListener('scroll', () => {
      if (window.innerWidth >= BREAKPOINT) {
        isDesktop = true
      }
      const azx = document.querySelector('.subscription-section__wrapper');
      const st = window.pageYOffset || document.documentElement.scrollTop

      if (st > lastScrollPosition) {
        patternTranslate -= 2
      } else {
        patternTranslate += 2
      }
      lastScrollPosition = st <= 0 ? 0 : st

      blocks.forEach(item => {
        const patterns: NodeListOf<HTMLDivElement> = item.querySelectorAll('.ornament-block__image')

        patterns.forEach(pattern => {
          pattern.style.transform = `translateY(${patternTranslate}px)`
        })
      })
      
      
      blocks.forEach(item => {
        const patterns: NodeListOf<HTMLDivElement> = item.querySelectorAll('.custom-ornament-block .ornament-block__image')

        let elem = document.querySelectorAll('.custom-ornament-block');
        elem.forEach(elemParent => {
          
          let ttop = ((elemParent.getBoundingClientRect().top - window.innerHeight)/window.innerHeight)*100;
          patterns.forEach(pattern => {
              if(elemParent.getBoundingClientRect().top - window.innerHeight < 0){
                pattern.style.transform = `translateY(${ttop}px)`
              }else{
                pattern.style.transform = `translateY(0px)`
              }
            })
        })
      })

    })
  }
}
