import './Modal.scss'

import { FC } from 'react'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg'

type ModalProps = {
  isOpen: boolean
  setIsOpen: (prevState: null) => void
}

const Modal: FC<ModalProps> = props => {
  return (
    <div className={`modal ${props.isOpen ? 'modal--open' : ''}`}>
      <div className='modal__wrapper'>
        <div className='modal__close' onClick={() => props.setIsOpen(null)}>
          <CloseIcon />
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Modal
