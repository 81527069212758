import { SVGProps } from "react"
const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="currentColor"
    viewBox="0 0 1080 1080"
    height="100%" 
    width="100%"
    {...props}
  >
    <path d="m1036.3 986.08-393.76-514.6L970.86 93.92H819.53L571.71 378.9 353.65 93.92H43.7l372.72 487.1L64.17 986.08H215.5L487.24 673.6l239.11 312.48h309.95zm-725.46-805.5 550.04 718.84h-91.71L219.12 180.58h91.72z" />
  </svg>
)
export default TwitterIcon
