

export interface IMedia {
  id:              string;
  alternativeText: string;
  caption:         string;
  name:            string;
  previewUrl:      null;
  size:            number;
  url:             string;
  height:          number;
  width:           number;
}

interface IImageSlides {
  index: number
  media: IMedia
}


export const ImageSlides = ({
  index,
  media
}: IImageSlides ) => {

  if (media?.caption === 'desktop') {
    return (
      <div
        key={media.id}
        className={`hero-slide-block__picture hero-slide-block__picture--${index + 1} hero-slide-block__picture--desktop`}
      >
        <img src={media.url} alt='hero-image' className='hero-slide-block__image' />
      </div>
    )
  }

  return (
    <div
      key={media.id}
      className={`hero-slide-block__picture hero-slide-block__picture--${index + 1} hero-slide-block__picture--mobile`}
    >
      <img src={media?.url} alt='hero-image' className='hero-slide-block__image' />
    </div>
  )
}
