import { FC, PropsWithChildren, ReactNode, useEffect } from 'react'
import Intercom from 'react-intercom'
import { useLocation } from 'react-router-dom'
import { MenuContext } from 'src/components/App/App'
import { Footer } from 'src/components/Footer/Footer'
import { Header } from 'src/components/Header/Header'
import { NavMenu } from 'src/components/NavMenu/NavMenu'

import { patternScrolling } from '../components/Patterns/patternsGenerator'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import { SeoProvider, useSeo } from '../components/Seo'
import { Maybe, SeoFragment } from '../graphql'
import { Scrollable } from './Scrollable'

export const reactIntercomUser = {
  appID: 'vikyxw9l',
}

interface LayoutProps {
  isHeaderFixed?: boolean
  isHeaderIndependent?: boolean
  isFooter?: boolean
  isFooterAnimated?: boolean
  isFooterShort?: boolean
  isFooterHasMenu?: boolean
  isHeaderShort?: boolean
  hasLogin?: boolean
  hasSignup?: boolean
  theme?: string
  footerContent?: boolean
  headerText?: string
  meta?: Maybe<Partial<SeoFragment>>
  hasVideoBanner?: boolean
  customLogo?: ReactNode
}

export const DefaultLayout: FC<PropsWithChildren<Partial<LayoutProps>>> = ({
  children,
  isHeaderFixed = true,
  isHeaderIndependent = false,
  isFooter = true,
  isFooterShort = false,
  isFooterHasMenu = true,
  isFooterAnimated = true,
  isHeaderShort = false,
  theme = 'dark',
  footerContent = true,
  headerText,
  hasSignup = false,
  hasVideoBanner = true,
  customLogo
}) => {
  const elements = window?.document?.querySelectorAll<HTMLDivElement>('.ornament-block')

  const { pathname } = useLocation()
  const { value } = useSeo({ pathname })
  useEffect(() => {
    elements.length > 0 && patternScrolling(elements)
  }, [elements])

  if (!value) return null

  return (
    <SeoProvider value={value}>
      <MenuContext.Provider
        value={{
          onClick: () => {
            window?.document?.head.parentElement?.classList.toggle('html-scroll')
            window?.document?.body.classList.toggle('menu--opened')
          },
        }}
      >
        <Scrollable>
          <ScrollToTop />
          <main className='main'>
            <Header
              headerText={headerText}
              isHeaderFixed={isHeaderFixed}
              hasSignup={hasSignup}
              isHeaderIndependent={isHeaderIndependent}
              isShort={isHeaderShort}
              theme={theme}
              hasVideoBanner={hasVideoBanner}
              customLogo={customLogo}
            />
            <NavMenu renderSocials={true} />
            {children}
            {import.meta.env.PROD && <Intercom {...reactIntercomUser} />}
            {isFooter && <Footer isShort={isFooterShort} footerContent={footerContent} isFooterHasMenu={isFooterHasMenu} isFooterAnimated={isFooterAnimated} />}

            
          </main>
        </Scrollable>
      </MenuContext.Provider>
    </SeoProvider>
  )
}
