import './LogoWithText.scss'

import { ReactComponent as LogoIcon } from 'src/components/App/images/logo-icon.svg'
import { ReactComponent as LogoText } from 'src/components/App/images/text-icon.svg'

const LogoWithText = () => (
    <div className="logo-container">
        <LogoIcon className={'header-logo__icon'} />
        <LogoText className={'header-logo__text-icon'} />
    </div>
)

export default LogoWithText