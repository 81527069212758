import { FC, PropsWithChildren } from "react"
import { Footer } from "src/components/Footer/Footer"


interface LayoutProps {
  isFooter?: boolean
  isFooterAnimated?: boolean
  isFooterShort?: boolean
  isFooterHasMenu?: boolean,
  footerContent?: boolean,
}

export const MobileLayout: FC<PropsWithChildren<Partial<LayoutProps>>> = ({
  children,
  isFooter = true,
  isFooterShort = false,
  isFooterHasMenu = true,
  isFooterAnimated = true,
  footerContent = true,
}) => {
  const onClickBack = () => window.location.href = '/'
  return (
    <div className="main mobile-layout">
      {/* <div className="header-button" onClick={onClickBack}>
        <LoginIcon width={40} height={30} />
      </div> */}
      {children}
      {isFooter && <Footer isShort={isFooterShort} footerContent={footerContent} isFooterHasMenu={isFooterHasMenu} isFooterAnimated={isFooterAnimated} />}
    </div>
  )
}