import 'src/sections/homePageSections/KeyFeaturesSection/KeyFeaturesSection.scss'

import { FC } from 'react'

import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import memberPattern2 from './images/member-pattern-2.png'
import memberPattern3 from './images/member-pattern-3.png'
import opsTeamsPattern3 from './images/ops-teams-pattern-3.png'
import opsTeamsPattern4 from './images/ops-teams-pattern-4.png'
import { useContent } from '../../../components/App/Content'

SwiperCore.use([Pagination, Autoplay, EffectFade])

interface IKeyFeature {
  title: string | null | undefined
  slides: (string | null | undefined)[] | undefined
  images: (string | undefined)[] | undefined
  isDarkTheme: boolean
  additionalClass: string
}

const MemberPortalSection: FC = () => {
  const content = useContent('product-key-features-01')
  const slidesTitles = content?.cards?.map(card => card?.title)
  const slidesImages = content?.cards?.map(card => card?.media?.url)

  const memberPortaleKeyFeatures = {
    title: content?.description,
    slides: slidesTitles,
    images: slidesImages,
    isDarkTheme: true,
    additionalClass: 'portal',
    autoplayDelay: 5000 
  }

  return <KeyFeaturesSection {...memberPortaleKeyFeatures} />
}
const OpsTeamsSection: FC = () => {
  const content = useContent('product-key-features-02')
  const slidesTitles = content?.cards?.map(card => card?.title)
  const slidesImages = content?.cards?.map(card => card?.media?.url)

  const opsTeamsKeyFeatures = {
    title: content?.description,
    slides: slidesTitles,
    images: slidesImages,
    isDarkTheme: false,
    additionalClass: 'ops-teams',
  }
  return <KeyFeaturesSection {...opsTeamsKeyFeatures} />
}
const KeyFeaturesSection: FC<IKeyFeature> = ({ title, slides, images, isDarkTheme, additionalClass, autoplayDelay = 10000 }) => {
  const backgroundColor = `${isDarkTheme ? '#202123' : '#fff'}`
  const textColor = `${isDarkTheme ? '#fefefe' : '#2f3034'}`
  const progressBarColor = `${isDarkTheme ? '#3F444D' : '#EBECEE'}`

  return (
    <section style={{ backgroundColor }} className={`key-features key-features--${additionalClass}`}>
      <div className={`ornament-block ornament-block--${additionalClass}`}>
        {additionalClass === 'portal' ? (
          <div className='ornament-block__wrapper'>
            <img src={memberPattern2} alt='ornament' className='ornament-block__image' />
            <img src={memberPattern3} alt='ornament' className='ornament-block__image' />
          </div>
        ) : (
          <div className='ornament-block__wrapper'>
            <img src={opsTeamsPattern3} alt='ornament' className='ornament-block__image' />
            <img src={opsTeamsPattern4} alt='ornament' className='ornament-block__image' />
          </div>
        )}
      </div>
      <div className='container'>
        <div className='key-features__wrapper'>
          {additionalClass === 'portal' ? (
            <h2 className='key-features__title section-main-title' style={{ color: textColor }}>
              Key features of <span className='key-features__title-linebreak'>{title}</span>
            </h2>
          ) : (
            <h2 className='key-features__title section-main-title' style={{ color: textColor }}>
              Key features of {title}
            </h2>
          )}
          <div className='key-features-slider'>
            <Swiper
              effect='fade'
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              pagination={{
                clickable: true,
                el: `.swiper-pagination.swiper-pagination--${additionalClass}`,
                renderBullet: function (index, className) {
                  return (
                    slides &&
                    `<span class=${className} style="background-color: ${progressBarColor}">
                      <span class="swiper-pagination-bullet__text" style="color: ${textColor}" >
                        ${slides[index]}
                      </span>
                    </span>`
                  )
                },
              }}
              autoplay={{ delay: autoplayDelay }}
              speed={600}
            >
              {slides &&
                slides.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={`swiper-slide__picture swiper-slide__picture--${additionalClass}`}>
                      <img src={images && images[index]} alt='client-image' className='swiper-slide__image swiper-slide__image--desktop' />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className={`swiper-pagination swiper-pagination--${additionalClass}`} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default { OpsTeamsSection, MemberPortalSection }
