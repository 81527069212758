
import { useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg';
import { ReactComponent as PlayIcon } from 'src/assets/icons/play-solid.svg';

const videosAvailable = {
    homePage: 'https://player.adventr.io/index.html?link=https%3A%2F%2Fd252srr1zuysk4.cloudfront.net%2Fclients%2F7128%2F8707%2Fpublished%2F7128-peopleveine-super-app-overview-76874642.data',
}

const VideoContainer = () => {

    const videoHolderRef = useRef<HTMLDivElement>(null);
    const iframeContainerRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const videoContent = useRef<HTMLIFrameElement>(null);

    const videoToRender = videosAvailable.homePage

    const videoPlaceHolder = 'play video'

    const renderVideoContent = () => {
        if (iframeRef.current) {
            const iframe = document.createElement('iframe');
            const currentIframe = iframeRef.current
            iframe.src = currentIframe.getAttribute('src') || '';

            currentIframe.getAttributeNames().map(attr => {
                const value = currentIframe.getAttribute(attr) || '';
                iframe.setAttribute(attr, value)
            })
            return iframe;
        }

        return;
    }

    const handleCloseVideo = () => {
        if (videoHolderRef.current && iframeContainerRef.current && videoContent.current) {
            videoHolderRef.current.classList.remove("started");

            iframeContainerRef.current.innerHTML = ""
            iframeContainerRef.current.appendChild(renderVideoContent() as Node)

            videoContent.current.classList.remove("open");
        }
    }

    useEffect(() => {

        function receiveMessage(event: any) {
            // first, determine if event is JSON
            let received;
            try {
                received = JSON.parse(event.data);
            } catch (e) {
                received = {
                    event: event.data
                }
            }

            // if the event is JSON, check that it's in the adventr format and if it's a start
            if (event.source === (document.getElementById('adventr-video-section') as any)?.contentWindow &&
                (received.context?.includes('adventr') || received.context === 'player.js')
            ) {
                if (received.event === 'videoclipchanged' || received.event === 'play') {
                    videoHolderRef.current?.classList.add("started");
                    videoContent.current?.classList.add("open");
                }
            }
        }

        // add an event listener to the page to listen for the play start.
        window.addEventListener("message", receiveMessage, false);
        return () => window.removeEventListener("message", receiveMessage, false);
    }, []);

    return (
        <div className='content' ref={videoContent}>
            <div className="container">
                <div className="video-title">
                    <h3>preview a day in the life <br /> of your member</h3>
                </div>
                <div ref={videoHolderRef} className='responsive-video'>
                    <div className="play-holder">
                        <div ref={iframeContainerRef}>
                            <iframe id="adventr-video-section" ref={iframeRef} src={videoToRender} frameBorder="0" scrolling="no" allowFullScreen allow="autoplay; fullscreen; clipboard-read; clipboard-write; encrypted-media; geolocation; microphone"></iframe>
                        </div>
                        <a className='play'>
                            <p>{videoPlaceHolder}</p>
                            <PlayIcon />
                        </a>
                    </div>
                </div>
            </div>
            <button className="close-button" type="button" onClick={handleCloseVideo}>
                <CloseIcon />
            </button>
        </div>
    )
}

export default VideoContainer