import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { FC, useEffect } from 'react'
import { ReactComponent as Baia } from 'src/assets/images/clients/baia.svg'
import { ReactComponent as Core } from 'src/assets/images/clients/core.svg'
import { ReactComponent as JamesBeard } from 'src/assets/images/clients/jamesBeard.svg'
import { ReactComponent as Mhub } from 'src/assets/images/clients/mhub.svg'
import { ReactComponent as Nocatee } from 'src/assets/images/clients/nocatee.svg'
import { ReactComponent as ZeroBond } from 'src/assets/images/clients/zeroBond.svg'
import DefaultLayout from 'src/layouts'
import { ClientsSection } from 'src/sections/commonSections/ClientsSection/ClientsSection'
import { CustomerExperienceSection } from 'src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection'
import customerExperienceImage from 'src/sections/commonSections/CustomerExperienceSection/images/customer-experience-image-1.jpg'
import { FeedbackSection } from 'src/sections/homePageSections/FeedbackSection/FeedbackSection'
import HomeHeroSection from 'src/sections/homePageSections/HomeHeroSection'
import { HomeVideoSection } from 'src/sections/homePageSections/HomeVideoSection/HomeVideoSection'
import { PlatformsSection } from 'src/sections/homePageSections/PlatformsSection/PlatformsSection'
import { ProductsSection } from 'src/sections/homePageSections/ProductsSection/ProductsSection'
import { ServicesSection } from 'src/sections/homePageSections/ServicesSection/ServicesSection'
import { SubscriptionSection } from 'src/sections/homePageSections/SubscriptionSection/SubscriptionSection'
import { TwoSidesPlatformSection } from 'src/sections/homePageSections/TwoSidesPlatformSection/TwoSidesPlatformSection'

import VideoAdventrSection from 'src/sections/VideoAdventrSection/VideoAdventrSection'
import { useContent } from '../../components/App/Content'
import { ClientProps } from '../Capterra/crm/Capterra'

export const clients = [
  {
    clientName: 'zeroBond',
    title: 'Private club',
    image: <ZeroBond />,
    about:
      'Zero Bond is a twenty thousand square foot private membership club in the heart of Noho. The club will have something for every member at every hour',
  },
  {
    clientName: 'baia',
    title: 'Boutique Hotel',
    image: <Baia />,
    about: 'Baia Beach Club brings the effortless sophistication of international coastal living to the new and reimagined Mondrian South Beach',
  },
  {
    clientName: 'nocatee',
    title: 'Luxury Residential',
    image: <Nocatee />,
    about: 'Nocatee is one of the best-selling master-planned communities in America, and the number one place to call home on the First Coast',
  },
  {
    clientName: 'mhub',
    title: 'Coworking ',
    image: <Mhub />,
    about:
      "mHUB's hyper-resourced environment creates the conditions for physical product innovation to thrive resulting in new business creation,  intellectual property and jobs",
  },
  {
    title: 'PRIVATE CLUB',
    image: <Core />,
    about:
      "Core - is a private members club where are all the conditions for today's mavericks and mavens to do their boldest thinking, deepest discovery, and freest play",
  },
  {
    title: 'Hospitality Enterprise',
    image: <JamesBeard />,
    about:
      "The James Beard Foundation is a nonprofit organization whose mission is to celebrate, nurture, and honor chefs and other leaders making America's food culture",
  },
]

const HomePage: FC = () => {

  useEffect(() => {
    const twoSidesAnimation = () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1200px)': () => {
          gsap.to('.two-sides-platform-part--dark', {
            scrollTrigger: {
              trigger: '.two-sides-platform-part--dark',
              start: '15% 90%',
              end: '70% 30%',
              scrub: 1,
            },
            ease: 'none',
            clipPath: 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
          })
        },
      })
    }

    window.addEventListener('load', () => twoSidesAnimation())
    return () => {
      window.removeEventListener('load', () => twoSidesAnimation())
    }
  })
  const servicesContent = useContent('home-services')
  const clientsContent = useContent('home-clients')
  const platformsContent = useContent('home-platforms')

  return (
    <DefaultLayout isFooterHasMenu={true} isHeaderFixed={false} isFooterAnimated={false}>
      <HomeVideoSection slugContent="hero-video-section" showPlayButton={false} />
      {/* <ProductRelease /> */}
      <HomeHeroSection />
      <ServicesSection title={servicesContent?.title} />
      <ClientsSection isHomePage={true} clients={clientsContent?.cards as ClientProps[]} title={clientsContent?.title} hasSeeMore={false} />
      <VideoAdventrSection />
      <TwoSidesPlatformSection />
      <ProductsSection />
      <PlatformsSection title={platformsContent?.title} />
      <FeedbackSection />
      <SubscriptionSection />
      <CustomerExperienceSection image={customerExperienceImage} isAnimated={false} />
    </DefaultLayout>
  )
}
export default HomePage
