import { useMemo } from 'react'
import { FeedbackFragment, useFeedbackQuery } from '../../graphql'

function useFeedbacks(): Array<FeedbackFragment | null | undefined> | null | undefined {
  const { loading, data } = useFeedbackQuery()

  const content = useMemo(() => (!loading && data?.feedbacks ? data.feedbacks : []), [loading, data])
  return content
}
export { useFeedbacks }
