import { SVGProps } from "react"
const VimeoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 23 20"
    height="100%" 
    width="100%"
    {...props}
  >
    <path d="M22.047 5.008c-1.207 6.928-7.95 12.793-9.978 14.133-2.028 1.338-3.88-.536-4.55-1.957-.77-1.617-3.072-10.388-3.675-11.114-.604-.727-2.412.726-2.412.726l-.877-1.17s3.673-4.47 6.469-5.03c2.964-.592 2.96 4.637 3.672 7.538.689 2.81 1.153 4.416 1.755 4.416.603 0 1.755-1.566 3.015-3.966 1.264-2.404-.054-4.526-2.521-3.016.987-6.033 10.309-7.484 9.102-.56Z" />
  </svg>
)
export default VimeoIcon
