import './Hero.scss'

import { FC } from 'react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '../../components/Form/Button'

SwiperCore.use([Autoplay, EffectFade])

export type HeroProps = {
  slides: {
    title: string
    subtitle: string
    img: string
  }[]
}

const Hero: FC<HeroProps> = ({ slides }) => {
  return (
    <section className='capterra-guide hero-section' id='hero-section'>
      <div className='container'>
        <div className='capterra-section__wrapper'>
          <div className='capterra-section-slider'>
            <Swiper effect='fade' slidesPerView={1} loop={true} initialSlide={1} allowTouchMove={false} speed={600}>
              {slides.map((slide, index) => (
                <SwiperSlide key={slide.title}>
                  <div className='capterra-section-slider__item'>
                    <div className='capterra-section-slider__item-wrapper'>
                      <div key={1} className={'capterra-slide-block'}>
                        <h1 className={'capterra-slide-block__title main-title'}>{slide.title}</h1>
                        <p className='capterra-slide-block__text'>{slide.subtitle}</p>
                        <Button>Request a Demo</Button>
                      </div>
                      <div key={2} className={`capterra-slide-block capterra-slide-block--${index + 1}`}>
                        <div className={'capterra-slide-block__picture'}>
                          <img src={slide.img} alt='capterra-image' className='capterra-slide-block__image' />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
