import { FC } from 'react'
import servicePattern2 from './images/platform-pattern-1.png'
import './CustomerCase.scss'
import { IClientType } from '../clientsPageSections/ClientTypeSection/ClientTypeSection'

export type CustomerCaseProps = {
  data: IClientType
  theme?: 'light' | 'grey' | 'dark'
}

export const CustomerCase: FC<CustomerCaseProps> = ({ data, theme = 'dark' }) => {
  return (
    <section className={`customer-case customer-case-${theme}`} id='customer-case' role={'presentation'}>
      <div className='ornament-block'>
        <img src={servicePattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='customer-case__wrapper'>
          <img src={data.slides[0].logo} className='customer-case__logo' alt='logo' />
          <h2 className='customer-case__title section-main-title'>{`Zero Bond Case Study`}</h2>
          <div className='customer-case-list'>
            {data.slides.map(item => (
              <article key={item.slideTitle} className='customer-case-list-item'>
                <div className='customer-case-list-item__content'>
                  <h5 className='customer-case-list-item__title section-secondary-title'>{item.slideTitle}</h5>
                  <p className='customer-case-list-item__text'>{item.slideAbout}</p>
                </div>
                <div className='customer-case-list-item__picture'>
                  <img src={item.slideImage} alt='serices-image' className='customer-case-list-item__image' />
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
