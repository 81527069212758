
import { ReactComponent as LoginIcon } from '../images/login-icon.svg'

interface LogInButtonProps {
    handleOpenDropdown: () => void;
}

export const LogInButton = ({
    handleOpenDropdown
}: LogInButtonProps) => (
    <div className="header-panel-container">
        <div className='header-panel-button' onClick={handleOpenDropdown}>
            <span className='header-panel-button__text'>Log in</span>
            <LoginIcon className={'header-panel-button__icon'} />
        </div>
    </div>
)
