import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg'

const RequestDemo: FC<Partial<LinkProps>> = ({ to = '/form', title = 'Request a demo', className, ...props }) => (
  <Link
    to={to}
    onClickCapture={() => {
      document.head.parentElement?.classList.remove('html-scroll')
      document.body.classList.remove('menu--opened')
    }}
    className={['button', className].filter(Boolean).join(' ')}
    {...props}
  >
    <span>{title}</span>
    <ArrowIcon />
  </Link>
)

export { RequestDemo }
