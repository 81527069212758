import './Resources.scss'

import { FC, useEffect, useRef, useState } from 'react'

import { useContent } from '../../../components/App/Content'
import { ContentFragment, Maybe } from '../../../graphql'
import platformPattern1 from '../../homePageSections/PlatformsSection/images/platform-pattern-2.png'
import platformPattern2 from '../../homePageSections/PlatformsSection/images/platform-pattern-2.png'

const headerInfo = ['For all Creators', 'For Designers', 'For Developers']

const Resources: FC = () => {
  const [activeTab, setActiveTab] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  const ivy = useContent('developers-page-ivy')
  const platform = useContent('developers-page-platform-overview')
  const portal = useContent('developers-page-member-portal')
  const strapiData: Maybe<ContentFragment>[] = []

  if (ivy && platform && portal) {
    strapiData.push(platform, portal, ivy)
  }

  useEffect(() => {
    let lastScrollTop = 0
    const headerToggler = () => {
      const scrollTop = window.pageYOffset
      if (headerRef.current !== null && scrollTop > lastScrollTop && scrollTop > 150 && headerRef.current.classList.contains('sticky-header')) {
        headerRef.current.classList.add('header--hidden')
      } else if (headerRef.current !== null && scrollTop < lastScrollTop) {
        headerRef.current.classList.remove('header--hidden')
      }
      lastScrollTop = scrollTop

      if (scrollTop > 100 && headerRef.current !== null) {
        headerRef.current.classList.add('header--top')
      } else if (headerRef.current !== null && scrollTop <= 100) {
        headerRef.current.classList.remove('header--top')
      }
    }
    window.addEventListener('scroll', () => {
      headerToggler()
    })

    return () => {
      window.removeEventListener('scroll', () => headerToggler())
    }
  })

  return (
    <section className={`resources active-tab-${activeTab}`}>
      <div className='sticky-header' ref={headerRef}>
        <div className='wrapper'>
          <div className='list'>
            {headerInfo.map((item, index) => (
              <a
                key={index}
                onClick={() => {
                  setActiveTab(index)
                  const elem = document?.getElementById('resources')
                  if (elem) {
                    const y = elem?.getBoundingClientRect().top + window.scrollY - 50
                    window.scrollTo({ top: y, behavior: 'smooth' })
                  }

                  // document?.getElementById('resources')?.scrollIntoView({
                  //   behavior: 'smooth'
                  // })
                }}
                className={`header-item-${index} ${index === activeTab ? 'active' : ''}`}
              >
                {item}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className='ornament-block'>
        <img src={platformPattern1} alt='ornament' className='ornament-block__image' />
        <img src={platformPattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container' id={'resources'}>
        {strapiData.length !== 0 && (
          <div className='resources__wrapper'>
            {strapiData.map(item => (
              <div
                key={item?.title}
                hidden={item?.cards?.filter(item => item?.subDescription?.split(',').includes(activeTab.toString())).length === 0 && activeTab !== 0}
              >
                <h2 className='resources__title section-main-title'>
                  {item?.title}
                  {item?.section?.subTitle && <span className='beta'>beta</span>}
                </h2>
                <p className='resources__description'>{item?.description}</p>
                <ul className='resources-list'>
                  {item?.cards?.map((item, index) =>
                    item?.subDescription?.split(',').includes(activeTab.toString()) || activeTab === 0 ? (
                      <li key={index}>
                        <div className='info'>
                          <h3>
                            {item?.title}
                            {item?.active && (
                              <span>
                                <div>The option is only available to logged-in users.</div>
                              </span>
                            )}
                          </h3>
                          <div className='platforms-list-item__picture'>
                            <img src={`/developers/${item?.backgroundColor}.svg`} alt='platform-logo' className='platforms-list-item__image' />
                          </div>
                          <p>{item?.description}</p>
                        </div>
                        <div className='item-footer'>
                          <a href={item?.slug ?? ''} target='_blank' rel={'noreferrer'}>
                            {item?.subTitle}
                          </a>
                        </div>
                      </li>
                    ) : (
                      ''
                    ),
                  )}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default Resources
