import { FC } from 'react'
import './ReviewsSection.scss'
import { useContent } from 'src/components/App/Content'
import ImagePeople from 'src/sections/ReviewsSection/images/zerobond-omar-testimonial-peoplevine.jpg'
import ZeroBondLogo from 'src/sections/ReviewsSection/images/zerobond-logo-white.png'

interface ReviewsSectionProps {}

const reviewContent = {
    people: 'Omar Aly',
    client: 'Head Of Marketing & Communications - Zero Bond',
    description: `Peoplevine is a powerful platform, particularly with its integration with Toast. It allows us to track our members' spending and enables them to pay using their stored card information. When they are finished dining, our staff can easily charge their card on file, which streamlines our operations and provides a seamless experience for our members.`,
    image: ImagePeople,
    logo: ZeroBondLogo
}

export const ReviewsSection: FC<ReviewsSectionProps> = () => {

    return (
        <section className="reviews-section">
            <div className='container'>
                <div className='review-wrapper'>
                    <div className='review--header'>
                        <div className='client-img'>
                            <img src={reviewContent.image} alt="Client image" />
                        </div>
                        <div className='review--header_content'>
                            {/* <img className="logo-img" src={ reviewContent.logo } alt="Client logo" /> */}
                            <span className='people-text'>{ reviewContent.people }</span>
                            <span className='client-text'>{ reviewContent.client }</span>
                        </div>
                    </div>
                    <div className='review--description'>
                        { reviewContent.description }
                    </div>
                </div>
            </div>
        </section>
    )
}
