
import ukflag from 'src/assets/images/flag/peoplevine-uk-flag.svg'
import usflag from 'src/assets/images/flag/peoplevine-us-flag.svg'

interface HeaderOptionsWrapperProps {
    openOptionsDropdown: boolean;
}

const HeaderOptionsWrapper = ({
    openOptionsDropdown
}: HeaderOptionsWrapperProps) => (
    <div className={`header-options__wrapper ${openOptionsDropdown ? 'active' : ''}`}>
        <div className="header-panel-dropdown fade-in-bottom">
            <a href='https://control.peoplevine.com/' target={'_blank'} rel='noreferrer' className="login-button">
                <span>US Login</span>
                <img src={usflag} alt='USA Flag' />
                {/* <LoginIcon className={'header-panel-button__icon'} /> */}
            </a>
            <a href='https://control.peoplevine.co.uk/' target={'_blank'} rel='noreferrer' className="login-button">
                <span>INTL LOGIN</span>
                <img src={ukflag} alt='USA Flag' />
                {/* <LoginIcon className={'header-panel-button__icon'} /> */}
            </a>
        </div>
    </div>
)

export default HeaderOptionsWrapper