import 'src/sections/homePageSections/TwoSidesPlatformSection/TwoSidesPlatformSection.scss'

import {  Children, DOMElement, FC, MutableRefObject, Ref, useEffect, useRef  } from 'react'
import platformDashboardPrimary from 'src/assets/images/crm-preview/events-left.png'
import crmPrimary from 'src/assets/images/crm-preview/people-list.png'
import twoSidesPlatformPattern2 from 'src/sections/homePageSections/TwoSidesPlatformSection/images/two-sides-pattern-2.png'
import twoSidesPlatformPattern3 from 'src/sections/homePageSections/TwoSidesPlatformSection/images/two-sides-pattern-3.png'

import { useContent } from '../../../components/App/Content'

export const TwoSidesPlatformSection: FC = () => {
  const content = useContent('home-two-sides')
  const card01Title = content?.cards ? content?.cards[0]?.title : ''
  const card01SubTitle = content?.cards ? content?.cards[0]?.subTitle : ''
  const card02Title = content?.cards ? content?.cards[1]?.title : ''
  const card02SubTitle = content?.cards ? content?.cards[1]?.subTitle : ''

  const ftext1 = useRef<HTMLInputElement>(null);
  const fdiv1 = useRef<HTMLInputElement>(null);
  const ftext2 = useRef<HTMLInputElement>(null);
  const stop1 = useRef<HTMLInputElement>(null);
  const ftext3 = useRef<HTMLInputElement>(null);
  const ftext4 = useRef<HTMLInputElement>(null);
  const stop2 = useRef<HTMLInputElement>(null);
  const imageClip = useRef<HTMLInputElement>(null);
  const imageClip2 = useRef<HTMLInputElement>(null);
  const imageClip3 = useRef<HTMLInputElement>(null);
  const ftext5 = useRef<HTMLInputElement>(null);
  const ftext6 = useRef<HTMLInputElement>(null);
  const stop3 = useRef<HTMLInputElement>(null);
  const ftext7 = useRef<HTMLInputElement>(null);
  const stop4 = useRef<HTMLInputElement>(null);
  const image1 = useRef<HTMLInputElement>(null);
  const bg = useRef<HTMLInputElement>(null);
  const bg2 = useRef<HTMLInputElement>(null);
  const ftext5inner = useRef<HTMLInputElement>(null);
  const fh = useRef<HTMLInputElement>(null);
  const fp = useRef<HTMLInputElement>(null);



  const offsetTop = (elRect: DOMRect): number => elRect.top + window.scrollY;

  const scrollingEffect = () => {
    var useheight = ((window.innerHeight/100)*35) + 50;

    if (ftext1.current != null && ftext2.current != null && ftext3.current != null && ftext5.current != null && ftext7.current != null && fh.current != null && fp.current != null) {
      if(window.innerWidth >767){
        var pth = ((window.innerHeight /100)*35)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
      }else{
        var pth = ((window.innerHeight /100)*45)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
      }
      var pt = pth/2 - 15;
      if( pth > 60 ){
        ftext1.current.style.paddingTop = pt + "px";
        ftext3.current.style.paddingTop = pt + "px";
        ftext5.current.style.paddingTop = pt + "px";
        // ftext7.current.style.paddingTop = pt + "px"; 
      }else{
        ftext1.current.style.paddingTop ="0px";
        ftext3.current.style.paddingTop ="0px";
        ftext5.current.style.paddingTop ="0px";
        // ftext7.current.style.paddingTop ="0px";
        
      }
    }


    if (ftext1.current != null) {
      if (window.scrollY > ((ftext1.current.getBoundingClientRect().top + window.scrollY) - 80) ) {
        ftext1.current.classList.add('fixed');
        ftext1.current.style.position = "fixed"
        ftext1.current.style.top = "80px"
        if (ftext2.current != null) {
          ftext2.current.style.opacity = "1"
        }
      }
      if (fdiv1.current != null && ftext2.current != null && image1.current != null && stop1.current != null) {
        if ((fdiv1.current.getBoundingClientRect().top > 80) ){
          ftext1.current.classList.remove('fixed')
          ftext1.current.style.position = "static"
          ftext1.current.style.top = "0px"
          if (ftext2.current != null) {
            ftext2.current.style.opacity = "0"
          }
        }
        if (fdiv1.current.getBoundingClientRect().top < 80 ){
            if((useheight + Math.floor(fdiv1.current.getBoundingClientRect().top/3)) > ftext1.current.clientHeight + 80 ){
              ftext2.current.style.top =  (useheight + Math.floor(fdiv1.current.getBoundingClientRect().top/3))+"px";
            }else{
              ftext2.current.style.top =  (ftext1.current.clientHeight + 80)+"px";
            }
        }
        if(stop1.current.getBoundingClientRect().top < 80){
          var a = 80 - stop1.current.getBoundingClientRect().top;
          ftext1.current.style.transform = "translateY(-"+a+"px)";
          ftext2.current.style.transform = "translateY(-"+a+"px)";
          if (ftext3.current != null) {
            ftext3.current.style.opacity = "1"
          }
        }else{
          ftext1.current.style.transform = "translateY(0px)";
          ftext2.current.style.transform = "translateY(0px)";
          if (ftext3.current != null) {
            ftext3.current.style.opacity = "0"
          }
        }
      }
    }


    if (ftext3.current != null && ftext4.current != null && stop1.current != null && stop2.current != null && stop2.current != null && ftext1.current != null && ftext2.current != null) {
      ftext4.current.style.opacity = "0";
      if(stop1.current.getBoundingClientRect().top < (ftext1.current.clientHeight + ftext2.current.clientHeight - 300)*-1 ){
        var c = (useheight + (stop1.current.getBoundingClientRect().top+(ftext1.current.clientHeight + ftext2.current.clientHeight - 300))/3);
        if(c>80){
          ftext3.current.style.top =  c+"px";
          ftext4.current.style.opacity = "0";
        }else{
          ftext3.current.style.top =  "80px";

          ftext4.current.style.opacity = "1"
          
          var d = useheight+(useheight - 80 + (stop1.current.getBoundingClientRect().top+(ftext1.current.clientHeight + ftext2.current.clientHeight))/3);
          if(d>ftext3.current.clientHeight+80){
            ftext4.current.style.top =  d+"px";
          }else{
            ftext4.current.style.top =  ftext3.current.clientHeight+80+"px";
          }
        }
      }
      
      if(stop2.current.getBoundingClientRect().top < 80){
        var e = 80 - stop2.current.getBoundingClientRect().top;
        ftext3.current.style.transform = "translateY(-"+e+"px)";
        ftext4.current.style.transform = "translateY(-"+e+"px)";
      }else{
        ftext3.current.style.transform = "translateY(0px)";
        ftext4.current.style.transform = "translateY(0px)";
      }

      if (stop2.current != null && bg.current != null && imageClip.current != null && ftext5.current != null && ftext5inner.current != null ) {
        if(stop2.current.getBoundingClientRect().top < 80 -(ftext3.current.clientHeight + ftext4.current.clientHeight)){
            var bgWidth = (100/700) * (((stop2.current.getBoundingClientRect().top - 80 + ftext3.current.clientHeight + ftext4.current.clientHeight)*-1));
            bg.current.style.width = bgWidth+"%";
            imageClip.current.style.clipPath =  'polygon('+(100 - bgWidth)+'% 0px, 0% 0px, 0% 100%, '+(100 - bgWidth)+'% 100%)';
            ftext5.current.style.opacity = "1";
            ftext5inner.current.style.clipPath =  'polygon(100% 0px, '+ (100 - bgWidth) +'% 0px, '+ (100 - bgWidth) +'% 100%, 100% 100%)';
          }
          else{
            bg.current.style.width = "0%"
            imageClip.current.style.clipPath =  'polygon(100% 0px, 0% 0px, 0% 100%, 100% 100%)';
            ftext5.current.style.opacity = "0";
            ftext5inner.current.style.clipPath =  'polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%)';
        }
      }
    }

    if (ftext3.current != null && ftext4.current != null && stop2.current != null && stop3.current != null && ftext5.current != null && ftext6.current != null  && image1.current != null && imageClip2.current != null && imageClip3.current != null){
      if(stop2.current.getBoundingClientRect().top < 80 -(ftext3.current.clientHeight + ftext4.current.clientHeight) - 700){
        ftext6.current.style.opacity = "1"
        var f = (useheight + (stop2.current.getBoundingClientRect().top + (ftext3.current.clientHeight + ftext4.current.clientHeight) + 700 - 80 )/3);
        if(f>ftext5.current.clientHeight+80){
          ftext6.current.style.top =  f+"px";
        }else{
          ftext6.current.style.top =  ftext5.current.clientHeight+80+"px";
        }
      }else{
        ftext6.current.style.opacity = "0";
      }
      
      if(stop3.current.getBoundingClientRect().top < 80){
        var h = 80 - stop3.current.getBoundingClientRect().top;
        ftext6.current.style.transform = "translateY(-"+h+"px)";
        if (ftext1.current != null && ftext2.current != null && ftext3.current != null && ftext5.current != null && ftext7.current != null && fh.current != null && fp.current != null) {
          if(window.innerWidth >767){
            var pth2 = ((window.innerHeight /100)*35)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
          }else{
            var pth2 = ((window.innerHeight /100)*45)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
          }
          var pt2 = (pth2/2 - 15);
          if( pth2 > 60 ){
            if(h < pt2){
              ftext5.current.style.marginTop = "-"+h+"px";
            }
            if(h > pt2){
              ftext5.current.style.marginTop = "-"+pt2+"px";
            }
          }
        }
      }else{
        ftext6.current.style.transform = "translateY(0px)";
        ftext5.current.style.marginTop = "0px";
      }
      
      

      if ( bg2.current != null && imageClip2.current != null && stop4.current != null && ftext7.current != null) {
        if(stop4.current.getBoundingClientRect().top < 80 ){
          var bgWidth = (100/700) * (((stop4.current.getBoundingClientRect().top - 80)*-1));
            bg2.current.style.width = bgWidth+"%"
            imageClip2.current.style.clipPath =  'polygon(100% 0, '+(bgWidth)+'% 0, '+(bgWidth)+'% 100%, 100% 100%)';
            ftext5.current.style.clipPath =  'polygon(100% 0, '+(bgWidth)+'% 0, '+(bgWidth)+'% 100%, 100% 100%)';
            ftext7.current.style.opacity = "1"
            imageClip3.current.style.clipPath =  'polygon('+(bgWidth)+'% 0, 0% 0, 0% 100%, '+(bgWidth)+'% 100%)';
        }
        else{
          bg2.current.style.width = "0%"
          imageClip2.current.style.clipPath =  'polygon(100% -1px, 0% 0px, 0% 100%, 100% 100%)';
          ftext5.current.style.clipPath =  'polygon(100% 0, 0% 0, 0% 100%, 100% 100%)';
          ftext7.current.style.opacity = "0";
          imageClip3.current.style.clipPath =  'polygon(0% 0, 0% 0, 0% 100%, 0% 100%)';
        }
        
        if(stop4.current.getBoundingClientRect().top < -980){
          var e = stop4.current.getBoundingClientRect().top + 980;
          ftext7.current.style.transform = "translateY("+e+"px)";
          imageClip3.current.style.transform = "translate(-50%,"+e+"px)";
        }else{
          ftext7.current.style.transform = "translateY(0px)";
          imageClip3.current.style.transform = "translate(-50%,0px)";
        }
      }


      var ax = 0;
      if (ftext1.current != null && ftext2.current != null && ftext3.current != null && ftext5.current != null && ftext7.current != null && fh.current != null && fp.current != null) {
        if(window.innerWidth >767){
          var pth2 = ((window.innerHeight /100)*35)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
        }else{
          var pth2 = ((window.innerHeight /100)*45)  - 80 - fh.current.clientHeight - fp.current.clientHeight;
        }
        var pt2 = (pth2/2 - 15);
        if( pth2 > 60 ){
          ax = pt2;
        }
      }


      if(image1.current.getBoundingClientRect().top < ftext5.current.clientHeight - ax + 80){
        imageClip2.current.style.position =  "fixed";
        imageClip2.current.style.top =  (ftext5.current.clientHeight - ax + 80)+"px";
        imageClip3.current.style.position =  "fixed";
        imageClip3.current.style.top =  (ftext5.current.clientHeight - ax + 80)+"px";
      }else{
        imageClip2.current.style.position =  "absolute";
        imageClip2.current.style.top = "0px";
        imageClip3.current.style.position =  "absolute";
        imageClip3.current.style.top = "0px";
      }

      if ( bg2.current != null && bg.current != null){
        if(imageClip3.current.getBoundingClientRect().top < 0){
          bg.current.style.display = "none";
          bg2.current.style.display = "none";
        }else{
          bg.current.style.display = "block";
          bg2.current.style.display = "block";
        }
      }
      
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollingEffect);
    return () => {
      window.removeEventListener("scroll", () => {});
    }
  }, [])

  return (
    <section className='two-sides-platform' >
      <div className="bg-white"  ref={bg}></div>
      <div className="bg-black"  ref={bg2}></div>
      <div className='two-sides-platform-part two-sides-platform-part--dark slide-1'>
        <div className='container-'>
          <div className='two-sides-platform-part__wrapper'>
            <div className='text-wraper'>
              <div className="text-contents" ref={fdiv1}>
                <div className="heading-wrap" ref={ftext1}>
                  <h2 className='two-sides-platform-part__title section-main-title'  ref={fh}>{content?.title}</h2>
                </div>
                <div className="paralax-texts">
                  <div className="paragraph-wrap" ref={ftext2}>
                    <p className='two-sides-platform-part__text'  ref={fp}>
                      <span>{content?.description}</span>
                    </p>
                  </div>
                  <div className="stop1" ref={stop1}></div>
                  <div className="heading-wrap2" ref={ftext3}>
                    <h2 className='two-sides-platform-part__title section-main-title'>{card02Title}</h2>
                  </div>
                  <div className="paragraph-wrap2" ref={ftext4}>
                    <p className='two-sides-platform-part__text'>
                      <span>{card02SubTitle}</span>
                    </p>
                  </div>
                  <div className="stop2" ref={stop2}></div>

                  <div className="heading-wrap3" ref={ftext5}>
                    <div className="inner-wrap"  ref={ftext5inner}>
                      <h2 className='two-sides-platform-part__title section-main-title'>{card01Title}</h2>
                    </div>
                  </div>
                  <div className="paragraph-wrap3" ref={ftext6}>
                    <p className='two-sides-platform-part__text'>
                      <span>{card01SubTitle}</span>
                    </p>
                  </div>
                  <div className="stop3" ref={stop3}></div>

                  <div className="heading-wrap4" ref={ftext7}>
                    <h2 className='two-sides-platform-part__title section-main-title'>{card02Title}</h2>
                  </div>
                  <div className="stop4" ref={stop4}></div>
                </div>
              </div>
            </div>
            <div className='two-sides-platform-part-block' ref={image1}>
              <div className="third-image" ref={imageClip3}>
                <div className='two-sides-platform-part-block-picture'>
                  <img
                    src={platformDashboardPrimary}
                    alt='dashboard'
                    className='two-sides-platform-part-block-picture__image two-sides-platform-part-block-picture__image--desktop'
                    />
                </div>
              </div>
              <div className="second-image" ref={imageClip2}>
                <div className='two-sides-platform-part-block-picture'>
                  <img
                    src={crmPrimary}
                    alt='dashboard'
                    className='two-sides-platform-part-block-picture__image two-sides-platform-part-block-picture__image--desktop'
                    />
                </div>
              </div>
              <div className="first-image" ref={imageClip}>
                <div className='two-sides-platform-part-block-picture'>
                  <img
                    src={platformDashboardPrimary}
                    alt='dashboard'
                    className='two-sides-platform-part-block-picture__image two-sides-platform-part-block-picture__image--desktop'
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
