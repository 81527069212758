import { FC } from "react"
import { useContent } from "src/components/App/Content"
import DefaultLayout from "src/layouts"
import { ClientsSection } from "src/sections/commonSections/ClientsSection/ClientsSection"
import { ClientProps } from "../Capterra/crm/Capterra"
import Pricing from "../Pricing/Pricing"
import { CustomerExperienceSection } from "src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection"
import customerExperienceImage from 'src/sections/commonSections/CustomerExperienceSection/images/customer-experience-image-1.jpg'

const PricingPage: FC = () => {
  const clientsContent = useContent('home-clients')
  
  return (
    <DefaultLayout isHeaderFixed={false} isFooterAnimated={false} hasVideoBanner={false}>
      <Pricing />
      <ClientsSection isHomePage={true} clients={clientsContent?.cards as ClientProps[]} title={clientsContent?.title} hasSeeMore={false} />
      <CustomerExperienceSection image={customerExperienceImage} isAnimated={false} />
    </DefaultLayout>
  )
  }
  
  export default PricingPage