import { useMemo } from 'react'

import { PostFragment, usePostsQuery } from '../../graphql'

function usePosts(): Array<PostFragment | null | undefined> | null | undefined {
  const { loading, data } = usePostsQuery()
  const content = useMemo(() => (!loading && data?.posts ? data.posts : []), [loading, data])
  return content
}

/**
 * Returns single content block by slug
 * @param {PostFragment} slug
 */
function usePost(slug: string): PostFragment | null | undefined {
  const { loading, data } = usePostsQuery()
  const content = useMemo(() => (!loading && data?.posts ? data.posts : []), [loading, data])
  const post = content.find(it => it?.slug === slug)
  return post
}

export { usePost, usePosts }
