import React from 'react'
import { usePlatforms } from 'src/components/App/Platforms'
import { PlatformFragment } from 'src/graphql'

const PlatformsList = ({
    images
}: {
    images: (string | undefined)[] | undefined
}) => (
    <ul className='platforms-list'>
        {images?.map((item, index) => (
            <li key={index} className='platforms-list-item'>
                <div className='platforms-list-item__picture'>
                    <img src={item} alt='platform-logo' className='platforms-list-item__image' />
                </div>
            </li>
        ))}
    </ul>
)

export default PlatformsList