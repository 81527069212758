import { FC } from 'react'
import { DefaultLayout } from './DefaultLayout'
import { Outlet } from 'react-router-dom'

const CapterraLayout: FC = () => (
  <DefaultLayout
    isHeaderFixed={true}
    isHeaderShort={true}
    hasLogin={false}
    isHeaderIndependent={true}
    isFooterAnimated={false}
    isFooterShort={true}
    footerContent={true}
    headerText={'Powerful CRM for Member-Based Lifestyle Hospitality Businesses'}
  >
    <Outlet />
  </DefaultLayout>
)
export { CapterraLayout }
