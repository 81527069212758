import 'src/components/Header/Header.scss'

import { DOMAttributes, FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg'
import { MenuContext } from 'src/components/App/App'
import { ReactComponent as LogoIcon } from 'src/components/App/images/logo-icon.svg'
import { ReactComponent as LogoText } from 'src/components/App/images/text-icon.svg'

import Switcher from '../Form/Switch'
import { LogInButton } from './components/LogInButton'
import { LoginRSVPButton } from './components/LoginRSVPButton'
import HeaderOptionsWrapper from './components/HeaderOptionsWrapper'


const BurgerButton: FC<DOMAttributes<Element>> = props => (
  <button role='toggle' className='header-button-menu' {...props}>
    <div className='header-button-menu-burger'>
      <div className='header-button-menu-burger__line header-button-menu-burger__line-first' />
      <div className='header-button-menu-burger__line header-button-menu-burger__line-second' />
      <div className='header-button-menu-burger__line header-button-menu-burger__line--closer header-button-menu-burger__line--closer-first' />
      <div className='header-button-menu-burger__line header-button-menu-burger__line--closer header-button-menu-burger__line--closer-second' />
    </div>
    <p className='header-button-menu__text'>Menu</p>
  </button>
)

interface IHeader {
  isHeaderFixed: boolean
  isShort: boolean
  hasSignup: boolean
  isHeaderIndependent: boolean
  theme: string
  headerText?: string,
  hasVideoBanner: boolean
  customLogo?: ReactNode
}

export const Header: FC<IHeader> = ({ theme, isHeaderFixed, isShort, hasSignup, isHeaderIndependent, headerText, hasVideoBanner, customLogo }) => {
  const navigate = useNavigate()
  const headerRef = useRef<HTMLElement>(null)
  const [currentTheme, setCurrentTheme] = useState<string>(theme)
  const location = useLocation()
  const capterra = location.pathname.includes('capterra')

  const [showHeaderOptionsDropdown, setShowHeaderOptionsDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (showHeaderOptionsDropdown) setShowHeaderOptionsDropdown(false)
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showHeaderOptionsDropdown]);

  useEffect(() => {
    let lastScrollTop = 0
    const heightReference = window.innerHeight - (headerRef.current?.offsetHeight ?? 0);

    const headerToggler = () => {

      if (window.location.pathname != "/") return null;

      const scrollTop = window.pageYOffset

      if (
        headerRef.current !== null &&
        scrollTop > lastScrollTop &&
        scrollTop > 150 &&
        headerRef.current.classList.contains('header--fixed')
      ) {
        headerRef.current.classList.add('header--hidden')
      } else if (headerRef.current !== null && scrollTop < lastScrollTop) {
        headerRef.current.classList.remove('header--hidden')
      }
      lastScrollTop = scrollTop

      if (hasVideoBanner) {
        // if (scrollTop > 100 && headerRef.current !== null) {
        //   headerRef.current.classList.add('header--top')
        // } else if (headerRef.current !== null && scrollTop <= 100) {
        //   headerRef.current.classList.remove('header--top')
        // }
        if (scrollTop <= heightReference && headerRef.current !== null) {
          headerRef.current.classList.add('header-on-video')
        } else if (headerRef.current !== null && scrollTop > heightReference) {
          headerRef.current.classList.remove('header-on-video')
        }
      }
    }

    window.addEventListener('scroll', () => headerToggler())

    return () => {
      window.removeEventListener('scroll', () => headerToggler())
    }
  }, [])

  useEffect(() => {
    const wrapper: HTMLBodyElement | null = document.querySelector('.main')

    if (currentTheme === 'light') {
      wrapper?.classList.add('theme--light')
      wrapper?.classList.remove('theme--dark')
    } else if (currentTheme === 'dark') {
      wrapper?.classList.remove('theme--light')
      wrapper?.classList.add('theme--dark')
    }
  }, [currentTheme])

  return (
    <header
      className={`header ${hasVideoBanner ? 'header-on-video' : ''} ${isHeaderFixed ? 'header--fixed' : ''} ${isShort ? 'header--short' : ''} ${isHeaderIndependent ? 'header-ind' : ''
        } ${hasSignup ? 'header--hasSignup' : ''}`}
      ref={headerRef}
    >
      <div className='header__wrapper'>
        {isShort ? (
          customLogo ? (
            customLogo
          ) : (
            <div className='header-logo'>
              <a href={'https://peoplevine.com'} className='header-logo__link'>
                <LogoIcon className={'header-logo__icon'} />
                <LogoText className={'header-logo__text-icon'} />
              </a>
            </div>
          )
        ) : (
          <MenuContext.Consumer>{({ onClick }) => <BurgerButton onClick={onClick} />}</MenuContext.Consumer>
        )}
        {isShort ? (
          headerText ? (
            <h3 className={'header-logo__text'}>{headerText}</h3>
          ) : (
            <div />
          )
        ) : (
          <div className='header-logo'>
            <Link to='/' className='header-logo__link'>
              <LogoIcon className={'header-logo__icon'} />
              <LogoText className={'header-logo__text-icon'} />
            </Link>
            <nav className={'header-request'}>
              <a
                onClick={() => {
                  if (capterra) {
                    document.querySelector('#capterra-contact')?.scrollIntoView()
                  } else {
                    navigate('/form')
                  }
                }}
                className='header-panel-button'
              >
                <span className='header-panel-button__text'>Request a demo</span>
              </a>
            </nav>
          </div>
        )}
        {isShort ? (
          <nav>
            {isHeaderIndependent ? (
              <>
                {hasSignup ? (
                  <a onClick={() => document.querySelector('#getenterpass-contact')?.scrollIntoView()} className='header-panel-button'>
                    <span className='header-panel-button__text'>Sign up</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      if (capterra) {
                        document.querySelector('#capterra-contact')?.scrollIntoView()
                      } else {
                        navigate('/form')
                      }
                    }}
                    className='header-panel-button'
                  >
                    <span className='header-panel-button__text'>Request a demo</span>
                  </a>
                )}
              </>
            ) : (
              <>
                <div className="header-login-rsvp-button">
                  <LoginRSVPButton />
                </div>
                {hasSignup && (
                  <div className='theme-switcher' onClick={() => setCurrentTheme(prevState => (prevState === 'light' ? 'dark' : 'light'))}>
                    <Switcher checked={currentTheme !== 'dark'} />
                  </div>
                )}
              </>
            )}
          </nav>
        ) : (
          <>
            <nav className={'header-request-side'}>
              <a
                onClick={() => {
                  if (capterra) {
                    document.querySelector('#capterra-contact')?.scrollIntoView()
                  } else {
                    navigate('/form')
                  }
                }}
                className='header-panel-button'
              >
                <span className='header-panel-button__text'>Request a demo</span>
              </a>
            </nav>
            <LogInButton
              handleOpenDropdown={() => setShowHeaderOptionsDropdown(!showHeaderOptionsDropdown)}
            />
          </>
        )}
      </div>

      <HeaderOptionsWrapper
        openOptionsDropdown={showHeaderOptionsDropdown}
      />
    </header>
  )
}
