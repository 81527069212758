import { ContentFragment } from "src/graphql"
import PlatformsList from "../components/PlatformsList"

const integrationDataAdapter = (dataContent: ContentFragment | null | undefined) => {

    const cards = dataContent?.cards
    const opsIntegrationFeatures = cards?.map((card, index) => {
        const images: (string | undefined)[] | undefined = card?.media_slides?.map( card => card?.url)
        return {
            title: card?.title,
            // image: `${crmIntegrate}`,
            customComponent: (
                <PlatformsList 
                    images={images}
                />
            ),
            additionalClass: '',
            about: card?.description,
            list: [

            ],
            backgroundColor: '#fff',
            titleColor: '#2f3034',
            textColor: '#81848e',
        }
    })
    return opsIntegrationFeatures;
}

export default integrationDataAdapter