import 'src/sections/homePageSections/ServicesSection/ServicesSection.scss'

import { FC } from 'react'
// import service1 from 'src/assets/images/services/image-1.png'
// import service2 from 'src/assets/images/services/image-2.png'
// import service3 from 'src/assets/images/services/image-3.png'
// import service4 from 'src/assets/images/services/image-4.png'

import servicePattern2 from './images/services-pattern-2.png'
import { useContent } from '../../../components/App/Content'

// const servicesItems = [
//   {
//     title: 'Private Members’ Clubs',
//     about:
//       'Private social clubs have evolved to suit modern life and modern members who live, work, and play in city centers around the world.',
//     image: service1,
//   },
//   {
//     title: 'Hotels and resorts',
//     about:
//       'Popular destinations for locals and travelers alike, many of these properties have begun to establish a members club to differentiate themselves from the competition.',
//     image: service2,
//   },
//   {
//     title: 'Luxury Residential Communities',
//     about:
//       'As high-end residences and condo hotels up their amenities, developers are looking to leverage the power of membership to enhance the residential experience.',
//     image: service3,
//   },
//   {
//     title: 'Coworking',
//     about:
//       'To accommodate a growing number of start-ups and remote workers, coworking spaces are introducing more tiered levels of membership to their offerings.',
//     image: service4,
//   },
// ]

export type ServicesSectionProps = {
  title?: string
  titleMaxWidth?: string | number
  theme?: 'light' | 'grey' | 'dark'
}

export const ServicesSection: FC<ServicesSectionProps> = ({
  title = 'SUBSCRIPTION MANAGEMENT, CRM AND MEMBER EXPERIENCE PLATFORM',
  titleMaxWidth,
  theme = 'grey',
}) => {
  const content = useContent('home-services')
  return (
    <section className={`services services-${theme}`} id='services'>
      <div className='ornament-block'>
        <img src={servicePattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='services__wrapper'>
          <h2 className='services__title section-main-title' style={{ maxWidth: titleMaxWidth ?? '' }}>
            {title}
          </h2>
          <div className='services-list'>
            {content?.cards?.map(service => (
              <article key={service?.title} className='services-list-item'>
                <div className='services-list-item__content'>
                  <h5 className='services-list-item__title section-secondary-title'>{service?.title}</h5>
                  <p className='services-list-item__text'>{service?.subTitle}</p>
                </div>
                <div className='services-list-item__picture'>
                  <img src={service?.media?.url} alt='serices-image' className='services-list-item__image' />
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
