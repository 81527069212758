import 'src/sections/productPageSections/ProductGuideSection/ProductGuideSection.scss'

import { FC } from 'react'
import Button from 'src/components/Form/Button'
import { useContent } from '../../../components/App/Content'

export const ProductGuideSection: FC = () => {
  const content = useContent('product-product-guide')
  return (
    <section className='product-guide'>
      <div className='container'>
        <div className='product-guide__wrapper'>
          <div className='product-guide-block'>
            <div className='product-guide-block__picture'>
              <img src={content?.section?.media?.url} alt='dashboard' className='product-guide-block__image' />
            </div>
          </div>
          <div className='product-guide-block'>
            <h1 className='product-guide-block__title main-title'>{content?.title}</h1>
            <p className='product-guide-block__text'>{content?.description}</p>
            <Button to={'/form'}>{content?.button?.link?.title}</Button>
          </div>
          <div className='product-guide-block'>
            <ul className='product-guide-block-list'>
              {content?.cards?.map(slide => (
                <li key={slide?.id} className='product-guide-block-list__item'>
                  {slide?.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
