import Button from 'src/components/Form/Button';
import './ElevateDiningContentSection.scss';

import BackgroundImage from 'src/assets/images/backgrounds/peoplevine-elevate-dining-background-with-patterns.jpg'
import BackgroundImage2 from 'src/assets/images/backgrounds/peoplevine-elevate-dining.jpg';

const ElevateDiningContentSection = () => {
    return (
        <section className='elevate-dining-content-section'>
            <div className="container block-content without-peoplevine-wrapper">
                <h1>Without Peoplevine...</h1>
                <ul>
                    <li>Calling</li>
                    <li>Texting</li>
                    <li>Public reservations systems</li>
                    <li>Lines</li>
                    <li>Paid with cash</li>
                    <li>Pulling out your card</li>
                    <li>No tables available</li>
                    <li>Table is not guaranteed</li>
                </ul>
            </div>
            <div
                className="container block-content with-peoplevine-wrapper"
                style={{
                    backgroundImage: `url(${BackgroundImage})`
                }}
            >
                <h1>With Peoplevine...</h1>
                <ul>
                    <li>Reservations systems</li>
                    <li>Member app</li>
                    <li>You are a member</li>
                    <li>They know your expectations</li>
                    <li>Personalized experiences</li>
                </ul>
                <Button to={'/form'}>Request a demo</Button>
            </div>

            <div className='background-image-wrapper'>
                <img src={BackgroundImage2} alt="background image" />
            </div>
        </section>
    )
}

export default ElevateDiningContentSection