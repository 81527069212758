import './ClientsSection.scss'
import { FC, useEffect } from 'react'
import clientsPattern2 from './images/clients-pattern-2.png'
import clientsPattern3 from './images/clients-pattern-3.png'
import clientsPattern4 from './images/clients-pattern-4.png'
import { ComponentOrganismsSection, Maybe, SectionFragment } from '../../../graphql'
import { ClientProps } from '../../../pages/Capterra/crm/Capterra'

export type IClientsSection = {
  isHomePage?: boolean
  hasReadMore?: boolean
  centered?: boolean
  qty?: number
  title?: string
  hasSeeMore?: boolean
  theme?: 'light' | 'grey' | 'dark'
  clients: ClientProps[]
}

export const ClientsSection: FC<IClientsSection> = ({
  theme = 'light',
  isHomePage,
  hasSeeMore = true,
  clients,
  hasReadMore = true,
  centered = false,
  qty = 3,
  title,
}) => {
  useEffect(() => {
    const clientItems = document.querySelectorAll('.clients-list--home .clients-list-item')
    const clientsButton = document.querySelector('.clients-show-more-button')

    clientItems.forEach((client, index) => {
      if (hasSeeMore) {
        index < 3 && client.classList.add('clients-list-item--active')
      } else {
        client.classList.add('clients-list-item--active')
      }
    })

    const expandFunc = () => {
      clientItems.forEach(client => {
        client.classList.add('clients-list-item--active')
      })
      clientsButton?.classList.add('clients-show-more-button--hidden')
    }

    clientsButton?.addEventListener('click', () => expandFunc())

    return () => {
      clientsButton?.removeEventListener('click', () => expandFunc())
    }
  })

  return (
    <section className={`clients ${isHomePage ? 'clients--home' : 'clients--pricing'} clients-${theme}`} role={`${!isHomePage ? 'presentation' : ''}`}>
      <div className='ornament-block'>
        <img src={clientsPattern2} alt='ornament' className='ornament-block__image' />
        <img src={clientsPattern3} alt='ornament' className='ornament-block__image' />
        <img src={clientsPattern4} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='clients__wrapper'>
          <h2 className='clients__title section-main-title'>{title ?? 'Some of our clients'}</h2>
          <div className={`clients-list clients-list--home clients-list-${qty}`}>
            {clients?.map((client, index) => {
              if (isHomePage && !client?.title) return
              return (
                <article key={index} className={`clients-list-item ${centered && 'centered'} ${!client?.description && 'no-padding'}`}>
                  {isHomePage && client?.title && <h5 className='clients-list-item__title'>{client.title}</h5>}
                  <div className='clients-list-item__logo'>
                    <img src={client?.media?.url} alt={'img'} />
                  </div>
                  {isHomePage && client?.subTitle && <p className='clients-list-item__text'>{client?.subTitle}</p>}
                  {isHomePage && hasReadMore && index < 3 && (
                    <a href={`/clients#${client?.description}`} className='clients-list-item__link'>
                      Read more
                    </a>
                  )}
                </article>
              )
            })}
          </div>
          {isHomePage && hasSeeMore && (
            <button className='clients-show-more-button clients-show-more-button--home'>
              <span className='clients-show-more-button__text'>See more clients</span>
              <svg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1.46945 5H14.4695' stroke='#D58713' strokeWidth='1.5' strokeLinecap='square' />
                <path d='M11.4695 1L15.4695 5L11.4695 9' stroke='#D58713' strokeWidth='1.5' />
              </svg>
            </button>
          )}
        </div>
      </div>
    </section>
  )
}
