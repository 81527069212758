import 'src/sections/productPageSections/ProductItemSection/ProductItemSection.scss'

import { FC, PropsWithChildren, useState } from 'react'
import Modal from 'src/components/Drawer/Modal'
import { IProductItem } from 'src/types/product'

import { Maybe, PlatformFragment } from '../../../graphql'



export type IntegrationDrawerProps = {
  id: string | null | undefined
  platformItemLogo: string
  link?: string
  platformItemAbout: string
  preview?: string
  description?: {
    title: string
    description: string
  }[]
  features?: {
    title: string
    list: string[]
  }[]
}

export const IntegrationDrawer: FC<PropsWithChildren<Maybe<Partial<PlatformFragment>>>> = props => {
  return (
    <div className='integration-drawer'>
      <div>
        <img className='logo' src={props.logo?.url} alt={'logo'} />
        <p className='about'>{props.title}</p>
        <img className='preview' src={props?.preview?.url} alt={'image'} />
      </div>
      <div>
        {props.description_card?.length && (
          <>
            {props.description_card.map((item, index) => (
              <div key={index} className='block-info'>
                <h2>{item?.title}</h2>
                <p>{item?.description}</p>
              </div>
            ))}
          </>
        )}
        {/* {props.features_title && (
          <div className='block-info'>
            <h2>{props.features_title}</h2>
            <ul>
              {props?.features_list?.map((item, index) => (
                <li key={index}>
                  <p>{item?.features_list_item}</p>
                </li>
              ))}
            </ul>
          </div>
        )} */}
      </div>
    </div>
  )
}

export const ProductItemSection: FC<Partial<IProductItem>> = props => {
  const [drawer, setDrawer] = useState<Partial<PlatformFragment> | null>()
  let backgroundColor: string
  let titleColor: string
  let textColor: string
  // const posPlatforms = usePlatforms('pos')
  // const pmsPlatforms = usePlatforms('pms')
  // const reservationPlatforms = usePlatforms('reservation')
  // const paymentPlatforms = usePlatforms('payment-processor')

  // const productIntegrations = {
  //   title: 'Integrations',
  //   about: 'Unlike other CRM platforms, Peoplevine is innately equipped for third-party integrations so you can streamline your operations and see a holistic view of your members across all your systems.',
  //   platforms: [
  //     {
  //       platformsTitle: 'Point of sale system',
  //       platformList: posPlatforms
  //     },
  //     {
  //       platformsTitle: 'Property Management Systems (PMS)',
  //       platformList: pmsPlatforms
  //     },
  //     {
  //       platformsTitle: 'Reservations Systems',
  //       platformList: reservationPlatforms
  //     },
  //     {
  //       platformsTitle: 'Payment Processors',
  //       gridList: paymentPlatforms?.map(platform => ({
  //         title: platform?.title,
  //         image: platform?.logo?.url
  //       }))
  //     }
  //   ]
  // }

  switch (props.themeColor) {
    case 'black':
      backgroundColor = '#202123'
      titleColor = '#fefefe'
      textColor = '#c1c3c9'
      break

    case 'grey':
      backgroundColor = '#f8f8f8'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break

    case 'white':
      backgroundColor = '#fff'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break

    default:
      backgroundColor = '#fff'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break
  }

  return (
    <section
      className={`product-item product-item--${props.index} product-item--${props.additionalClass}`}
      style={{ backgroundColor: backgroundColor }}
      data-color={props.themeColor}
    >
      <div className='ornament-block'>
        {props.patterns?.map((pattern, index) => (
          <img key={index} src={pattern.image} style={pattern.styles} alt={pattern.image} className='ornament-block__image' />
        ))}
      </div>
      <div className='container'>
        <div className='product-item__wrapper'>
          <div className='product-item-block'>
            <h2 className='product-item-block__title section-main-title' style={{ color: titleColor }}>
              {props.title}
            </h2>
            <p className='product-item-block__text' style={{ color: textColor }}>
              {props.about}
            </p>
          </div>
          <div className='product-item-block'>
            {props.platforms &&
              props.platforms.map((item, index) => (
                <div className='product-platforms' key={index}>
                  <h5 className='product-platforms__title section-secondary-title' style={{ color: titleColor }}>
                    {item.platformsTitle}
                  </h5>
                  {/* {item.platformsAbout && (
                    <p className='product-platforms__text' style={{ color: textColor }}>
                      {item.platformsAbout}
                    </p>
                  )} */}
                  {item.platformsList && (
                    <ul className='product-platforms-list'>
                      {item.platformsList.map((item, index) => (
                        <li key={index} className='product-platforms-list-item'>
                          <div className='product-platforms-list-item__picture'>
                            <img src={item?.logo?.url} alt='logo' className='product-platforms-list-item__image' />
                          </div>
                          <p className='product-platforms-list-item__text' style={{ color: textColor }}>
                            {item?.title}
                          </p>
                          {item?.description_card && item?.description_card.length !== 0 && (
                            <a className='product-platforms-list-item__button' onClick={() => item && setDrawer(item)}>
                              Read more
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.gridList && (
                    <ul className='grid-list'>
                      {item.gridList.map((item, index) => (
                        <li key={index}>
                          <div className='grid-list__picture'>
                            <img src={item.image} alt={item.title ?? ''} className='grid-list__image' />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            {props.list && (
              <div className='product-item-block-list'>
                {props.list.map((productItem, index) => (
                  <article key={productItem.listItemTitle} className='product-item-block-list-item'>
                    {
                      productItem.image && (
                        <div
                          className={`product-item-block-list-item__picture  
                        product-item-block-list-item__picture--${index + 1}`}
                        >
                          <img src={productItem.image} alt='dashboard' className='product-item-block-list-item__image' />
                        </div>
                      )
                    }
                    <div className='product-item-block-list-item__content'>
                      <h6 className='product-item-block-list-item__title section-secondary-title' style={{ color: titleColor }}>
                        {productItem.listItemTitle}
                      </h6>
                      <p className='product-item-block-list-item__text' style={{ color: textColor }}>
                        {productItem.listItemAbout}
                      </p>
                    </div>
                  </article>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={!!drawer} setIsOpen={setDrawer}>
        {drawer && <IntegrationDrawer {...drawer} />}
      </Modal>
    </section>
  )
}
