import DefaultLayout from 'src/layouts';
import BrandSection from 'src/sections/brandPageSections/BrandSection/BrandSection';
import { replaceSpecialCharacters } from 'src/utils';
import './Brands.scss';
import { useBrands } from './hook/useBrands';



const Brands = () => {
    const brands = useBrands()

    const handleDownload = async (imageUrl: string, altText: string | null | undefined) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Create a blob URL
            const blobUrl = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = replaceSpecialCharacters(altText || 'download_image.jpg'); // Specify the downloaded file name
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Cleanup
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error occurred while downloading:', error);
            // Handle errors if fetch or download fails
        }
    };

    return (
        <DefaultLayout isHeaderFixed={false} isFooterAnimated={false} hasVideoBanner={false}>
            <section className="brands">
                <div className="container">
                    <h1 className="main-title">brand assets</h1>
                    {brands.map(brand => <BrandSection brand={brand} onHandleDownload={handleDownload} key={brand.title} />)}
                </div>
            </section>
        </DefaultLayout>
    )
}

export default Brands