import './Blog.scss'

import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { usePosts } from 'src/components/App/Posts'
import { Maybe, MetaFragment, PostFragment } from '../../../graphql'

type AuthorProps = {
  image?: string
  name: string
  position?: string
}

export type PostProps = Partial<{
  title: string
  author: AuthorProps[]
  category: string
  meta: Maybe<MetaFragment | undefined>[]
  preview?: string
  content: string
  id: string
  source?: string
  timeToRead?: string
  link?: string
}>

function readingTime(content: string) {
  const wordsPerMinute = 225
  const words = content.trim().split(/\s+/).length
  return Math.ceil(words / wordsPerMinute)
}

const TOTAL_BLOG_PER_PAGE = 9

const BlogPost: FC<Partial<PostProps>> = props => {
  return (
    <Link to={`/rsvp/${props.link}`} className='blog__post'>
      <div className='blog__post-wrapper'>
        <img src={props.preview} alt={props.title} />
        <div className='blog__post-info'>
          <div className='reading-info'>
            <p>{props.timeToRead} mins read</p>
            {props.source && <p className='source'>Source: {props.source}</p>}
          </div>
          <p className='blog__post-text'>{props.title}</p>
        </div>
      </div>
    </Link>
  )
}

const Blog: FC = () => {
  const initialPosts = usePosts()
  const [searchParams] = useSearchParams()

  const [filters, setFilters] = useState({
    category: 'all'
  })

  const [pagination, setPagination] = useState({
    page: 1,
    totalPage: 0,
    totalBlogs: 0
  });

  const filterPosts = (posts: (PostFragment | null | undefined)[]) => {
    return posts.filter(post => {
      const slug = post?.category?.slug
      return (
        filters.category === 'all' ||
        slug === filters.category
      )
    })
  }

  const sortingPost = useMemo(() => {
    if (initialPosts?.length) {
      const posts = filterPosts(initialPosts)

      setPagination({
        ...pagination,
        totalBlogs: posts.length,
        totalPage: Math.ceil(posts.length / TOTAL_BLOG_PER_PAGE)
      })

      const lastPosition = TOTAL_BLOG_PER_PAGE * pagination.page
      const firstPosition = lastPosition - TOTAL_BLOG_PER_PAGE

      const orderPost = [...posts].sort((a, b) => {
        if (Number(a?.id) < Number(b?.id)) {
          return 1;
        } else if (Number(a?.id) > Number(b?.id)) {
          return -1;
        } else {
          return 0;
        }
      });
      return orderPost.slice(firstPosition, lastPosition)
    }
    return []
  }, [initialPosts?.length, pagination.page, filters])

  useEffect(() => {
    const category = searchParams.get('category')
    setFilters({ 
      category: category ?? 'all'
    })

  }, [searchParams])

  return (
    <div className='blog__wrapper'>
      <div className='blog__list'>
        {sortingPost?.map(item => (
          <BlogPost
            key={item?.id}
            content={`${item?.text}`}
            id={`${item?.id}`}
            preview={item?.previews?.url}
            title={item?.name}
            category={`${item?.category?.name}`}
            link={`${item?.slug}`}
            author={
              item?.authors?.map(it => ({
                name: `${it?.firstName} ${it?.lastName}`,
                image: it?.photo?.url,
                position: it?.occupation?.name,
              })) ?? []
            }
            timeToRead={`${readingTime(item?.text || '')}`}
          />
        ))}
      </div>

      <div className='blog__paginate'>
        <ul>
          {Array.from({ length: pagination.totalPage }, (_, index) => (
            <li
              key={index}
              className={`${(pagination.page === index + 1) ? 'active' : ''}`}
              onClick={() => setPagination({
                ...pagination,
                page: index + 1
              })}
            >
              {index + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Blog
