import { IBrand } from "src/pages/Brands/interface"

interface BrandSectionProps {
    brand: IBrand;
    onHandleDownload: (imageUrl: string, altText: string | null | undefined) => void;
}

const BrandSection = ({
    brand,
    onHandleDownload
}: BrandSectionProps) => (
    <div className="brand-section__wrapper">
        <div className="brand-section__header">
            <h3 className="brand-section__title">{brand.title}</h3>
            <h4 className="brand-section__description">{brand.description}</h4>
        </div>
        {
            brand.brandSections?.map(section => (
                <div className="brand-section__content" key={section.title}>
                    <div className="content__header">
                        <h3 className="content__header-title">{section.title}</h3>
                        <h4 className="content__header-description">{section.description}</h4>
                    </div>
                    <div className={`content__images ${section?.slug ?? ''}`}>
                        {section.images?.map(image => (
                            <button
                                className="image-container" key={image.altText}
                                onClick={() => onHandleDownload(image.url!, image.altText)}

                            >
                                <img src={image.url!} alt={image.altText || ''} />
                                {!(!!section?.slug && !!image?.caption)
                                    ? <p>{image.altText}</p>
                                    : (
                                        <div className={`text-with-background text-color-${image.caption.replace('#', '').trim()}`}>
                                            <p>{image.altText}</p>
                                            <p>{image.caption}</p>
                                        </div>
                                    )
                                }
                            </button>
                        ))}
                    </div>
                </div>
            ))
        }
    </div>
)

export default BrandSection