import { useMemo } from 'react'

import { PlatformFragment, usePlatformsQuery } from '../../graphql'

function usePlatforms(type = ''): Array<PlatformFragment | null | undefined> | null | undefined {
  const { loading, data } = usePlatformsQuery()
  const content = useMemo(() => (!loading && data?.platforms ? data.platforms : []), [loading, data])
  return type ? content.filter(it => it?.subTitle === type) : content
}

export { usePlatforms }
