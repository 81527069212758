import { useContent } from 'src/components/App/Content'
import TickerImagesClientBar from 'src/components/TickerImagesBar/TickerImagesClientBar'
import { MobileLayout } from 'src/layouts'
import { CustomerExperienceSection } from 'src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection'
import ElevateDiningContentSection from 'src/sections/ElevateDiningContentSection/ElevateDiningContentSection'
import HeaderDescriptionSection from 'src/sections/HeaderDescriptionSection/HeaderDescriptionSection'
import VideoElevateDiningSection from 'src/sections/VideoElevateDiningSection/VideoElevateDiningSection'
import coverImageVideo from 'src/assets/images/backgrounds/peoplevine-elevate-dining-video-cover.jpg'
import { ReviewsSection } from 'src/sections/ReviewsSection/ReviewsSection'

const ElevateDiningPage = () => {
  const videoElevateDiningSection = useContent('video-elevate-dining-section')

  return (
    <MobileLayout>
      <VideoElevateDiningSection
        urlVideo={videoElevateDiningSection?.section?.videoDesktop?.url}
        coverImage={coverImageVideo}
      />
      <HeaderDescriptionSection 
        title='Elevate Dining'
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nisi massa, mollis vel tempus quis, mollis eget tortor. Cras imperdiet imperdiet mattis. Vivamus vel orci nisi. Etiam vitae neque porta, viverra velit et, consectetur elit.'
      />
      <TickerImagesClientBar />
      <ElevateDiningContentSection />
      <ReviewsSection />
      <CustomerExperienceSection 
        isAnimated={false} 
        slug='elevate-dining-customer-experience'
        customClass='customer-experience--elevate-dining'
      />
    </MobileLayout>
  )
}

export default ElevateDiningPage