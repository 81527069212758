import { FC } from 'react'
import hapi from 'src/assets/icons/pms/hapi.svg'
import mews from 'src/assets/icons/pms/mews.svg'
import operacloud from 'src/assets/icons/pms/operacloud.svg'
import sevenRoomsPreview from 'src/assets/images/partners/7-rooms-preview.png'
import sevenRooms from 'src/assets/images/partners/7-rooms.svg'
import authorizeNet from 'src/assets/images/partners/authorize_net.svg'
import braintree from 'src/assets/images/partners/braintree.svg'
import cardconnect from 'src/assets/images/partners/cardconnect.svg'
import cybersource from 'src/assets/images/partners/cybersource.svg'
import gocardless from 'src/assets/images/partners/gocardless.svg'
import ncrAlohaPreview from 'src/assets/images/partners/ncr-aloha-preview.png'
import ncrAloha from 'src/assets/images/partners/ncr-aloha.svg'
import squarePreview from 'src/assets/images/partners/square-preview.png'
import square from 'src/assets/images/partners/square.svg'
import stripe from 'src/assets/images/partners/stripe.svg'
import { DoYouIntegrateSection } from 'src/sections/productPageSections/DoYouIntegrateSection/DoYouIntegrateSection'
import { ProductGuideSection } from 'src/sections/productPageSections/ProductGuideSection/ProductGuideSection'

import VideoSliderSection from 'src/sections/productPageSections/VideoSliderSection/VideoSliderSection'
import DefaultLayout from '../../layouts'
import Feature from '../../sections/homePageSections/FeatureSection/FeatureSection'
import KeyFeature from '../../sections/homePageSections/KeyFeaturesSection/KeyFeaturesSection'
import { HomeVideoSection } from 'src/sections/homePageSections/HomeVideoSection/HomeVideoSection'
import { HeroSliderSections } from 'src/sections/HeroSliderSections/HeroSliderSections'



export const productsIntegration = {
  title: 'Integrations',
  about:
    'Unlike other CRM platforms, Peoplevine is innately equipped for third-party integrations so you can streamline your operations and see a holistic view of your members across all your systems.',
  platforms: [
    {
      platformsTitle: 'Point of sale systems',
      platformsList: [
        {
          platformItemLogo: square,
          platformItemAbout: 'Tools to run your business—even when it’s not business as usual',
          preview: squarePreview,
          description: [
            {
              title: 'Know What Your Members Love',
              description: 'Sync members purchases, spend, and dietary preferences to their CRM profile and personalize your hospitality.',
            },
          ],
          features: [
            {
              title: 'Make Paying Convenient',
              list: [
                'Make it quick, convenient and contactless for members to close out their order with a card on file.',
                'Leverage Toast’s payment processing to ensure all payments go directly to your bank account.',
              ],
            },
          ],
        },
        {
          platformItemLogo: ncrAloha,
          platformItemAbout: 'NCR Aloha Essentials brings together best-in-class software, hardware, and services you need to run your operations',
          preview: ncrAlohaPreview,
          description: [
            {
              title: 'Know What Your Members Love',
              description:
                'Still or sparkling? Red or white? POS purchase data and dietary preferences automatically sync to your member’s profile so you can personalize your hospitality.',
            },
            {
              title: 'Phone to Table Dining',
              description:
                'Spare your staff and go self-service. Members can place online dine-in, carryout, and/or delivery orders, paying directly through the portal or mobile app.',
            },
          ],
          features: [
            {
              title: 'Make Paying Convenient',
              list: [
                'Make it quick, convenient and contactless for members to close out their order with a card on file.',
                'Automatically apply member discounts and house credits, or provide a house account for members to settle at the end of the month.',
                'Close out orders via your POS terminal or allow your members to do it themselves via the mobile app.',
              ],
            },
          ],
        },
      ],
    },
    {
      platformsTitle: 'Property Management Systems (PMS)',
      platformsList: [
        {
          platformItemLogo: hapi,
          platformItemAbout:
            'The Hapi data streaming platform is based on the same proven technology that runs some of the largest social media and business services.',
          link: '',
        },
        {
          platformItemLogo: mews,
          platformItemAbout: 'Mews is designed to simplify and automate all operations for modern hoteliers and their guests.',
          link: '',
        },
        {
          platformItemLogo: operacloud,
          platformItemAbout:
            'Oracle Hospitality OPERA Property Management Cloud CreateEnterpass are a cloud-based, portable empowered stage for cutting edge inn property the executives.',
          link: '',
        },
      ],
    },
    {
      platformsTitle: 'Reservations Systems',
      platformsList: [
        {
          platformItemLogo: sevenRooms,
          preview: sevenRoomsPreview,
          platformItemAbout: "SevenRooms helps the world's largest hospitality brands deliver memorable, top-tier guest experiences",
          description: [
            {
              title: 'Reservations Without Conditions',
              description:
                'Members can make dining reservations in the member portal and mobile app, which will automatically sync to both Sevenrooms and the Peoplevine platform and Peoplevine’s PoX Check-in App.',
            },
            {
              title: 'Keep in Touch',
              description: 'Send automated reminders ahead of the reservation. Follow up with emails or SMS messaging to capture feedback.',
            },
          ],
        },
      ],
    },
    {
      platformsTitle: 'Payment Processors',
      gridList: [
        {
          title: 'authorize.net',
          image: authorizeNet,
        },
        {
          title: 'braintree',
          image: braintree,
        },
        {
          title: 'stripe',
          image: stripe,
        },
        {
          title: 'cardconnect',
          image: cardconnect,
        },
        {
          title: 'gocardless',
          image: gocardless,
        },
        {
          title: 'cybersource',
          image: cybersource,
        },
        {
          title: 'square',
          image: square,
        },
      ],
    },
  ],
  themeColor: 'grey',
  additionalClass: 'integrations',
}

const ProductPage: FC = () => {

  return (
    <DefaultLayout isHeaderFixed={true}>
      <HomeVideoSection 
        slugContent="hero-video-product-page"
        className="hero-video-product-page"
        showPlayButton={false}
      />
      {/* <VideoSliderSection 
        typeContent='slider-video-product-page'
      /> */}
      {/* <HeroSliderSections 
         typeContent='slider-product-page'
       /> */}
      <ProductGuideSection />
      <KeyFeature.MemberPortalSection />
      <div className='features-wrapper'>
        <Feature.MemberPortalSection />
      </div>
      <KeyFeature.OpsTeamsSection />
      <div className='features-wrapper'>
        <Feature.OpsTeamsSection />
      </div>
      {/* <ProductItemSection
        key={productIntegrations.title}
        title={productIntegrations.title}
        about={productIntegrations.about}
        additionalClass={productIntegrations.additionalClass}
      /> */}
      <DoYouIntegrateSection />
    </DefaultLayout>
  )
}

export default ProductPage
