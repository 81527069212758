import 'src/pages/FormPage/FormPage.scss'

import { FC, useEffect } from 'react'
import { ReactComponent as CloseIcon } from 'src/components/App/images/close-icon.svg'
import { MainForm } from 'src/components/MainForm/MainForm'

const FormPage: FC = () => {
  useEffect(() => {
    document?.querySelector('html')?.classList.remove('html-scroll')
    window.scroll(0,0)
  }, [])

  return (
    <main className='main'>
      <div className='form-page'>
        <button type='button' onClick={() => history.back()} className='form-page-close-button'>
          <CloseIcon />
        </button>
        <MainForm />
      </div>
    </main>
  )
}
export default FormPage
