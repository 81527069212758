import React from 'react'
import { usePlatforms } from 'src/components/App/Platforms'
import { PlatformFragment } from 'src/graphql'

const PlatformsList = () => {
    const posPlatforms = usePlatforms('pos')
    const pmsPlatforms = usePlatforms('pms')
    const reservationPlatforms = usePlatforms('reservation')
    const platformsContent: PlatformFragment[] = [
        ...posPlatforms ? posPlatforms : [],
        ...pmsPlatforms ? pmsPlatforms : [],
        ...reservationPlatforms ? reservationPlatforms : []
    ].filter(Boolean) as PlatformFragment[]

    return (
        <ul className='platforms-list'>
            {platformsContent?.map((item, index) => (
                <li key={index} className='platforms-list-item'>
                    <div className='platforms-list-item__picture'>
                        <img src={item?.logo?.url} alt='platform-logo' className='platforms-list-item__image' />
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default PlatformsList