import { SVGProps } from "react"
const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 21 20"
    height="100%" 
    width="100%"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.355 0C7.64 0 7.3.012 6.232.06c-1.064.049-1.79.218-2.427.465a4.901 4.901 0 0 0-1.772 1.153A4.902 4.902 0 0 0 .88 3.45C.633 4.086.464 4.813.415 5.877.366 6.944.355 7.284.355 10s.011 3.056.06 4.123c.05 1.064.218 1.791.465 2.427.251.668.645 1.272 1.153 1.772.5.509 1.105.902 1.772 1.153.636.247 1.363.416 2.427.465 1.067.048 1.407.06 4.123.06s3.056-.012 4.123-.06c1.065-.049 1.792-.218 2.428-.465a4.903 4.903 0 0 0 1.771-1.153c.51-.5.903-1.104 1.154-1.772.247-.636.416-1.363.465-2.427.047-1.067.06-1.407.06-4.123s-.013-3.056-.06-4.123c-.05-1.064-.218-1.791-.465-2.427a4.903 4.903 0 0 0-1.154-1.772A4.901 4.901 0 0 0 16.906.525C16.27.278 15.543.109 14.479.06 13.412.012 13.072 0 10.355 0Zm0 1.802c2.67 0 2.986.01 4.04.058.976.045 1.505.207 1.858.344.466.182.8.399 1.15.748.35.35.567.684.748 1.15.137.353.3.882.345 1.857.048 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.04-.045.976-.208 1.505-.345 1.858-.16.434-.415.827-.747 1.15-.35.35-.684.566-1.15.748-.354.136-.883.3-1.858.344-1.054.048-1.37.058-4.04.058s-2.988-.01-4.04-.058c-.977-.045-1.506-.208-1.859-.344a3.099 3.099 0 0 1-1.15-.748 3.098 3.098 0 0 1-.748-1.15c-.137-.353-.3-.882-.344-1.857-.048-1.055-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.04.045-.976.207-1.505.344-1.858.182-.466.4-.8.748-1.15.35-.35.684-.566 1.15-.748.353-.137.882-.3 1.857-.344 1.055-.048 1.37-.058 4.041-.058Zm0 11.531a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667Zm0-8.468a5.135 5.135 0 1 0 0 10.27 5.135 5.135 0 0 0 0-10.27Zm6.538-.203a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default InstagramIcon
