import { SVGProps } from "react"
const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 11 20"
    height="100%" 
    width="100%"
    {...props}
  >
    <path d="M3.151 19.6h3.516v-8.76h2.94l.444-3.408H6.667V5.248c0-.984.276-1.656 1.692-1.656h1.812V.532A25.885 25.885 0 0 0 7.543.4C4.927.4 3.151 1.996 3.151 4.912v2.52H.2v3.408h2.952v8.76Z" />
  </svg>
)
export default FacebookIcon
