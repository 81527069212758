import './SubscriptionSection.scss'
import 'src/components/MainForm/MainForm.scss'

import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import { FC, useState } from 'react'
import pattern1 from 'src/assets/images/patterns-combined/pattern-combined (1).png'
import pattern2 from 'src/assets/images/patterns-combined/pattern-combined (2).png'
import pattern3 from 'src/assets/images/patterns-combined/pattern-combined (3).png'
import pattern4 from 'src/assets/images/patterns-combined/pattern-combined (4).png'
import pattern6 from 'src/assets/images/patterns-combined/pattern-combined (6).png'
import pattern7 from 'src/assets/images/patterns-combined/pattern-combined (7).png'
import Drawer from 'src/components/Drawer/Drawer'
import { useSurveyApi } from 'src/components/Email'
import * as Yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/button-arrow.svg'
import { useContent } from '../../../components/App/Content'
import countries from '../../../data/countries.json'
import { businesses, roles, SelectField } from '../../capterra/ContactForm'
import Card from './components/Card'
import { ReactComponent as Download } from 'src/assets/icons/download.svg'

type DownloadArticleModalProps = {
  onFormSubmit: (values: FormValues) => void
}

export type FormValues = FormikValues & {
  membersClub: string
  role: string
  firstName: string
  lastName: string
  email: string
  company: string
  website: string
  country: {
    value: string
  }
}

export const DownloadArticleModal: FC<DownloadArticleModalProps> = ({ onFormSubmit }) => {
  const errorMessage = 'This field is required'

  const initialValues = {
    membersClub: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    website: '',
    country: {
      value: '',
      label: '',
    },
  }
  const validationSchema = Yup.object({
    membersClub: Yup.string().required(errorMessage),
    role: Yup.string().required(errorMessage),
    firstName: Yup.string().required(errorMessage),
    lastName: Yup.string().required(errorMessage),
    company: Yup.string().required(errorMessage),
    website: Yup.string().required(errorMessage),
    email: Yup.string().email('Invalid email format').required(errorMessage),
  })

  return (
    <Formik initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={validationSchema}>
      {form => (
        <Form className='login-form' id="download-article-form">
          <div>
            <div className='login-form__box'>
              <div className='login-form__input' style={{ marginBottom: 0 }}>
                <Field
                  name={'membersClub'}
                  placeholder='Members club Type?'
                  component={SelectField}
                  required={true}
                  options={businesses.map(item => ({ label: item.title, value: item.title }))}
                />
              </div>
              <div className='login-form__input' style={{ marginBottom: 0 }}>
                <Field name={'role'} placeholder='Your role or title' component={SelectField} required={true} options={roles} />
              </div>
            </div>
            <div className='login-form__box'>
              <div className='login-form__input'>
                <div className='login-form__input-inner-box'>
                  <label className='login-form__label' htmlFor='firstName'>
                    First name
                    <span className='login-form__label-required'> *</span>
                  </label>
                  <Field
                    type='text'
                    className={`login-form__inputfield 
                        ${form?.errors.firstName && form?.touched.firstName && 'login-form__inputfield--error'}`}
                    name='firstName'
                    placeholder='First name'
                  />
                </div>
                <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
              </div>
              <div className='login-form__input'>
                <div className='login-form__input-inner-box'>
                  <label className='login-form__label' htmlFor='lastName'>
                    Last name
                    <span className='login-form__label-required'> *</span>
                  </label>
                  <Field
                    type='text'
                    className={`login-form__inputfield 
                        ${form?.errors.lastName && form?.touched.lastName && 'login-form__inputfield--error'}`}
                    name='lastName'
                    placeholder='Last name'
                  />
                </div>
                <ErrorMessage name='lastName' component='span' className='login-form__error-message' />
              </div>
            </div>
            <div className='login-form__box'>
              <div className='login-form__input'>
                <div className='login-form__input-inner-box'>
                  <label className='login-form__label' htmlFor='email'>
                    Your Business Email
                    <span className='login-form__label-required'> *</span>
                  </label>
                  <Field
                    type='text'
                    className={`login-form__inputfield 
                        ${form?.errors.email && form?.touched.email && 'login-form__inputfield--error'}`}
                    name='email'
                    placeholder='Enter your business email'
                  />
                </div>
                <ErrorMessage name='email' component='span' className='login-form__error-message' />
              </div>
              <div className='login-form__input'>
                <div className='login-form__input-inner-box'>
                  <label className='login-form__label' htmlFor='company'>
                    Company Name
                    <span className='login-form__label-required'> *</span>
                  </label>
                  <Field
                    type='text'
                    className={`login-form__inputfield 
                        ${form?.errors.company && form?.touched.company && 'login-form__inputfield--error'}`}
                    name='company'
                    placeholder='Enter company name'
                  />
                </div>
                <ErrorMessage name='company' component='span' className='login-form__error-message' />
              </div>
            </div>
            <div className='login-form__box'>
              <div className='login-form__input'>
                <div className='login-form__input-inner-box'>
                  <label className='login-form__label' htmlFor='website'>
                    Company Website
                    <span className='login-form__label-required'> *</span>
                  </label>
                  <Field
                    type='text'
                    className={`login-form__inputfield 
                        ${form?.errors.website && form?.touched.website && 'login-form__inputfield--error'}`}
                    name='website'
                    placeholder='Enter company website'
                  />
                </div>
                <ErrorMessage name='website' component='span' className='login-form__error-message' />
              </div>
              <div className='login-form__input'>
                <Field
                  name={'country'}
                  placeholder='Country'
                  component={SelectField}
                  required={true}
                  options={countries.map(it => ({ label: it.name, value: it.name }))}
                />
              </div>
            </div>
            <div className='login-form__button subs-submit'>
              <button type='submit'>
                get the article
                <ArrowIcon />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

type DrawerProps = {
  visible: boolean
  onClose: () => void
}

export const ResultDrawer: FC<{ status: 'success' | 'error' } & Partial<DrawerProps>> = ({ status, visible = false, onClose = undefined }) => (
  <Drawer
    title={status === 'success' ? 'Success!' : 'Oooooops Something went wrong.'}
    subtitle={
      status === 'success'
        ? 'The complete “Membership is the Future of Hospitality” article should be in your inbox soon.'
        : 'Please check the information you submitted and try again.'
    }
    visible={visible}
    onClose={() => onClose && onClose()}
  />
)

export const DownloadDrawer: FC<Partial<DrawerProps>> = ({ onClose, visible = false, children }) => (
  <Drawer
    title={'Download article'}
    subtitle={
      'By downloading this article, you are also opting in to our newsletter, that features articles about members clubs and memberships in the lifestyle hospitality space.'
    }
    visible={visible}
    onClose={() => onClose && onClose()}
  >
    {children}
  </Drawer>
)

type OrnamentItem = { src: string }

const OrnamentItem: FC<OrnamentItem> = ({ src }) => <img src={src} alt='ornament' className='ornament-block__image' />

export const SubscriptionSection: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [idFormModal, setIdFormModal] = useState<number>(0)

  const { sendEmail, error, requestSent } = useSurveyApi()
  const ornaments = [pattern1, pattern2, pattern3, pattern4, pattern6, pattern7]
  // const sectionContent = useContent('home-blog')
  const sectionContent = useContent('home-subscription-section')
  const section = sectionContent?.section

  return (
    <section className='subscription-section' id='subscription'>
      <div className='subscription-section__wrapper'>
        {/* <div className='ornament-block custom-ornament-block'>
          {ornaments.map((value, index) => (
            <OrnamentItem key={index} src={value} />
          ))}
        </div> */}
        {/* <div className='container'>

          {cards?.map(card => (
            <Card
              key={card?.id}
              urlImage={card?.media?.url as string}
              title={card?.title as string}
              description={card?.description as string}
              urlButton={card?.urlButton as string}
              textButton={card?.buttonText as string}
              setIsOpen={setIsOpen}
              activeFirstButton={card?.activeFirstButton || false}
              setIdFormModal={() => setIdFormModal(parseInt(card?.slug as string))}
            />
          ))}

        </div> */}
        <div className="subscription-section__container">
          <div className="subscription-section__card card-rsvp">
              <div className='card-rsvp_image'>
                <img src={section?.media?.url} alt="RSVP Logo" />
              </div>
              <div className='card-rsvp_button'>
                <a className="button" href={section?.urlButton ?? ''}>{section?.buttonText}
                  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 5H14" stroke="#FEFEFE" strokeWidth="1.5" strokeLinecap="square"></path><path d="M11 1L15 5L11 9" stroke="#FEFEFE" strokeWidth="1.5"></path></svg>
                </a>
              </div>
          </div>
          <div className="subscription-section__card card-download">
            {section?.videoDesktop?.url && (
              <video autoPlay muted playsInline loop>
                  <source src={section?.videoDesktop?.url} type="video/mp4" />
              </video>
            )}
            <div className='subscription-section__card-content'>
              <h2 className="card-download_conent--title">{section?.title}</h2>
              {section?.activeFirstButton && (
                <div
                    className="button-secondary"
                    onClick={() => {
                        setIdFormModal(parseInt(section?.slug as string))
                        setIsOpen(() => true)
                    }}
                >
                    <Download />
                    {`Download Now`}
                </div>
              )}

            </div>
          </div>
        </div>

      </div>
      {!requestSent ? (
        <DownloadDrawer visible={isOpen} onClose={() => setIsOpen(false)}>
          <DownloadArticleModal
            onFormSubmit={values =>
              sendEmail(idFormModal, {
                18980: values.membersClub,
                18981: values.role,
                18982: values.firstName,
                18983: values.lastName,
                18984: values.email,
                18985: values.company,
                18986: values.website,
                18987: values.country.value,
                18988: 'Tag',
              })
            }
          />
        </DownloadDrawer>
      ) : (
        <ResultDrawer visible={isOpen} onClose={() => setIsOpen(false)} status={error === undefined ? 'success' : 'error'} />
      )}
    </section>
  )
}
