import { MainForm } from "src/components/MainForm/MainForm"
import { EarlyForm } from "src/components/EarlyForm/EarlyForm"
import DefaultLayout from "src/layouts"
import ExclusiveBenefitsSection from "src/sections/EarlyOnTheVineSections/ExclusiveBenefitsSection/ExclusiveBenefitsSection"
import RequirementsSection from "src/sections/EarlyOnTheVineSections/RequirementsSection/RequirementsSection"
import VideoBannerSection from "src/sections/EarlyOnTheVineSections/VideoBannerSection/VideoBannerSection"
import { useEffect } from 'react';


const EarlyOnTheVinePage = () => {
  
  useEffect(() => {
    const infoTextElement = document.querySelector('.video-content__info-text');
    if (infoTextElement) {
      const text = infoTextElement.innerHTML;
      const newText = text.replace('hospitality industry.', 'hospitality industry.<br /><br />');
      infoTextElement.innerHTML = newText;
    }
  }, []);

  return (
    <DefaultLayout isHeaderFixed={false} isFooterAnimated={false} hasVideoBanner={false}>
        <VideoBannerSection slug="video-banner-early-page" />
        <ExclusiveBenefitsSection slug="your-exclusive-benefits-section" />
        <RequirementsSection slug="requirements-beta-program-section" />
        <section className="get-free-demo-form-section">
          <EarlyForm />
        </section>
    </DefaultLayout>
  )
}

export default EarlyOnTheVinePage