import 'src/sections/homePageSections/PlatformsSection/PlatformsSection.scss'

import { FC, useEffect, useState } from 'react'
import mews from 'src/assets/icons/pms/mews.svg'
import operacloud from 'src/assets/icons/pms/operacloud.svg'
import sevenRooms from 'src/assets/images/partners/7-rooms.svg'
import sevenRoomsPreview from 'src/assets/images/partners/7-rooms-preview.png'
import ncrAloha from 'src/assets/images/partners/ncr-aloha.svg'
import ncrAlohaPreview from 'src/assets/images/partners/ncr-aloha-preview.png'
import onmivore from 'src/assets/images/partners/omnivore.svg'
import square from 'src/assets/images/partners/square.svg'
import squarePreview from 'src/assets/images/partners/square-preview.png'

import { useContent } from '../../../components/App/Content'
import { usePlatforms } from '../../../components/App/Platforms'
import Modal from '../../../components/Drawer/Modal'
import { Maybe, PlatformFragment } from '../../../graphql'
import { IntegrationDrawer, IntegrationDrawerProps } from '../../productPageSections/ProductItemSection/ProductItemSection'
import platformPattern1 from './images/platform-pattern-2.png'
import platformPattern2 from './images/platform-pattern-3.png'
import Button from 'src/components/Form/Button'

export type PlatformsSectionProps = {
  readMore?: boolean
  title?: string
  theme?: 'light' | 'grey' | 'dark'
}

export const PlatformsSection: FC<PlatformsSectionProps> = ({ theme = 'grey', readMore = true, title = 'Integrated Platforms' }) => {
  const [drawer, setDrawer] = useState<PlatformFragment | null | undefined>(null)
  // const platformsContent = usePlatforms()
  const posPlatforms = usePlatforms('pos')
  const pmsPlatforms = usePlatforms('pms')
  const reservationPlatforms = usePlatforms('reservation')
  const platformsContent: PlatformFragment[] = [
    ...posPlatforms ? posPlatforms : [],
    ...pmsPlatforms ? pmsPlatforms : [],
    ...reservationPlatforms ? reservationPlatforms : []
  ].filter(Boolean) as PlatformFragment[]
  // const content = useContent('home-platforms')

  // const platforms = [
  //   {
  //     platformItemLogo: square,
  //     platformItemAbout: 'Tools to run your business—even when it’s not business as usual',
  //     preview: squarePreview,
  //     description: [
  //       {
  //         title: 'Know What Your Members Love',
  //         description: 'Sync members purchases, spend, and dietary preferences to their CRM profile and personalize your hospitality.',
  //       },
  //     ],
  //     features: [
  //       {
  //         title: 'Make Paying Convenient',
  //         list: [
  //           'Make it quick, convenient and contactless for members to close out their order with a card on file.',
  //           'Leverage Toast’s payment processing to ensure all payments go directly to your bank account.',
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     platformItemLogo: ncrAloha,
  //     platformItemAbout:
  //       'NCR Aloha Essentials brings together best-in-class software, hardware, and services you need to run your operations',
  //     preview: ncrAlohaPreview,
  //     description: [
  //       {
  //         title: 'Know What Your Members Love',
  //         description:
  //           'Still or sparkling? Red or white? POS purchase data and dietary preferences automatically sync to your member’s profile so you can personalize your hospitality.',
  //       },
  //       {
  //         title: 'Phone to Table Dining',
  //         description:
  //           'Spare your staff and go self-service. Members can place online dine-in, carryout, and/or delivery orders, paying directly through the portal or mobile app.',
  //       },
  //     ],
  //     features: [
  //       {
  //         title: 'Make Paying Convenient',
  //         list: [
  //           'Make it quick, convenient and contactless for members to close out their order with a card on file.',
  //           'Automatically apply member discounts and house credits, or provide a house account for members to settle at the end of the month.',
  //           'Close out orders via your POS terminal or allow your members to do it themselves via the mobile app.',
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     platformItemLogo: sevenRooms,
  //     preview: sevenRoomsPreview,
  //     platformItemAbout: "SevenRooms helps the world's largest hospitality brands deliver memorable, top-tier guest experiences",
  //     description: [
  //       {
  //         title: 'Reservations Without Conditions',
  //         description:
  //           'Members can make dining reservations in the member portal and mobile app, which will automatically sync to both Sevenrooms and the PeopleVine platform and PeopleVine’s PoX Check-in App.',
  //       },
  //       {
  //         title: 'Keep in Touch',
  //         description: 'Send automated reminders ahead of the reservation. Follow up with emails or SMS messaging to capture feedback.',
  //       },
  //     ],
  //   },
  //   {
  //     platformItemLogo: onmivore,
  //     platformItemAbout:
  //       'Omnivore is the industry standard to discover, vet and unify technologies for guest engagement and technical agility',
  //   },
  //   {
  //     platformItemLogo: mews,
  //     platformItemAbout: 'Mews is designed to simplify and automate all operations for modern hoteliers and their guests',
  //   },
  //   {
  //     platformItemLogo: operacloud,
  //     platformItemAbout:
  //       'Oracle Hospitality OPERA Property Management Cloud CreateEnterpass are a cloud-based, portable empowered stage for cutting edge inn property the executives',
  //   },
  // ]

  return (
    <section className={`platforms platforms-${theme}`}>
      <div className='ornament-block'>
        <img src={platformPattern1} alt='ornament' className='ornament-block__image' />
        <img src={platformPattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='platforms__wrapper'>
          <h2 className='platforms__title section-main-title'>{title}</h2>
          <ul className='platforms-list'>
            {platformsContent?.map((item, index) => (
              <li key={index} className='platforms-list-item'>
                <div className='platforms-list-item__picture'>
                  <img src={item?.logo?.url} alt='platform-logo' className='platforms-list-item__image' />
                </div>
                <p className='platforms-list-item__text'>{item?.title}</p>
                {item?.description_card?.length !== 0 && readMore && (
                  <a className='platforms-list-item__button' onClick={() => setDrawer(item)}>
                    Read more
                  </a>


                )}

              </li>
            ))}
          </ul>
          <div className="login-form__button platform-button-v2">
            <Button to="/integrations" target="_blank">Learn More</Button>
          </div>
        </div>
      </div>
      <Modal isOpen={!!drawer} setIsOpen={setDrawer}>
        {drawer && <IntegrationDrawer {...drawer} />}
      </Modal>
    </section>
  )
}
