import { FC, useEffect, useRef, useState } from 'react'
import './VideoSection.scss'

interface VideoSectionProps {
    urlVideo?: string;
    width?: number;
    height?: number;
    mobileUrlVideo?: string; // Added prop for mobile video URL
}

const VideoSection: FC<VideoSectionProps> = ({
    urlVideo,
    width = 750,
    height = 500,
    mobileUrlVideo // Added mobile video URL prop
}) => {
    
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        
        if (mobileUrlVideo && videoRef.current) {
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.catch((error) => {
                    console.error('Video playback failed:', error);
                    // Fallback for Safari and other browsers
                    videoRef.current!.muted = true;
                    videoRef.current!.play();
                });
            }
        }
    }, [mobileUrlVideo]);

    return (
        <section className="video-section">
            {mobileUrlVideo && (
                <video
                    width={width}
                    height={height}
                    autoPlay
                    muted
                    playsInline
                    loop
                    className='hide-desktop'
                >
                    <source src={mobileUrlVideo} type="video/mp4" />
                </video>
            )}
            {urlVideo && (
                <video
                    ref={videoRef}
                    width={width}
                    height={height}
                    autoPlay
                    muted
                    playsInline
                    loop
                    className='hide-mobile'
                >
                    <source src={urlVideo} type="video/mp4" />
                </video>
            )}
        </section>
    );
}

export default VideoSection
