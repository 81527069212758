import './FeatureSection.scss'

import { FC } from 'react'
import { useContent } from 'src/components/App/Content'
import integrationDataAdapter from './helpers/integrationDataAdapter'

export interface IFeature {
  title?: string | undefined | null
  image?: string | undefined
  about?: string | undefined
  additionalClass: string | null | undefined
  pattern1?: string
  pattern2?: string
  pattern3?: string
  list: {
    listItemTitle: string | null | undefined
    listItemAbout: string | null | undefined
  }[]
  backgroundColor?: string | null | undefined
  titleColor?: string
  textColor?: string
  index: number
  customComponent?: any
}

const OpsIntegrationSection: FC = () => {

  const integrationSections = useContent('integration-sections');

  const integrationSectionsData = integrationDataAdapter(integrationSections) ?? []

  return (
    <>
    {integrationSectionsData.map((feature, index) => (
      <FeatureSection key={feature.title} index={index} {...feature as any} />
    ))}
  </>
  )
}
const FeatureSection: FC<IFeature> = ({
  title = '',
  image,
  additionalClass,
  pattern1,
  pattern2,
  pattern3,
  about,
  list,
  backgroundColor,
  titleColor,
  textColor,
  index,
  customComponent
}) => {
  const listElementColor = window.innerWidth < 576 ? textColor : titleColor
  return (
    <section className={`feature feature--${index + 1} feature--${additionalClass}`} style={{ backgroundColor: `${backgroundColor}` }}>
      <div className='ornament-block'>
        {pattern1 && <img src={pattern1} alt='pattern' className='ornament-block__image' />}
        {pattern2 && <img src={pattern2} alt='pattern' className='ornament-block__image' />}
        {pattern3 && <img src={pattern3} alt='pattern' className='ornament-block__image' />}
      </div>
      <div className='container'>
        <div className='feature__wrapper'>
          <div className={'feature-block feature-block--sticky'}>
            <h2 className='feature-block__title section-main-title' style={{ color: titleColor }}>
              {title}
            </h2>
            <p className='feature-block__text' style={{ color: textColor }}>
              {about}
            </p>
          </div>
          <div className='feature-block'>
            {
              image && (
                <div className={`feature-block-picture feature-block-picture--${index + 1}`}>
                  <img src={`${image}`} alt='dashboard' className='feature-block-picture__image feature-block-picture__image--primary' />
                </div>
              )
            }
            {
              customComponent && (
                <div className='feature-block-component'>
                  {customComponent}
                </div>
              )
            }
            <ul className='feature-block-list'>
              {list.map((item, i) => (
                <li key={i} className='feature-block-list-item'>
                  <h6 className='feature-block-list-item__title' style={{ color: listElementColor }}>
                    {item.listItemTitle}
                  </h6>
                  <p className='feature-block-list-item__text' style={{ color: textColor }}>
                    {item.listItemAbout}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
export default { OpsIntegrationSection }
