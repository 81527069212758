import { useState } from 'react';
import './VideoAdventrSection.scss'
import { useContent } from 'src/components/App/Content';
import { ReactComponent as PlayIcon } from 'src/assets/icons/play-solid.svg'
import VideoContainer from './components/VideoContainer';

const VideoAdventrSection = () => {
  const [isMobileVideoLoaded, setIsMobileVideoLoaded] = useState(false);
  const [isDesktopVideoLoaded, setIsDesktopVideoLoaded] = useState(false);

  const heroVideoSection = useContent('video-adventr-section');

  return (
    <section className="video-adventr-section" id='hero-video-section'>
      {
        heroVideoSection?.section?.videoDesktop?.url && (
          <video className='m-none' width="750" height="500" autoPlay muted playsInline loop onLoadedData={() => setIsDesktopVideoLoaded(true)}>
            <source src={heroVideoSection?.section?.videoDesktop?.url} type="video/mp4" />
          </video>
        )
      }

      {
        heroVideoSection?.section?.videoMobile?.url && (
          <video className='dx-none' width="750" height="500" autoPlay muted playsInline loop onLoadedData={() => setIsMobileVideoLoaded(true)}>
            <source src={heroVideoSection?.section?.videoMobile?.url} type="video/mp4" />
          </video>
        )
      }
      <VideoContainer />
    </section>
  )
}

export default VideoAdventrSection