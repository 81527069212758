import { SVGProps } from "react"
const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 512 359"
    height="100%" 
    width="100%"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M501.3 56c-5.9-22.1-23.2-39.4-45.3-45.3C416.1 0 256 0 256 0S95.9 0 55.9 10.7C33.8 16.6 16.6 33.9 10.6 56 0 96 0 179.4 0 179.4s0 83.4 10.7 123.4c5.9 22.1 23.3 39.4 45.3 45.3 39.9 10.7 200 10.7 200 10.7s160.1 0 200.1-10.7c22.1-5.9 39.4-23.3 45.3-45.3 10.6-40 10.6-123.4 10.6-123.4S512 96 501.3 56ZM205.1 256V102.8l132.6 76L205.1 256Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h512v358.8H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default YoutubeIcon
