import 'src/sections/clientsPageSections/ClientTypeSection/ClientTypeSection.scss'
import 'swiper/bundle'

import { FC } from 'react'
import Button from 'src/components/Form/Button'
import { Autoplay, Navigation, Pagination, Swiper as SwiperCore } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ContentFragment, Maybe } from '../../../graphql'
import { ReactComponent as NavArrow } from '../../homePageSections/FeedbackSection/images/swiper-nav-arrow.svg'

SwiperCore.use([Pagination, Autoplay, Navigation])

export interface IClientType {
  title?: string
  clientName: string
  clientTypeName: string
  clientTypeAbout: string
  clientTypeMobileImage: string
  slides: {
    slideImage: string
    logo?: string
    slideTitle: string
    slideAbout: string[]
  }[]
  themeColor: string
  customPaginationClass: string
  backgroundColor?: string
  titleColor?: string
  textColor?: string
  index: number
  download?: boolean
}

export const ClientTypeSection: FC<{ item?: Maybe<ContentFragment>; download?: boolean }> = ({ item, download = true }) => {
  let backgroundColor: string
  let titleColor: string
  let textColor: string

  switch (item?.cards?.[0]?.backgroundColor) {
    case 'black':
      backgroundColor = '#202123'
      titleColor = '#fefefe'
      textColor = '#c1c3c9'
      break

    case 'grey':
      backgroundColor = '#f8f8f8'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break

    case 'white':
      backgroundColor = '#fff'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break

    default:
      backgroundColor = '#fff'
      titleColor = '#2f3034'
      textColor = '#81848e'
      break
  }

  return (
    <section
      className={`client-type ${item?.title && 'has-title'}`}
      style={{ backgroundColor: backgroundColor }}
      data-color={item?.cards?.[0]?.backgroundColor}
      id={item?.title}
    >
      <div className='container'>
        {/*{item?.title && <p className={'client-title'} dangerouslySetInnerHTML={{ __html: item?.title }} />}*/}
        <div className='client-type__wrapper'>
          <div className='client-type-block'>
            <h2 className='client-type-block__title section-main-title' style={{ color: titleColor }}>
              {item?.description}
            </h2>
          </div>
          <div className='client-type-block'>
            <div className='client-type-block__picture'>
              <img src={item?.cards?.[0]?.media_slides?.[0]?.url} alt='image' className='client-type-block__image' />
            </div>
            <div className='client-type-swiper'>
              <Swiper
                spaceBetween={168}
                slidesPerView={1}
                navigation={{
                  nextEl: `.swiper-nav.${item?.section?.subTitle} .swiper-nav-button--next`,
                  prevEl: `.swiper-nav.${item?.section?.subTitle} .swiper-nav-button--prev`,
                }}
                speed={300}
                pagination={{
                  el: `.swiper-pagination.${item?.section?.subTitle}`,
                  clickable: true,
                }}
              >
                {item?.cards?.map((card, index) => (
                  <SwiperSlide key={card?.title}>
                    <div className='client-type-swiper__item'>
                      <div className='client-type-swiper__item-wrapper'>
                        <div className='client-type-swiper__picture'>
                          <img src={card?.media_slides?.[0]?.url} alt='image' className='client-type-swiper__image' />
                        </div>
                        <div
                          className={`client-type-swiper__content ${
                            index === 0
                              ? 'client-type-swiper__content--first-slide'
                              : index + 1 === item?.cards?.length
                              ? 'client-type-swiper__content--last-slide'
                              : ''
                          }`}
                        >
                          {index === 0 ? (
                            <div className='client-type-swiper__logo'>
                              <img src={card?.media?.url} alt='logo' className='client-type-swiper__logo-image' />
                            </div>
                          ) : (
                            ''
                          )}
                          <div className='client-type-swiper__text-box'>
                            <h5 className='client-type-swiper__title section-secondary-title' style={{ color: titleColor }}>
                              {card?.title}
                            </h5>
                            <p key={index} className='client-type-swiper__text' style={{ color: textColor }}>
                              {card?.subTitle}
                            </p>
                          </div>
                          {index === 0 && download ? (
                            <div className={'client-type-swiper__button'}>
                              <Button width={255} to={`/presentations/ppv-${item?.title}.pdf`} target={'_blank'}>
                                Download use case
                              </Button>
                            </div>
                          ) : index + 1 === item?.cards?.length && download ? (
                            <div className='client-type-swiper__experience-box'>
                              <h4 className='client-type-swiper__experience-title section-tertiary-title' style={{ color: titleColor }}>
                                Want to elevate your member experience?
                              </h4>
                              <Button to={'/form'}>Request a demo</Button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className={`swiper-nav ${item?.section?.subTitle}`}>
                <span className='swiper-nav-button swiper-nav-button--prev'>
                  <NavArrow className='swiper-nav-button__icon' />
                </span>
                <span className='swiper-nav-button swiper-nav-button--next'>
                  <NavArrow className='swiper-nav-button__icon' />
                </span>
              </div>
              <div className={`swiper-pagination ${item?.section?.subTitle}`} />
            </div>
            {/* <div className="horizontal-slider">
              <div className={`horizontal-slider__wrapper horizontal-slider__wrapper--${props.crm}`}>
                {props.slides.map((item, crm) => (
                  <div
                    key={item.slideTitle}
                    className={`horizontal-slider-item horizontal-slider-item--${props.crm}`}
                  >
                    <div className="horizontal-slider-item__wrapper">
                      <div
                        className={`horizontal-slider-item__content ${
                          crm === 0 ? 'horizontal-slider-item__content--first-slide' : ''
                        }`}
                      >
                        {crm === 0 ? (
                          <div className="horizontal-slider-item__logo">
                            <img src={item.logo} alt="logo" className="horizontal-slider-item__logo-image" />
                          </div>
                        ) : ''}
                        <div className="horizontal-slider-item__text-box">
                          <h5
                            className="horizontal-slider-item__title section-secondary-title"
                            style={{ color: titleColor }}
                          >
                            {item.slideTitle}
                          </h5>
                          {item.slideAbout.map((paragraph, crm) => (
                            <p key={crm} className="horizontal-slider-item__text" style={{ color: textColor }}>
                              {paragraph}
                            </p>
                          ))}
                        </div>
                        {crm === 0 ? (
                          <button className="horizontal-slider-item__button">
                            <span className="horizontal-slider-item__button-text">Download use case</span>
                            <svg
                              width="17"
                              height="10"
                              viewBox="0 0 17 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1.46945 5H14.4695" stroke="#fefefe" strokeWidth="1.5" strokeLinecap="square" />
                              <path d="M11.4695 1L15.4695 5L11.4695 9" stroke="#fefefe" strokeWidth="1.5" />
                            </svg>
                          </button>
                        ) : ''}
                      </div>
                      <div className="horizontal-slider-item__picture">
                        <img src={item.slideImage} alt="image" className="horizontal-slider-item__image" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}
