import { FC } from 'react'
import './HeaderDescriptionSection.scss'

interface HeaderDescriptionSectionProps {
    title: string;
    description: string;
}
const HeaderDescriptionSection: FC<HeaderDescriptionSectionProps> = ({
    title,
    description
}) => (
    <section className="elevate-header-section">
        <div className='container'>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    </section>
)

export default HeaderDescriptionSection