import 'src/components/Menu/index.scss'

import { FC } from 'react'
import links from 'src/data/menu.json'

const Menu: FC<Partial<{ prefixCls: string }>> = ({ prefixCls }) => {
  const className = prefixCls ? prefixCls.concat('-', 'navigation-list') : 'navigation-list'

  return (
    <ul className={className}>
      {links.map(item => (
        <li key={item.title} className={`${className}__item`}>
          <a href={item.to} className={`${className}__link`}>
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Menu
