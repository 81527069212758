import './index.scss'

import { FC } from 'react'

import propsValues from './index.json'

type StatusT = {
  type: StatusType
  data: StatusProps
  width: number | string
  hasFooter: boolean
}
type StatusProps = {
  title: string
  message: string
  imgSrc: string
}

type StatusType = 'success' | 'error' | 'not-found' | 'redirect'

export const Status: FC<Partial<StatusT>> = ({ type = 'success', data, width = 340, hasFooter = false }) => {
  const props: Partial<StatusProps> = data || (propsValues as { [key: string]: Partial<StatusProps> })[type]
  const { imgSrc, message, title } = props
  return (
    <div className={`message-page ${!hasFooter && 'no-footer'}`}>
      <div className='message-page__wrapper' style={{ maxWidth: width }}>
        <div className='message-page__picture'>{imgSrc && <img src={imgSrc} alt={title} />}</div>
        <div className='massage-page__text'>
          {title && <h1>{title}</h1>}
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  )
}
