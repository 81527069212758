import './ProgressBar.scss'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { FC, useEffect, useRef } from 'react'

gsap.registerPlugin(ScrollTrigger)

interface IProgressBar {
  container: string
  progressBarInfo: {
    title: string
    themeColor: string
  }[]
}

export const ProgressBar: FC<IProgressBar> = ({ container, progressBarInfo }) => {
  const progressBar = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = document.querySelector(`.${container}`) as HTMLDivElement
    switch (element.children[0].getAttribute('data-color')) {
      case 'black':
        progressBar.current?.classList.add('product-guide-block-progress--black')
        break

      case 'white':
        progressBar.current?.classList.add('product-guide-block-progress--white')
        break

      case 'grey':
        progressBar.current?.classList.add('product-guide-block-progress--grey')
        break

      default:
        progressBar.current?.classList.add('product-guide-block-progress--black')
        break
    }

    const listItem = document.querySelectorAll('.product-guide-block-progress__list-item')

    const progressFunc = () => {
      ScrollTrigger.matchMedia({
        '(max-width: 576px)': () => {
          gsap.utils.toArray<Element>(element.children).forEach((section, index) => {
            ScrollTrigger.create({
              trigger: section,
              start: '-80px top',
              end: 'bottom 80px',
              onToggle: () => {
                listItem.forEach(item => {
                  item.classList.remove('product-guide-block-progress__list-item--active')
                })
                listItem[index].classList.add('product-guide-block-progress__list-item--active')
              },
            })
          })
        },
      })

      gsap.to('.product-guide-block-progress__bar-fill', {
        scaleX: 1,
        ease: 'none',

        scrollTrigger: {
          trigger: `.${container}`,
          scrub: 0.1,
          start: '80px top',
          end: '98% bottom',
        },
      })

      gsap.utils.toArray<Element>(element.children).forEach(section => {
        if (section.hasAttribute('data-color')) {
          ScrollTrigger.create({
            trigger: section,
            start: '-80px top',
            end: 'bottom 80px',
            onToggle: () => {
              if (section.getAttribute('data-color') === 'black') {
                progressBar.current?.classList.add('product-guide-block-progress--black')
                progressBar.current?.classList.remove('product-guide-block-progress--white')
                progressBar.current?.classList.remove('product-guide-block-progress--white')
              }
              if (section.getAttribute('data-color') === 'grey') {
                progressBar.current?.classList.add('product-guide-block-progress--grey')
                progressBar.current?.classList.remove('product-guide-block-progress--black')
                progressBar.current?.classList.remove('product-guide-block-progress--white')
              }
              if (section.getAttribute('data-color') === 'white') {
                progressBar.current?.classList.add('product-guide-block-progress--white')
                progressBar.current?.classList.remove('product-guide-block-progress--black')
                progressBar.current?.classList.remove('product-guide-block-progress--grey')
              }
            },
          })
        }
      })
    }

    window.addEventListener('load', () => progressFunc())

    return () => {
      window.removeEventListener('load', () => progressFunc())
    }
  })

  return (
    <div className='product-guide-block-progress' ref={progressBar}>
      <div className='product-guide-block-progress__wrapper'>
        <ul className='product-guide-block-progress__list'>
          {progressBarInfo.map((item, index) => (
            <li
              key={item.title}
              className={`product-guide-block-progress__list-item capture ${
                index === 0 ? 'product-guide-block-progress__list-item--active' : ''
              }`}
            >
              {item.title}
            </li>
          ))}
        </ul>
        <div className='product-guide-block-progress__bar'>
          <div className='product-guide-block-progress__bar-fill'></div>
        </div>
      </div>
    </div>
  )
}
