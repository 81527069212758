import { FC, useEffect } from 'react'
import clientlogo2 from 'src/assets/images/clients/baia.svg'
import clientlogo3 from 'src/assets/images/clients/nocatee.svg'
import clientlogo1 from 'src/assets/images/clients/zeroBond.svg'
import zeroBond1 from 'src/assets/images/common/picture (1).png'
import zeroBond2 from 'src/assets/images/common/picture (2).png'
import zeroBond3 from 'src/assets/images/common/picture (3).png'
import baia1 from 'src/assets/images/common/picture (4).png'
import baia2 from 'src/assets/images/common/picture (5).png'
import baia3 from 'src/assets/images/common/picture (6).png'
import nocatee1 from 'src/assets/images/common/picture (7).png'
import nocatee2 from 'src/assets/images/common/picture (8).png'
import nocatee3 from 'src/assets/images/common/picture (9).png'
import customerExperienceImage from 'src/assets/images/common/picture-contact-1.jpg'
import { ProgressBar } from 'src/components/ProgressBar/ProgressBar'
import { ClientTypeSection } from 'src/sections/clientsPageSections/ClientTypeSection/ClientTypeSection'
import { OurClientsSection } from 'src/sections/clientsPageSections/OurClientsSection/OurClientsSection'
import { CustomerExperienceSection } from 'src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection'

import DefaultLayout from '../../layouts'
import { useContent } from '../../components/App/Content'
import { ContentFragment } from '../../graphql'
import { ClientProps } from '../Capterra/crm/Capterra'
import TickerImagesClientBar from 'src/components/TickerImagesBar/TickerImagesClientBar'

export const clientsTypes = [
  {
    clientName: 'zeroBond',
    clientTypeName: 'Private members clubs',
    clientTypeAbout: '',
    clientTypeMobileImage: zeroBond1,
    slides: [
      {
        slideImage: zeroBond1,
        logo: clientlogo1,
        slideTitle: 'Intro',
        slideAbout: [
          'From 1Oak to Zero Bond, NYC nightlife and hospitality vanguard Scott Sartiano diversifies his portfolio and steps into the private members club space. Zero Bond is an exclusive social club for creative elites, set in a refurbished NoHo factory that, like its membership base, is the definition of cool.',
        ],
      },
      {
        slideImage: zeroBond2,
        slideTitle: 'Challenge',
        slideAbout: [
          'A new business venture required new technology. Zero Bond needed a robust CRM and membership management system that could service all aspects (membership, dining, programming) of its multi-amenity property.',
        ],
      },
      {
        slideImage: zeroBond3,
        slideTitle: 'Solution',
        slideAbout: [
          'We integrated with their Toast POS system to sync member transactions preferences to their CRM and powered their member app with billing, events & ticketing, and dining reservations.',
        ],
      },
    ],
    themeColor: 'white',
    customPaginationClass: 'clients-pagination-1',
  },
  {
    clientName: 'baia',
    clientTypeName: 'Boutique Hotels',
    clientTypeAbout: '',
    clientTypeMobileImage: baia1,
    slides: [
      {
        slideImage: baia1,
        logo: clientlogo2,
        slideTitle: 'Intro',
        slideAbout: [
          'Part of the sbe group, Mondrian hotels are known to occupy cultural hotspots around the globe. To grow with the local market, they opened a private beach club, Baia, at their Miami outpost, inviting members to relax, recharge, and unwind.',
        ],
      },
      {
        slideImage: baia2,
        slideTitle: 'Challenge',
        slideAbout: [
          'Baia Beach Club needed a CRM and membership management platform that prioritized both function and form. It was just as important to streamline hotel and club operations as it was to provide an engaging digital platform for their members. To effectively promote their amenities, they required booking technology to service their watersport activities, cabana rentals, and salon and spa appointments.',
        ],
      },
      {
        slideImage: baia3,
        slideTitle: 'Solution',
        slideAbout: [
          'We integrated the hotel’s PMS and built Baia Beach Club a modern, attractive members platform that provides an on-brand experience for their cosmopolitan clientele. Now equipped with a built-in booking engine, members are one click away from planning an exciting day at the beach.',
        ],
      },
    ],
    themeColor: 'black',
    customPaginationClass: 'clients-pagination-2',
  },
  {
    clientName: 'nocatee',
    clientTypeName: 'Luxury Residential',
    clientTypeAbout: '',
    clientTypeMobileImage: nocatee1,
    slides: [
      {
        slideImage: nocatee1,
        logo: clientlogo3,
        slideTitle: 'Intro',
        slideAbout: [
          'Developed by The PARC Group, Nocatee is a sprawling planned community in St. Johns County, Florida that’s equivalent to the land size of Manhattan.',
        ],
      },
      {
        slideImage: nocatee2,
        slideTitle: 'Challenge',
        slideAbout: [
          'Nocatee required a highly customized CRM and membership management platform that was dynamic enough to service the scale of its 13,323 acre property and offerings: 22 neighborhoods, two waterparks, fitness centers, F&B, and large-scale community events. To match the functionality of their CRM goals, they needed a complete overhaul of their mobile app.',
        ],
      },
      {
        slideImage: nocatee3,
        slideTitle: 'Solution',
        slideAbout: [
          'We worked with The PARC Group to build Nocatee a souped-up CRM with a multitude of integrations and customized experiences — all mobile. With their new app, residents can seamlessly experience all their community has to offer.',
        ],
      },
    ],
    themeColor: 'white',
    customPaginationClass: 'clients-pagination-3',
  },
]

const progressBarInfo = [
  {
    title: "Private members' clubs",
    themeColor: 'white',
  },
  {
    title: 'Boutique Hotels',
    themeColor: 'black',
  },
  {
    title: 'Luxury Residential',
    themeColor: 'white',
  },
]

const ClientsPage: FC = () => {
  const nocatee = useContent('clients-page-nocatee')
  const zeroBond = useContent('clients-zero-bond')
  const baja = useContent('client-page-baja')

  const data: ContentFragment[] = [zeroBond, baja, nocatee].filter(Boolean) as ContentFragment[]

  return (
    <DefaultLayout isHeaderFixed={false} isFooterAnimated={false} hasVideoBanner={false}>
      <OurClientsSection />
      {data.length !== 0 && (
        <div className='clients-types-core-block'>
          <ProgressBar container='clients-types-core-block__wrapper' progressBarInfo={progressBarInfo} />
          <div className='clients-types-core-block__wrapper'>
            {data?.map(clientType => (
              <ClientTypeSection item={clientType} key={clientType.description} />
            ))}
          </div>
        </div>
      )}
      <CustomerExperienceSection image={customerExperienceImage} isAnimated={false} />
    </DefaultLayout>
  )
}

export default ClientsPage
