import './index.scss'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { FC, memo, useEffect, useState } from 'react'
import { createBrowserRouter, RouteObject, RouterProvider, useLocation } from 'react-router-dom'
import BlogPage from 'src/pages/BlogPage/BlogPage'
import CapterraExperience from 'src/pages/Capterra/clubExperience/Capterra'
import CapterraCRM from 'src/pages/Capterra/crm/Capterra'
import CapterraHospitality from 'src/pages/Capterra/hospitalityProperty/Capterra'
import CapterraMembership from 'src/pages/Capterra/membershipManagement/Capterra'
import ClientsPage from 'src/pages/ClientsPage/ClientsPage'
import DeveloperPage from 'src/pages/DeveloperPage/DeveloperPage'
import FormPage from 'src/pages/FormPage/FormPage'
import HelpPage from 'src/pages/HelpPage/HelpPage'
import HomePage from 'src/pages/HomePage/HomePage'
import PricingPage from 'src/pages/PricingPage/PricingPage'
import ProductPage from 'src/pages/ProductPage/ProductPage'
import PrivacyPage from 'src/pages/RightsPage/PrivacyPage'
import TermsPage from 'src/pages/RightsPage/TermsPage'
import StatusPage from 'src/pages/StatusPage/StatusPage'
import TeamPage from 'src/pages/TeamPage/TeamPage'
import Brands from 'src/pages/Brands/Brands'
import routeMap from 'src/route-map.json'

import { BlogLayout, CapterraLayout } from '../../layouts'
import BlogPostPage from '../../pages/BlogPage/BlogPostPage'
import IntegrationsPage from 'src/pages/IntegrationsPage/IntegrationsPage'
import EarlyOnTheVinePage from 'src/pages/EarlyOnTheVinePage/EarlyOnTheVinePage'
import PricingAll from 'src/pages/PricingAll/Pricing'
import ElevateDiningPage from 'src/pages/ElevateDining/ElevateDiningPage'
import OpenTablePage from 'src/pages/OpenTablePage/OpenTablePage'

const vanityUrls = routeMap as Record<string, string>

/**
 * Used to serve all legacy and existing link redirects as of https://peoplevine.com
 * @constructor
 */
function StaticRouteMap(): JSX.Element {
  const { pathname, search } = useLocation()
  const [url, setUrl] = useState<string>()
  useEffect(() => {
    if (!url && vanityUrls) {
      const resolvedUrl = vanityUrls[pathname] ?? `https://blog.peoplevine.com/${pathname.concat(search)}`
      setUrl(resolvedUrl.endsWith(search) ? resolvedUrl : resolvedUrl.concat(search))
    }
  }, [url, pathname, search])
  useEffect(() => {
    url && window.location.replace(url)
  }, [url])
  return <StatusPage.Redirect url={url} />
}

const client = new ApolloClient({
  uri: `${import.meta.env.WEBSITE_API_URL ?? '/graphql'}`,
  cache: new InMemoryCache({ resultCaching: import.meta.env.PROD }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
})

const routes: RouteObject[] = [
  {
    path: '',
    errorElement: <StatusPage.Error />,
    children: [
      { path: '', element: <HomePage />, index: true },
      { path: 'clients', element: <ClientsPage /> },
      { path: 'developers-resources', element: <DeveloperPage /> },
      { path: 'error', element: <StatusPage.Error /> },
      { path: 'form', element: <FormPage /> },
      { path: 'help', element: <HelpPage /> },
      { path: 'pricing', element: <PricingPage /> },
      { path: 'privacy-policy', element: <PrivacyPage /> },
      { path: 'product', element: <ProductPage /> },
      { path: 'success', element: <StatusPage.Success /> },
      { path: 'success/:slug', element: <StatusPage.Success /> },
      { path: 'team', element: <TeamPage /> },
      { path: 'terms-of-use', element: <TermsPage /> },
      { path: 'logos', element: <Brands /> },
      { path: 'integrations', element: <IntegrationsPage /> },
      { path: 'early-on-the-vine', element: <EarlyOnTheVinePage /> },
      { path: 'pricing-all', element: <PricingAll /> },
      { path: 'elevate-dining', element: <ElevateDiningPage /> },
      // { path: 'open-table-integration', element: <OpenTablePage /> }

    ],
  },
  {
    path: 'rsvp',
    element: <BlogLayout />,
    children: [
      { path: '', element: <BlogPage /> },
      { path: ':id', element: <BlogPostPage /> },
    ],
  },
  {
    path: 'capterra',
    element: <CapterraLayout />,
    children: [
      { path: 'powerful-crm-for-member-based-lifestyle-hospitality-businesses', element: <CapterraCRM /> },
      { path: 'powerful-membership-management', element: <CapterraMembership /> },
      { path: 'powerful-club-management', element: <CapterraExperience /> },
      { path: 'powerful-member-experience-platform-for-hotels-and-resorts', element: <CapterraHospitality /> },
    ],
  },
  {
    path: '*',
    element: <StaticRouteMap />,
  },
]

const Router = () => <RouterProvider router={createBrowserRouter(routes)} />

const App: FC = () => (
  <ApolloProvider client={client}>
    <Router />
  </ApolloProvider>
)

export default memo(App)
