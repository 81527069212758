import { FC } from 'react'
import { useContent } from 'src/components/App/Content';
import DefaultLayout from 'src/layouts'
import VideoSection from 'src/sections/VideoSection/VideoSection';
import { CustomerExperienceSection } from 'src/sections/commonSections/CustomerExperienceSection/CustomerExperienceSection';
import FeatureSection from 'src/sections/integrationsPageSections/FeatureSection/FeatureSection';
import customerExperienceImage from 'src/sections/commonSections/CustomerExperienceSection/images/customer-experience-image-1.jpg'

const IntegrationsPage: FC = () => {
    const videoBannerSection = useContent('video-banner-integration-page')
    return (
        <DefaultLayout isHeaderFixed={false} isFooterAnimated={false} hasVideoBanner={false}>
            <VideoSection 
                urlVideo={videoBannerSection?.section?.videoDesktop?.url}
                mobileUrlVideo={videoBannerSection?.section?.videoMobile?.url}
            />
            <div className='features-wrapper ops-integrations-section'>
                <FeatureSection.OpsIntegrationSection />
            </div>
            <CustomerExperienceSection image={customerExperienceImage} isAnimated={false} />
        </DefaultLayout>
    )
}

export default IntegrationsPage;