
import { useEffect, useState } from 'react'

import { ReactComponent as LoginIcon } from '../images/login-icon.svg'

export const LoginRSVPButton = () => {

    const [showDropdown, setShowDropdown] = useState(false);

    

    useEffect(() => {
        const handleScroll = () => {
            if(showDropdown) setShowDropdown(false)
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showDropdown]);


    return (
        <div className="header-panel-container">
            <div className='header-panel-button' onClick={() => setShowDropdown(!showDropdown)}>
                <span className='header-panel-button__text'>peoplevine</span>
                <LoginIcon className={'header-panel-button__icon'} />
            </div>
            {showDropdown && (
                <div className="header-panel-dropdown fade-in-bottom">
                    <a href='https://rsvp.member.house/' target={'_blank'} rel='noreferrer' className="login-button">
                        <span>login RSVP</span>
                        {/* <LoginIcon className={'header-panel-button__icon'} /> */}
                    </a>
                    <a href='/' rel='noreferrer' className="login-button">
                        <span>To peoplevine</span>
                        {/* <LoginIcon className={'header-panel-button__icon'} /> */}
                    </a>
                </div>
            )}
        </div>

    )
}