import Button from 'src/components/Form/Button'
import './VideoBannerSection.scss'
import { useContent } from 'src/components/App/Content'

interface VideoBannerSectionProps {
    slug: string
}
const VideoBannerSection = ({
    slug
}: VideoBannerSectionProps) => {

    const videoBannerContent = useContent(slug)?.section

    return (
        <section className='video-banner-section'>
            <div className="video-wrapper">
                {videoBannerContent?.videoMobile && (
                    <video className="video video-mobile" autoPlay muted loop>
                        <source src={videoBannerContent?.videoMobile.url} type="video/mp4" />
                    </video>
                )}
                {videoBannerContent?.videoDesktop && (
                    <video className="video video-desktop" autoPlay muted loop>
                        <source src={videoBannerContent?.videoDesktop.url} type="video/mp4" />
                    </video>
                )}
            </div> 
            <div className="video-content container">
                <div className="video-content__image-container">
                    <img className="video-content__image" src={videoBannerContent?.media?.url} alt={videoBannerContent?.title || ''} />
                </div>
                <div className="video-content__info-container">
                    <h1 className="video-content__info-title">{videoBannerContent?.title}</h1>
                    <p className="video-content__info-text">{videoBannerContent?.description}</p>
                    <p className="video-content__info-text">{videoBannerContent?.text}</p>
                    { (videoBannerContent?.buttonText && videoBannerContent.urlButton) && (
                        <Button to={videoBannerContent.urlButton}>{videoBannerContent.buttonText}</Button>
                    )}
                </div>
            </div>
        </section>
    )
}

export default VideoBannerSection