import 'src/sections/teamPageSections/TeamMembersSection/TeamMembersSection.scss'

import { FC } from 'react'

import { ContentProps } from '../../../components/App/Content'

export const TeamMembersSection: FC<ContentProps> = ({ data }) => {
  return (
    <section className='team-members'>
      <div className='container'>
        <div className='team-members__wrapper'>
          <h2 className='team-members__title section-main-title'>{data?.title}</h2>
          <p className='team-members__text' />
          {data?.cards && (
            <div className='team-members-list'>
              {data?.cards.map(member => (
                <article key={member?.title} className='team-members-list-item'>
                  <div className='team-members-list-item__picture'>
                    <img src={member?.media?.url} alt={''} className='team-members-list-item__image' />
                  </div>
                  <div className='team-members-list-item__content'>
                    <h5 className='team-members-list-item__title section-secondary-title'>{member?.title}</h5>
                    <p className='team-members-list-item__position section-secondary-title'>{member?.subTitle}</p>
                    <p className='team-members-list-item__text'>{member?.description}</p>
                  </div>
                </article>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
