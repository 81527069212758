import 'src/sections/clientsPageSections/OurClientsSection/OurClientsSection.scss'

import { FC } from 'react'
import pattern from 'src/assets/images/patterns/pattern-3.png'
import personsClients from 'src/assets/images/people/persons (5).png'
import TickerImagesClientBar from 'src/components/TickerImagesBar/TickerImagesClientBar'

const slides = ['Private Members’ Clubs', 'Hotels And Resorts', 'Luxury Residential Communities']

export const OurClientsSection: FC = () => (
  <section className='our-clients' role={'presentation'}>
    <div className='ornament-block'>
      <img src={pattern} alt='ornament' className='ornament-block__image' />
    </div>
    <div className='container'>
      <div className='our-clients__wrapper'>
        <div className='our-clients-block'>
          <h1 className='our-clients-block__title main-title'>Who are our clients</h1>
        </div>
        <div className='our-clients-block'>
          <div className='our-clients-block__picture'>
            <img src={personsClients} alt='image' className='our-clients-block__image' />
          </div>
        </div>
        <div className='our-clients-block'>
          <ul className='our-clients-block-list'>
            {slides.map((slideName, index) => (
              <li key={index} className='our-clients-block-list__item'>
                {slideName}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <TickerImagesClientBar />
  </section>
)
