import 'src/sections/blogPageSections/Post/Post.scss'
import './CustomPost.scss'

import { FC, useMemo, useState } from 'react'
import Slider from 'react-slick'
import { ReactComponent as Facebook } from 'src/assets/icons/socials/facebook-icon.svg'
import { ReactComponent as Instagram } from 'src/assets/icons/socials/instagram-icon.svg'
import { ReactComponent as Linkein } from 'src/assets/icons/socials/linkedin-icon.svg'
import { ReactComponent as Twitter } from 'src/assets/icons/socials/twitter-icon.svg'
import BackLink from 'src/components/BackLink/BackLink'
import { useSurveyApi } from 'src/components/Email'
import Button from 'src/components/Form/Button'
import { patternGenerator } from 'src/components/Patterns/patternsGenerator'
import { PostProps } from 'src/sections/blogPageSections/Blog/Blog'
import { DownloadArticleModal, DownloadDrawer, ResultDrawer } from 'src/sections/homePageSections/SubscriptionSection/SubscriptionSection'

import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import { Helmet } from 'react-helmet'

const Content: FC<Partial<PostProps & { readMore: FC }>> = ({ readMore, ...props }) => {
  const ReadMore = readMore ?? (() => null)
  const patterns = useMemo(() => patternGenerator({ quantity: 20, theme: 'light' }), [])
  const sliderSettings = {
    dots: false,
    arrows: false,
    draggable: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    slidesPerView: 1,
    adaptiveHeight: true,
  }

  return (
    <section className='custom-post'>
      <div className='ornament-block'>
        <div className='ornament-block__wrapper'>{patterns}</div>
      </div>
      <div className='container'>
        <div className='post custom'>
          <BackLink />
          <div className='post__wrapper'>
            <h1>{props.title}</h1>
            <div className='post__inner'>
              <div className='hero'>
                <div className='author-wrapper'>
                  <p className='read-time'>12 mins read</p>
                  {props.author?.map((item, index) => (
                    <div className='author' key={index}>
                      {item.image && (
                        <div className='author__image'>
                          <img src={item.image} alt={item.name} className='image' />
                        </div>
                      )}
                      <div className='author__description'>
                        <p className='author__name'>{item.name}</p>
                        {item.position && <p className='author__position'>{item.position}</p>}
                      </div>
                    </div>
                  ))}
                  <div className='author-socials'>
                    <a href='https://www.linkedin.com/company/peoplevine/' target='_blank' rel='noreferrer'>
                      <Linkein />
                    </a>
                    <a href='https://twitter.com/peoplevine' target='_blank' rel='noreferrer'>
                      <Twitter />
                    </a>
                    <a href='https://www.facebook.com/PeopleVine/' target='_blank' rel='noreferrer'>
                      <Facebook />
                    </a>
                    <a href='https://www.instagram.com/peoplevine' target='_blank' rel='noreferrer'>
                      <Instagram />
                    </a>
                  </div>
                </div>
                <img className='hero-img' src='/posts/post-1/post%20(11).png' alt='' />
              </div>
              <div className='post__content'>
                <p>
                  Starting our careers in the city, chasing client ad buys and tech gigs, most stories of cool afterparties and overflowing
                  drinks behind the velvet rope we owe to our friend Kory. He was that guy everyone wanted to be around, with a knack for
                  building relationships and forming connections. Kory was the one who thrived on the excitement of being around influential
                  people, artists, and creators. He loved the hustle of staying one step ahead.
                </p>
                <p>
                  Running into Kory now, a decade later, still well-connected and in the game, no surprise he’s a general manager of a
                  swanky hotel and members’ club. It’s one of those places when you walk in, the finishes are plush, concrete walls exposed,
                  and the operation is orderly and fluid. But he tells us not to be fooled - behind the scenes, hospitality is synchronized
                  chaos that requires careful orchestration. The magic of exceptional experiences is about beautifully executed teamwork –
                  motivated, efficient, and perfectly connected. In other words: always on and fluid. It’s a world we are coming to
                  understand well, but from a different vantage point, as a software startup. Nowadays, in frequent discussions with general
                  managers like Kory, a repeated set of narratives are playing out.
                </p>
                <blockquote>
                  <p>
                    The magic of exceptional experiences requires beautifully executed teamwork – motivated, efficient, and perfectly
                    connected across departments. In other words: always on and fluid.
                  </p>
                </blockquote>
                <div className='slider-wrapper'>
                  <div className='images'>
                    <img src='/posts/post-1/post%20(9).png' alt='' />
                    <img src='/posts/post-1/post%20(8).png' alt='' />
                  </div>
                  <Slider {...sliderSettings}>
                    <img src='/posts/post-1/post%20(9).png' alt='' />
                    <img src='/posts/post-1/post%20(8).png' alt='' />
                  </Slider>
                </div>
                <p>
                  Before the pandemic hit, the biggest enemies to service quality and team coordination were high turnover and seasonality
                  common in this industry. Now some of the best people are gone, and in many ways, it feels like starting over. Maintaining
                  service excellence is simultaneously an art and a chore. When a Instagram influencer @Connoisseur_Brad strolls in on a
                  Thursday morning, fingers crossed that someone’s been there long enough to remember he takes oat milk with his coffee…
                </p>
                <p>
                  Expectations for a general manager are always evolving. Instant gratification provided by social media, commerce,
                  rideshare, and entertainment apps have raised customer expectations of access, timeliness and individualized service.
                  Hospitality staff must go above and beyond to deliver this same personalized, elevated level of service around the clock
                  incoming from all channels.
                </p>
                <p>
                  The difficulty is achieving consistency at scale in the face of these challenges. Technology is a critical component, and
                  although it has woven itself into nearly every aspect of daily life, hospitality often struggled to keep up, running on
                  antiquated systems and ideas. Guests wonder why they have to keep repeating their preferences, and staff is frustrated in
                  keeping up with it all. It feels like one continuous Groundhog Day.
                </p>
                <p>
                  Hospitality technology landscape doesn’t make it easy to deliver a seamless guest experience. In a recent
                  hospitalitynet.com index, we counted over 35 categories of various software solutions available to operators?!.
                </p>
                <img src='/posts/post-1/post%20(1).png' className='fit' alt='' />
                <p>
                  We do love software, we are a software company after all, but what we see are fragmented systems and fragemented
                  relationships. As a number of digital innovations in the space have arrived in the past 10 years they have been channel
                  specific (booking, social, ...) or vertically oriented (new POSs, new PMSs, new CRMs...) letting the guests and members to
                  stich siloed experience for themselves. But honestly, no matter how much one might love a place, who will bother to
                  download multiple apps to interact with a single property and staff?
                </p>
                <blockquote>
                  <p>Fragmented systems and fragemented relationships are the dominant hospitality tech paradigm</p>
                </blockquote>
                <p>
                  This has left the operators themselves to close the gap, but recent LinkedIn profile data search indicates that tech
                  employees at top hospitality brands make up less than 3% of workers, so how will these problems be solved?
                </p>
                <p>
                  Loyalty programs were going to be the answer to experience and personalization conundrums—until they weren’t. In latest
                  industry stats, 67% of customers don’t use them and are not loyal, and we suspect the 33% that use them feel trapped into
                  their ‘status’. And good luck using the app for anything other than vanity status.
                </p>
                <p>
                  To us, the concept of a loyalty program as presented by the industry is totally backwards (all respect to Gary Loveman who
                  some of us studied and admire) and a hangover from generations past.
                </p>
                <p>
                  Loyalty should be earned by the business from the customer, not the other way around. It shouldn’t be a never-ending
                  scheme of putting customers through paces to earn business’s loyalty of low cost / high value ROI rewards. Todays’
                  generations want access to experiences now, so please don’t ask me to book another night in the middle of nowwhere on
                  Thursday to get that bump. We also wonder what will happen to these programs anyway as a lot fewer of us will be racking
                  up points on business travel expenses. In our observation this model not only fails to elevate guest loyalty, but it also
                  fails to capture useful data that is actionable and accessible by front of the house staff members in real-time and the
                  moment of impact.
                </p>
                <blockquote>
                  <p>Loyalty should be earned by the business from the customer, not the other way around.</p>
                </blockquote>
                <p>
                  After working for more than a decade on the front lines of eCommerce, tech and digital media, our team at PeopleVine
                  understands hundreds of versions of this frustration. Digital innovators have disrupted the established ways of doing
                  business across industries. Hospitality has seen its fair share of apps built around distinct slices of the guest journey—
                  Expedia, OpenTable, Trip Advisor, AirBnB—have engaged and empowered users.
                </p>
                <p>
                  Meanwhile, inside the four walls, most restaurants and hotels operate primarily through transactional, offline
                  relationships with their guests. Every week, in our discussions with GMs, hoteliers, and private club owners we are asked
                  to help them leverage technology as a way to deliver true hospitality. In their terms, they want to have a way to deliver
                  personalized experiences that evolve with each guest interaction and gain insight into their preferences and habits that
                  will not only help ops staff anticipate expectations but do so with grace and ease. And they see digital channels and apps
                  as a foundational interaction point not to replace but complement the experience.
                </p>
                <p>
                  We have seen this story unfold first hand in the retail and media industries, where giants like Target, Walmart, Disney
                  have first resisted digital adoption and let the experience fragment itself, then in fight for survival, embraced digital
                  transformation at the core. The hospitality industry has no choice but to follow suit. Insights that we have have learned,
                  is that the key to transformation is a shift in mindset from seeing digital as an enemy to the way things have always
                  been, or something that is not ‘organic enough’ or something that does not apply.
                </p>
                <p>
                  The converging forces of digital, consumer, and industry change—further accelerated by the pandemic—have arrived in
                  hospitality as a non-negotiable. Who would have thought in January 2020 that Alinea, the famous 3 star Michelin restaurant
                  in our hometown, two months later would be serving curbside take out family meals via an app? But they pivoted beautifully
                  in record time by thinking about how to serve their customer in the context of a new reality and introduced the brand to
                  even more customers.
                </p>
                <ReadMore />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Leadership: FC<Partial<PostProps>> = ({
  title = 'Membership is a future of hospitality',
  author = [
    {
      position: 'Head of product marketing',
      name: 'Chris Lindsey',
      image: undefined,
    },
    {
      name: 'Mobolaji Akintude',
      position: 'Head of business development',
      image: undefined,
    },
  ],
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { sendEmail, error, requestSent } = useSurveyApi()

  const readMore = () => (
    <div className='read-more'>
      <div className='read-more__content'>
        <h2>Download the pdf file to Read the rest of this article.</h2>
        <Button
          icon={Download}
          onClickCapture={e => {
            setIsOpen(() => true)
            e.preventDefault()
          }}
          width={245}
        >
          Download PDF
        </Button>
      </div>
    </div>
  )

  return (
    <>
      <Helmet title={title} meta={[{ name: 'author', content: author.map(it => it.name).join(', ') }]} />
      <Content readMore={readMore} title={title} author={author} {...props} />
      {!requestSent ? (
        <DownloadDrawer visible={isOpen} onClose={() => setIsOpen(false)}>
          <DownloadArticleModal
            onFormSubmit={values =>
              sendEmail(4241, {
                18980: values.membersClub,
                18981: values.role,
                18982: values.firstName,
                18983: values.lastName,
                18984: values.email,
                18985: values.company,
                18986: values.website,
                18987: values.country.value,
                18988: 'Tag',
              })
            }
          />
        </DownloadDrawer>
      ) : (
        <ResultDrawer status={error === undefined ? 'success' : 'error'} visible={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </>
  )
}

export default Leadership
