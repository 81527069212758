import 'src/components/Loader/Loader.scss'
import Lottie from "react-lottie";

import { FC } from 'react'

import animationData from "src/assets/lotties/peoplevine-logo-loading.json";

export const Loader: FC = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <div className="loader-container">
            <div className="lottie-container">
                <Lottie options={defaultOptions} />
            </div>
        </div>
    )
}