import './Touchpoints.scss'

import { FC } from 'react'
import mainImage from 'src/assets/images/capterra/touchpoints.png'

import platformPattern1 from './images/platform-pattern-2.png'
import platformPattern2 from './images/platform-pattern-2.png'

const Touchpoints: FC = () => {
  return (
    <section className='touchpoints-capterra' id='key-benefits'>
      <div className='ornament-block'>
        <img src={platformPattern1} alt='ornament' className='ornament-block__image' />
        <img src={platformPattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='touchpoints-capterra__wrapper'>
          <div>
            <h2 className='touchpoints-capterra__title section-main-title'>
              An Elevated Member Experience Start with collecting Data across all touchpoints
            </h2>
            <ul className='touchpoints__list'>
              <li className='touchpoints__list-item'>Enrollment</li>
              <li className='touchpoints__list-item'>Engagement</li>
              <li className='touchpoints__list-item'>Payments</li>
              <li className='touchpoints__list-item'>Tracking</li>
            </ul>
          </div>
          <div>
            <img className='touchpoints-main-img' src={mainImage} alt='touchpoints logo' />
          </div>
        </div>
      </div>
    </section>
  )
}

export { Touchpoints }
