import { FC } from 'react'

import { Status } from '../../components/Status'
import { DefaultLayout } from '../../layouts'
import { CapterraTracker } from '../../sections/capterra/CapterraTracker'

const Success: FC = () => {
  const tracker = history.state?.prevPath?.includes('capterra')

  return (
    <DefaultLayout>
      {tracker && <CapterraTracker />}
      <Status type={'success'} />
    </DefaultLayout>
  )
}

const Error: FC = () => {
  return (
    <DefaultLayout>
      <Status type={'error'} />
    </DefaultLayout>
  )
}
type RedirectProps = { url?: string }
const Redirect: FC<RedirectProps> = ({ url = '' }) => (
  <DefaultLayout>
    <Status type={'redirect'} />
    {url && window.location.assign(url)}
  </DefaultLayout>
)

const NotFound: FC = () => {
  return (
    <DefaultLayout>
      <Status type={'not-found'} />
    </DefaultLayout>
  )
}

const StatusPage = { Success, Error, NotFound, Redirect }
export default StatusPage
