import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { FC, useEffect } from 'react'
import access from 'src/assets/icons/dev-resources/access-personalized.svg'
import connect from 'src/assets/icons/dev-resources/connect-members.svg'
import digital from 'src/assets/icons/dev-resources/digital-experience.svg'
import dollar from 'src/assets/icons/dev-resources/dollar.svg'
import energy from 'src/assets/icons/dev-resources/energy.svg'
import stockExchange from 'src/assets/icons/dev-resources/stock-exchange.svg'
import assa from 'src/assets/icons/pms/assaAbloy.svg'
import hapi from 'src/assets/icons/pms/hapi.svg'
import mews from 'src/assets/icons/pms/mews.svg'
import operacloud from 'src/assets/icons/pms/operacloud.svg'
import salto from 'src/assets/icons/pms/salto.svg'
import vizpin from 'src/assets/icons/pms/vizpin.svg'
import { ReactComponent as TwentyTwo } from 'src/assets/images/clients/22.svg'
import { ReactComponent as Core } from 'src/assets/images/clients/core.svg'
import { ReactComponent as Gleneagles } from 'src/assets/images/clients/gleneagles.svg'
import { ReactComponent as Tryp } from 'src/assets/images/clients/tryp.svg'
import slide from 'src/assets/images/crm-preview/hotel-room.png'
import crm2 from 'src/assets/images/crm-preview/integrated-platforms.png'
import crm1 from 'src/assets/images/crm-preview/people-list.png'
import ncrAloha from 'src/assets/images/partners/ncr-aloha.svg'
import square from 'src/assets/images/partners/square.svg'
import { DefaultLayout } from 'src/layouts/DefaultLayout'
import Hero from 'src/sections/capterra/Hero'
import Resources from 'src/sections/capterra/Resources'
import { TwoSidesPlatformSection } from 'src/sections/capterra/TwoSidesPlatformSection'
import { ClientsSection } from 'src/sections/commonSections/ClientsSection/ClientsSection'
import { FeedbackSection } from 'src/sections/homePageSections/FeedbackSection/FeedbackSection'
import membersPattern1 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-1.png'
import membersPattern2 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-2.png'
import membersPattern3 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-3.png'
import { ProductItemSection } from 'src/sections/productPageSections/ProductItemSection/ProductItemSection'

import clientlogo1 from '../../../assets/images/clients/baia.svg'
import clientImage1 from '../../../assets/images/common/picture (4).png'
import clientImage2 from '../../../assets/images/common/picture (5).png'
import clientImage3 from '../../../assets/images/common/picture (6).png'
import { useMetaQuery } from '../../../graphql'
import { ContactForm } from '../../../sections/capterra/ContactForm'
import { Pricing } from '../../../sections/capterra/Pricing'
import { Touchpoints } from '../../../sections/capterra/Touchpoints'
import { ClientTypeSection } from '../../../sections/clientsPageSections/ClientTypeSection/ClientTypeSection'

export const clients = [
  {
    id: '1',
    title: 'BOuTIQUE HOTEL',
    image: <Core />,
  },
  {
    id: '2',
    title: 'luxury HOTEL',
    image: <Tryp />,
  },
  {
    id: '3',
    title: 'luxury HOTEL',
    image: <Gleneagles />,
  },
  {
    id: '4',
    title: 'BOuTIQUE hotel',
    image: <TwentyTwo />,
  },
]

export const product = {
  title: 'end-to-End Membership Management',
  about: 'PeopleVine allows you to manage and engage with your member base while creating a self-service experience, so your staff can focus on growth.',
  list: [
    {
      image: crm1,
      list: [
        {
          title: 'A Single Dashboard',
          items: ['Application processing and onboarding', 'Member self-service tools', 'Branded member portal', 'Searchable member directory'],
        },
        {
          title: 'Member Interaction Tracking',
          items: ['Triggered, automated outreach', 'Email and SMS newsletters', 'Affiliate program tracking', 'Multi-location support'],
        },
      ],
    },
    {
      image: crm2,
      list: [
        {
          title: 'Integrated Payment and Accounting',
          items: ['Automated subscription payments', 'Billing and receipt management', 'Cardless payments', 'Membership dues processing'],
        },
        {
          title: 'Automated Marketing',
          items: ['Drip and date-based campaigns', 'Automatic triggers', 'Chatbots on Twitter and SMS', 'Third-party integrations and APIs'],
        },
      ],
    },
  ],
  themeColor: 'black',
  additionalClass: 'members',
  key: 'management',
  patterns: [
    {
      image: membersPattern1,
      styles: {
        top: '10%',
        right: '0',
      },
    },
    {
      image: membersPattern2,
      styles: {
        top: '15%',
        left: '5%',
      },
    },
    {
      image: membersPattern3,
      styles: {
        top: '35%',
        left: '0',
      },
    },
  ],
  index: 1,
}

const heroSlides = [
  {
    title: 'The premier membership platform',
    subtitle: 'Designed from the ground up to create premier member experiences, empower ops teams to excel at scale and build forever transactions.',
    img: slide,
  },
  {
    title: 'Create member experiences for your guest',
    subtitle: 'Build your member community, drive engagement, and connect the dots that elevate experiences and revenues.',
    img: slide,
  },
]

const productsIntegration = {
  id: '1',
  title: 'Integrations',
  about: 'Integrated with modern PMS systems, POS, Access Control systems.',
  platforms: [
    {
      platformsTitle: 'Point of sale systems',
      platformsList: [
        {
          platformItemLogo: square,
          platformItemAbout: 'Tools to run your business—even when it’s not business as usual',
          link: '',
        },
        {
          platformItemLogo: ncrAloha,
          platformItemAbout: 'NCR Aloha Essentials brings together best-in-class software, hardware, and services you need to run your operations',
          link: '',
        },
      ],
    },
    {
      platformsTitle: 'Property Management Systems (PMS)',
      platformsList: [
        {
          platformItemLogo: hapi,
          platformItemAbout:
            'The Hapi data streaming platform is based on the same proven technology that runs some of the largest social media and business services.',
          link: '',
        },
        {
          platformItemLogo: mews,
          platformItemAbout: 'Mews is designed to simplify and automate all operations for modern hoteliers and their guests.',
          link: '',
        },
        {
          platformItemLogo: operacloud,
          platformItemAbout:
            'Oracle Hospitality OPERA Property Management Cloud CreateEnterpass are a cloud-based, portable empowered stage for cutting edge inn property the executives.',
          link: '',
        },
      ],
    },
    {
      platformsTitle: 'Access control systems',
      platformsList: [
        {
          platformItemLogo: assa,
          platformItemAbout: 'Assa Abloy offers a complete range of access solutions making people feel safe and secure in simple and convenient ways.',
          link: '',
        },
        {
          platformItemLogo: salto,
          platformItemAbout: 'Salto gives users the ability to control all their access needs and secure all their doors without complex and expensive wiring.',
          link: '',
        },
        {
          platformItemLogo: vizpin,
          platformItemAbout: 'VIZpin is a complete bluetooth smartphone access control system that requires no network connection.',
          link: '',
        },
      ],
    },
  ],
  themeColor: 'grey',
  additionalClass: 'integrations',
}

const clientType = {
  title: 'Member Experience Platform Subscription Management, & CRM',
  clientName: 'zeroBond',
  clientTypeName: 'Private members clubs',
  clientTypeAbout: '',
  clientTypeMobileImage: clientImage1,
  slides: [
    {
      slideImage: clientImage1,
      logo: clientlogo1,
      slideTitle: 'Overview',
      slideAbout: [
        'Part of the sbe group, Mondrian hotels are known to occupy cultural hotspots around the globe. To grow with the local market, they opened a private beach club, Baia, at their Miami outpost, inviting members to relax, recharge, and unwind.',
      ],
    },
    {
      slideImage: clientImage2,
      slideTitle: 'Challenge',
      slideAbout: [
        'Baia Beach Club needed a CRM and membership management platform that prioritized both function and form. It was just as important to streamline hotel and club operations as it was to provide an engaging digital platform for their members. To effectively promote their amenities, they required booking technology to service their watersport activities, cabana rentals, and salon and spa appointments.',
      ],
    },
    {
      slideImage: clientImage3,
      slideTitle: 'Solution',
      slideAbout: [
        'We integrated the hotel’s PMS and built Baia Beach Club a modern, attractive members platform that provides an on-brand experience for their cosmopolitan clientele. Now equipped with a built-in booking engine, members are one click away from planning an exciting day at the beach.',
      ],
    },
  ],
  themeColor: 'grey',
  customPaginationClass: 'clients-pagination-1',
}

export const resourcesData = [
  {
    title: 'Save up to 60% on Software',
    image: dollar,
    subtitle: 'Invest in the Guest Experience, not back-end code (we already did that...).',
  },
  {
    title: 'Operate More Efficiently',
    image: energy,
    subtitle: 'Give your staff time back while giving your members digital tools they want to use.',
  },
  {
    title: 'Reduce Member Attrition + Increase Spend',
    image: stockExchange,
    subtitle: 'A 1-2% reduction in Member Attrition self-funds investment.',
  },
]

export const benefits = [
  {
    title: 'Seamless Digital Experience',
    image: digital,
    subtitle: 'Give your members a single digital home where they can access all your venue as offer',
  },
  {
    title: 'Access Personalized Hospitality',
    image: access,
    subtitle: 'Show programming and amenities that match your members interests and preferences',
  },
  {
    title: 'Connect with Members and Staff',
    image: connect,
    subtitle: 'Create a sense of belonging and build community among members and staff',
  },
]

const Capterra: FC = () => {
  const { data } = useMetaQuery({ variables: { where: { slug: 'capterra-hospitality' } } })
  const meta = data?.metas?.[0]

  useEffect(() => {
    const twoSidesAnimation = () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1200px)': () => {
          gsap.to('.two-sides-platform-part--dark', {
            scrollTrigger: {
              trigger: '.two-sides-platform-part--dark',
              start: '15% 90%',
              end: '70% 30%',
              scrub: 1,
            },
            ease: 'none',
            clipPath: 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
          })
        },
      })
    }

    window.addEventListener('load', () => twoSidesAnimation())
    return () => {
      window.removeEventListener('load', () => twoSidesAnimation())
    }
  })

  return (
    <DefaultLayout
      isHeaderFixed={true}
      isHeaderShort={true}
      hasLogin={false}
      isHeaderIndependent={true}
      isFooterAnimated={false}
      isFooterShort={true}
      footerContent={false}
      headerText={'Powerful Member Experience Platform for Hotels and Resorts'}
    >
      <section className='capterra'>
        <Hero slides={heroSlides} />
        <Resources theme={'light'} title={'key ops team benefits'} data={resourcesData} />
        <Resources theme={'light'} title={'key member\nbenefits'} data={benefits} />
        <ClientTypeSection {...clientType} download={false} />
        <Touchpoints />
        <TwoSidesPlatformSection />
        <ProductItemSection key={productsIntegration.title} index={1} />
        <Pricing />
        <ClientsSection theme={'light'} qty={2} isHomePage={true} hasSeeMore={false} hasReadMore={false} clients={clients} />
        <FeedbackSection hasTitle={true} theme={'grey'} />
        <ContactForm />
      </section>
    </DefaultLayout>
  )
}

export default Capterra
