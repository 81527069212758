// eslint-disable-next-line simple-import-sort/imports
import 'src/sections/homePageSections/HomeVideoSection/HomeVideoSection.scss'

import { FC, useState } from 'react'

import { useContent } from 'src/components/App/Content'

import VideoHolder from './components/VideoHolder'



import { Loader } from '../../../components/Loader/Loader'

const HomeVideoSection = ({
  slugContent,
  className,
  showPlayButton
}: {
  slugContent: string;
  className?: string;
  showPlayButton: boolean;
}) => {

  const [isMobileVideoLoaded, setIsMobileVideoLoaded] = useState(false);
  const [isDesktopVideoLoaded, setIsDesktopVideoLoaded] = useState(false);

  const heroVideoSection = useContent(slugContent);

  return (
    <section className={`hero-video-section ${className}`} id='hero-video-section'>


      {(!isMobileVideoLoaded && !isDesktopVideoLoaded) && (<Loader />)}

      {
        heroVideoSection?.section?.videoDesktop?.url && (
          <video className='m-none' width="750" height="500" autoPlay muted playsInline loop onLoadedData={() => setIsDesktopVideoLoaded(true)}>
            <source src={heroVideoSection?.section?.videoDesktop?.url} type="video/mp4" />
          </video>
        )
      }

      {
        heroVideoSection?.section?.videoMobile?.url && (
          <video className='dx-none' width="750" height="500" autoPlay muted playsInline loop onLoadedData={() => setIsMobileVideoLoaded(true)}>
            <source src={heroVideoSection?.section?.videoMobile?.url} type="video/mp4" />
          </video>
        )
      }
      <div className="video-overlay"></div>
      <VideoHolder
        title={heroVideoSection?.section?.title || ''}
        subTitle={heroVideoSection?.section?.subTitle || ''}
        showPlayButton={showPlayButton}
      />
    </section>
  )
}

HomeVideoSection.defaultProps = {
  showLoader: true,
  showPlayButton: true
}

export { HomeVideoSection }
