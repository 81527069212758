import 'src/sections/homePageSections/HomeHeroSection/HomeHeroSection.scss'

import { FC } from 'react'
import { ReactComponent as ScrollDown } from 'src/assets/icons/scroll.svg'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useContent } from '../../../components/App/Content'
import Buttons from '../../../components/button'
import { usePostsQuery } from '../../../graphql'
import { ImageSlides, IMedia } from './components/ImageSlides'

SwiperCore.use([Autoplay, EffectFade])

const HomeHeroSection: FC = () => {
  const { data } = usePostsQuery()
  const mainArticle = data?.posts?.some(item => item?.slug.includes('membership-is-the-future'))
  const content = useContent('home-hero')
  const heroSlides = content?.cards

  return (
    <section className='hero-section' id='hero-section' role={'presentation'}>
      <div className='container'>
        <div className='hero-section__wrapper'>
          <div className='hero-section-slider'>
            <Swiper effect='fade' slidesPerView={1} loop={true} allowTouchMove={false} autoplay={{ delay: 2500 }} speed={1000}>
              {heroSlides?.map((slide, index) => (
                <SwiperSlide key={slide?.id}>
                  <div className='hero-section-slider__item'>
                    <div className='hero-section-slider__item-wrapper'>
                      <div key={1} className={'hero-slide-block'}>
                        {index === 0 && (
                          <h1 className={`hero-slide-block__title main-title hero-slide-block__title--${index + 1}`}>{slide?.title}</h1>
                        )}
                        {index !== 0 && (
                          <h2 className={`hero-slide-block__title main-title hero-slide-block__title--${index + 1}`}>{slide?.title}</h2>
                        )}
                        <p className='hero-slide-block__text'>{slide?.subTitle}</p>
                        
                      </div>
                      <div key={2} className={`hero-slide-block hero-slide-block--${index + 1}`}>
                        {
                          slide?.media_slides && slide?.media_slides.map( media => (<ImageSlides key={media?.id} index={index} media={media as IMedia} />))
                        }
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper_but"><Buttons.RequestDemo /></div>
            </Swiper>
          </div>
        </div>
      </div>
      <ScrollDown
        className='scroll-button'
        width='24'
        height='36'
        onClick={() => document.querySelector(mainArticle ? '#subscription' : '#services')?.scrollIntoView()}
      />
    </section>
  )
}

export { HomeHeroSection }
