import membersPattern1 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-1.png'
import membersPattern2 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-2.png'
import membersPattern3 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-3.png'
import opsTeamsPattern1 from 'src/sections/productPageSections/ProductItemSection/images/patterns/ops-teams-pattern-1.png'
import opsTeamsPattern2 from 'src/sections/productPageSections/ProductItemSection/images/patterns/ops-teams-pattern-2.png'
import opsTeamsPattern3 from 'src/sections/productPageSections/ProductItemSection/images/patterns/ops-teams-pattern-3.png'
import opsTeamsPattern4 from 'src/sections/productPageSections/ProductItemSection/images/patterns/ops-teams-pattern-4.png'
import { TListProductItem } from 'src/types/product'

import { useContent } from '../components/App/Content'



export const Products = () => {
    const contentMembers = useContent('home-products-members')
    const contentTeam = useContent('home-products-team')

    const listContentMembers = contentMembers?.cards?.map(card => ({
        listItemTitle: card?.title,
        listItemAbout: card?.subTitle,
        image: card?.media?.url
    }));

    const listContentTeam = contentTeam?.cards?.map(card => ({
        listItemTitle: card?.title,
        listItemAbout: card?.subTitle,
        image: card?.media?.url
    }));

    return [
        {
            title: contentMembers?.title,
            about: contentMembers?.description,
            id: '123',
            list: listContentMembers as TListProductItem,
            themeColor: 'black',
            additionalClass: 'members',
            patterns: [
                {
                    image: membersPattern1,
                    styles: {
                        top: '10%',
                        right: '0',
                    },
                },
                {
                    image: membersPattern2,
                    styles: {
                        top: '15%',
                        left: '5%',
                    },
                },
                {
                    image: membersPattern3,
                    styles: {
                        top: '35%',
                        left: '0',
                    },
                },
            ],
        },
        {
            title: contentTeam?.title,
            about: contentTeam?.description,
            id: '1234',
            list: listContentTeam as TListProductItem,
            patterns: [
                {
                    image: opsTeamsPattern1,
                    styles: {
                        top: '10%',
                        left: '5%',
                    },
                },
                {
                    image: opsTeamsPattern2,
                    styles: {
                        top: '10%',
                        right: '0',
                    },
                },
                {
                    image: opsTeamsPattern3,
                    styles: {
                        top: '45%',
                        right: '0',
                    },
                },
                {
                    image: opsTeamsPattern4,
                    styles: {
                        bottom: '20%',
                        right: '0',
                    },
                },
            ],
            themeColor: 'white',
            additionalClass: 'ops-teams',
        },
    ]
}