import './Heading.scss'

import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as RSVPLogo } from 'src/assets/images/rsvp/rsvp-logo.svg'

const blogCategories = [
  {
    title: 'All',
    path: '/rsvp',
  },
  {
    title: 'Articles',
    path: '/rsvp?category=articles',
  },
  {
    title: 'In The News',
    path: '/rsvp?category=in-the-news',
  },
  // {
  //   title: 'Club Directory',
  //   path: '',
  // }
]

const Categories: FC = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const currentPath = `${pathname}${search}`

  return (
    <div className='blog__categories'>
      <ul>
        {blogCategories.map((item, index) => (
          <li key={index}>
            <Link to={item.path} >
              <p
               className={`${currentPath === item.path ? 'active' : '' }`}
              >
                {item.title}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Heading: FC = () => {
  return (
    <div className='blog__heading'>
      <div className='titling'>
        <h1>
          <RSVPLogo />
        </h1>
        <p className='subtitle'>The Members Club Magazine</p>
      </div>
      <Categories />
    </div>
  )
}

export default Heading
