import { FC, PropsWithChildren } from 'react'
import { Helmet, HelmetData, HelmetProps } from 'react-helmet'
import Post from 'src/sections/blogPageSections/Post/Post'

import { PostProps } from '../../sections/blogPageSections/Blog/Blog'
const PostPage: FC<PropsWithChildren<PostProps>> = props => {
  return (
    <>
      <Helmet
        title={`${props.title}`}
        meta={[{ name: 'author', content: props.author?.map(it => it.name).join(', ') }].concat(
          props.meta?.map(it => ({ name: it?.name as string, content: it?.content as string })) ?? [],
        )}
      />
      <section>
        <Post {...props} />
      </section>
    </>
  )
}

export default PostPage
