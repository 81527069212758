import './BackLink.scss'

import { FC } from 'react'
import BackArrow from 'src/assets/icons/button-arrow.svg'

const BackLink: FC = () => {
  return (
    <div className='backlink' onClick={() => history.back()}>
      <div className='backlink__inner'>
        <img src={BackArrow} alt='go back' />
        <p>Back</p>
      </div>
    </div>
  )
}

export default BackLink
