import 'src/components/EarlyForm/EarlyForm.scss'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FC, useRef, useState } from 'react'
import { Navigate } from 'react-router'
import * as Yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg'
import { useSurveyApi } from '../Email'
import countries from '../../data/countries.json'
import { BusinessesTypes, FormValues, referrals, roles, SelectField, timelines } from '../../sections/capterra/ContactForm'
import { patternGenerator } from '../Patterns/patternsGenerator'
import clubs from 'src/assets/icons/businesses/private-clubs.svg'
import hotels from 'src/assets/icons/businesses/boutique-hotels.svg'
import residentials from 'src/assets/icons/businesses/icon_restaurant.svg'
import ReCAPTCHA from "react-google-recaptcha";
import luxury from 'src/assets/icons/businesses/luxury-hotel-resort.svg'
import { removeHTMLTags } from 'src/utils'

const patterns = patternGenerator({ quantity: 0, theme: 'dark' })
const businesses = [
  {
    id: 1,
    title: "Private Social Members’ Clubs",
    image: clubs,
  },
  {
    id: 2,
    title: 'Luxury Hotels & Branded Residences',
    image: hotels,
  },
  {
    id: 3,
    title: 'Dining Clubs & Restaurant Groups',
    image: residentials,
  }
]
const product = [
  { id: 1, title: "Member App/Portal" },
  { id: 2, title: "Dash.5" },
  { id: 3, title: "Check-In 2.0" },
  { id: 4, title: "Connect+ (Integrations)" },
];
const handleChangeCheckbox = (value: string, stateFunction: React.Dispatch<React.SetStateAction<string[]>>) => {
  stateFunction(prevState => {
    if (prevState.includes(value)) {
      return prevState.filter(string => string !== value);
    } else {
      // Add the payment to the selection
      return [...prevState, value]
    }
  })
}
type ReferredFieldName = 'referral_client' | 'referred_by'

interface ReferredField {
  name: ReferredFieldName;
  title: string;
  placeholder: string;
}

export const EarlyForm: FC = () => {
  const reCaptchaElement = useRef<ReCAPTCHA>(null)
  const [isDisableButton, setIsDisableButton] = useState<boolean>(true)
  const [business, setBusiness] = useState('Private Members Clubs')
  const [selectedProduct, setselectedProduct] = useState<string[]>([]);
  const [showReferredField, setShowReferredField] = useState<ReferredField | undefined>(undefined)
  const { sendEmail, success } = useSurveyApi()
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    website: '',
    company: '',
    role: '',
    country: '',
    timeline: '',
    referral: '',
    referral_client: '',
    referred_by: '',
    leverageMembershipProgram: ''
  }

  function handleCard(id: string): void {
    setBusiness(id)
  }

  const errorMessage = 'This field is required'
  const onSubmit = (formValues: FormValues): void => {
    const product = removeHTMLTags(selectedProduct.join(','))
    sendEmail(11367, {
      82076: `${formValues?.company}`,
      82071: `${formValues?.firstName}`,
      82072: `${formValues?.lastName}`,
      82075: `${formValues?.email}`,
      82074: `${formValues.role}`,
      2070: `${business}`,
      82077: `${formValues?.website}`,
      84662: `${product}`
    })
  }

  const referredFields: ReferredField[] = [
    {
      name: "referral_client",
      title: "Referral Client",
      placeholder: 'Enter referral client'
    },
    {
      name: "referred_by",
      title: "Referred By",
      placeholder: 'Enter referred by'
    },
  ]

  const rolesData = {
    "roles": [
      { "id": 1, "name": "Owner" },
      { "id": 2, "name": "General Manager" },
      { "id": 3, "name": "Operations" },
      { "id": 4, "name": "Memberships" },
      { "id": 5, "name": "Marketing" },
      { "id": 6, "name": "IT" },
      { "id": 7, "name": "Other" }
    ]
  }



  const validationSchema = Yup.object({
    firstName: Yup.string().required(errorMessage),
    lastName: Yup.string().required(errorMessage),
    website: Yup.string().required(errorMessage),
    company: Yup.string().required(errorMessage),
    //  phone: Yup.string().required(errorMessage),
    //country: Yup.string().required(errorMessage),
    //referral: Yup.string().required(errorMessage),
    //timeline: Yup.string().required(errorMessage),
    role: Yup.string().required(errorMessage),
    // leverageMembershipProgram: Yup.string().required(errorMessage),
    email: Yup.string().email('Invalid email format').required(errorMessage),
    //referral_client: showReferredField?.name == 'referral_client' ? Yup.string().required(errorMessage) : Yup.string(),
    //  referred_by: showReferredField?.name == 'referred_by' ? Yup.string().required(errorMessage) : Yup.string()
  })

  const onChangeReferralClient = (value: string) => {
    if (value === "Client Referral") {
      setShowReferredField(referredFields.find(item => item.name === 'referral_client'))
      return;
    }
    if (value === "Word of Mouth") {
      setShowReferredField(referredFields.find(item => item.name === 'referred_by'))
      return;
    }

    setShowReferredField(undefined)
  }

  const onChangeReCaptcha = () => {
    if (reCaptchaElement?.current?.getValue()) {
      setIsDisableButton(false)
    }
  }

  if (success) return <Navigate to={{ pathname: `/success` }} />
  if (success === false) return <Navigate to={{ pathname: '/error' }} />
  return (
    <div className='main-contact' id='capterra-contact' role={'presentation'}>
      <div className='ornament-block'>{patterns}</div>
      <div className='container'>
        <div className='main-contact__wrapper'>
          <h2 className='main-contact__title'>Please complete the form below to be approved to participate in “Early on the vine” Beta Program</h2>
          <div className='main-form-block'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {form => (
                <Form className='main-login-form'>
                  <p className='card-list-heading'>Which Vertical represents your business?</p>
                  <ul className='card-list'>
                    {businesses.map((item: BusinessesTypes) => (
                      <li
                        key={item.id}
                        className={`small-card small-card-${business === item.title ? 'selected' : ''}`}
                        onClick={() => handleCard(item.title)}
                      >
                        <div className='small-card-radio' />
                        <div className='small-card-image'>
                          <img src={item.image} alt='logo' />
                        </div>
                        <div className='small-card-text'>{item.title}</div>
                      </li>
                    ))}
                  </ul>
                  <div className='login-form__wrapper'>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='firstName'>
                            First name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.firstName && form?.touched.firstName && 'login-form__inputfield--error'}`}
                            name='firstName'
                            placeholder='Enter First name'
                          />
                        </div>
                        <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='lastName'>
                            Last name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.lastName && form?.touched.lastName && 'login-form__inputfield--error'}`}
                            name='lastName'
                            placeholder='Enter Last name'
                          />
                        </div>
                        <ErrorMessage name='lastName' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field
                          name={'role'}
                          placeholder='Your role or title'
                          component={SelectField}
                          required={true}
                          options={rolesData.roles.map(it => ({ label: it.name, value: it.name }))}
                        />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='email'>
                            Business email address
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.email && form?.touched.email && 'login-form__inputfield--error'}`}
                            name='email'
                            placeholder='Enter your Email'
                          />
                        </div>
                        <ErrorMessage name='email' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='company'>
                            Company Name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.company && form?.touched.company && 'login-form__inputfield--error'}`}
                            name='company'
                            placeholder='Enter Company Name'
                          />
                        </div>
                        <ErrorMessage name='company' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='website'>
                            Company Website
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.website && form?.touched.website && 'login-form__inputfield--error'}`}
                            name='website'
                            placeholder='Enter Company Website'
                          />
                        </div>
                        <ErrorMessage name='website' component='span' className='login-form__error-message' />
                      </div>
                    </div>

                    <p className='card-list-heading spacer-v2'>What products would you like to test?</p>
                    <ul className='card-list cardlist-v2'>
                      {product.map((item) => (
                        <li
                          key={item.id}
                          className={`small-card small-card-${selectedProduct.includes(item.title) ? 'selected' : ''}`}
                          onClick={() => handleChangeCheckbox(item.title!, setselectedProduct)}
                        >
                          <div className='small-card-radio' />

                          <div className='small-card-text' dangerouslySetInnerHTML={{ __html: item.title }}></div>
                        </li>
                      ))}
                    </ul>
                    {/* <div className='login-form__row-full'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='company'>
                            How are you looking to leverage Peoplevine for your membership program?
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield login-form__textarea 
                        ${form?.errors.leverageMembershipProgram && form?.touched.leverageMembershipProgram && 'login-form__inputfield--error'}`}
                            name='leverageMembershipProgram'
                            placeholder='Enter your answer'
                          />
                        </div>
                        <ErrorMessage name='leverageMembershipProgram' component='span' className='login-form__error-message' />
                      </div>
                    </div> */}
                  </div>
                  {/* <ReCAPTCHA
                    ref={reCaptchaElement}
                    sitekey="6Le-zJgoAAAAAGHvte0r_NEFyeYfU1h8pkoEBoQh"
                    onChange={onChangeReCaptcha}
                  /> */}
                  <div className='login-form__button'>
                    {/* <button type='submit' disabled={isDisableButton}> */}
                    <button type='submit'>
                      Submit For Approval
                      <ArrowIcon />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
