import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { FC, useEffect, useRef } from 'react'

export const Scrollable: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const animationFunc = () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1200px) and (min-height: 940px)': () => {
          gsap.utils.toArray<Element>('[role="presentation"]').forEach(section => {
            ScrollTrigger.create({
              trigger: section,
              start: 'top top',
              pin: true,
              pinSpacing: section.classList.contains('two-sides-platform'),
            })
          })
        },
      })
    }

    window.addEventListener('load', animationFunc)
    return () => {
      window.removeEventListener('load', animationFunc)
    }
  })

  return <div ref={ref}>{children}</div>
}
