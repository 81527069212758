import './Switch.scss'

import { FC, PropsWithChildren } from 'react'

type SwitcherProps = {
  checked?: boolean
}

const Switcher: FC<PropsWithChildren<SwitcherProps>> = ({ checked = false }) => {
  return (
    <div className='switcher'>
      <div data-checked={checked} />
      <label />
    </div>
  )
}

export default Switcher
