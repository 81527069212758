import './Rigths.scss'

import { FC } from 'react'

import DefaultLayout from '../../layouts'

const TermsPage: FC = () => {
  return (
    <DefaultLayout theme={'light'} isHeaderFixed={true} isFooterAnimated={false} isFooterShort={true}>
      <section className='rights'>
        <h1>Terms of use</h1>
        <main>
          <div>
            <p>This Terms of Use Agreement describes the terms and conditions applicable to your access and use of our service.</p>
          </div>
          <div>
            <div>
              <h2>Acceptance</h2>
              <p>
                By using or installing any part of this Application, you expressly agree to, and consent to be bound by, all of the terms of
                this Agreement (as defined below). Please review this Agreement carefully before installation and/or acceptance. reported.
              </p>
            </div>
            <div>
              <h2>DEFINITIONS</h2>
              <p>
                01. &quot;Agreement&quot; is this Master Services Agreement and any other documents that are incorporated herein by
                reference, as may be amended by the Parties in accordance with the requirements herein.
              </p>
              <p>
                02. &quot;Application&quot; means the self-contained program and software provided by PeopleVine (&quot;PeopleVine&quot;)
                which offers access to PeopleVine’ Services and its Users who use the PeopleVine Application (as defined below), to be used
                on mobile devices, Web Site and any upgrades from time to time and any other software or documentation which enables the use
                of the Application. PeopleVine may elect to update the Application at any time. These updates may include bug fixes, feature
                enhancements or improvements, or entirely new versions of the Application. You agree that PeopleVine may automatically
                deliver such updates to you as part of the Services and you shall receive and install them as required.
              </p>
              <p>
                03. &quot;Confidential Information&quot; means all information that is disclosed by one party to the other under this
                Agreement, including the terms of this Agreement, data, tools or methodologies, business information, trade secrets, or any
                other information designated by a party as confidential,excluding information that: (i) is or becomes publicly known,
                through no fault of the receiving party; (ii) was known by the receiving party prior to disclosure hereunder; (iii) is
                disclosed to the receiving party by a third party with no violation of confidentiality to the disclosing party; or (iv) is
                developed by the receiving party independent of any use of information disclosed by the disclosing party. Confidential
                Information shall remain such for three (3) years after expiration or termination of the Agreement.
              </p>
              <p>04. &quot;Customer&quot; means the Person to whom PeopleVine is providing the Services under this Agreement.</p>
              <p>
                05. &quot;Fees&quot; are the charges, prices, and fees set forth in the terms on the Fee Schedule within the Application.
              </p>
              <p>
                06. &quot;Governmental Authority&quot; means the federal government of the United States, any state government, or any
                political subdivision of either, or any agency, court or body of the federal government of the United States, or any state,
                or any political subdivision of either, exercising executive, legislative, judicial, regulatory or administrative functions.
              </p>
              <p>
                07. &quot;Illicit Code&quot; means any program containing malicious or detrimental hidden files, any virus, malware or any
                other malicious computer program, any hardware-limiting, software-limiting or services-limiting function not part of
                standard configuration (including any key, node lock, time-out or other similar functions) or containing any automatically
                replicating, transmitting or activating computer program that is not planned or expected by one of the parties hereto.
              </p>
              <p>
                08. &quot;Intellectual Property Rights&quot; are all patents, patent applications, trademarks, inventions (whether or not
                patentable), know-how, designs, mask works, processes, methodologies, service marks, copyrights and copyrightable works,
                trade secrets including Confidential Information, data,designs, manuals, training materials and documentations, formulas,
                knowledge of manufacturing processes, methods, prices, financial and accounting data, products and product specifications
                and all other intellectual property rights as these terms are understood under Law, including any modifications,adaptations,
                adjustments, enhancements, updates, improvements, alterations and error corrections thereto and other derivative works
                thereof.
              </p>
              <p>
                09. &quot;Law&quot; means with respect to any Party, in each case to the extent applicable to such Party, its property, any
                of the Services or, in connection with this Agreement, any federal, state, county or local law, ordinance, statute, rule,
                regulation, regulatory guidance, code, guideline, policy, notice, treaty, judgment, order, decree, injunction, permit,
                issuance or other determination or finding of any Governmental Authority, or self-regulatory organization.
              </p>
              <p>
                10. &quot;Parties&quot; are both PeopleVine and Customer, and PeopleVine and Customer may be individually referred to as a
                &quot;Party.&quot;
              </p>
              <p>
                11. &quot;Person&quot; means a natural person, sole proprietorship, partnership, firm, corporation, limited liability
                company, joint stock company, business trust or other entity or association, including any Governmental Authority, and where
                the context requires, any of the foregoing when they are acting as trustee, executor, administrator or other legal
                representative.
              </p>
              <p>
                12. &quot;Services&quot; means the provision of access to PeopleVine’ Software, the Application, and the PeopleVine
                Application to Customer and the other services described within this Agreement.
              </p>
              <p>
                13. Software&quot; is the source code and object code versions of any programs, operating system software, computer software
                languages, utilities and other computer programs (i.e., any set of statements or instructions to be used in a computer to
                obtain a result), and related documentation and supporting materials, in any form or media, used to provide the Services,
                including the tangible media upon which the programs, operating system software, computer software languages, utilities and
                other computer programs, and documentation and supporting materials are recorded or printed, together with all corrections,
                improvements, updates and releases.
              </p>
              <p>
                14. &quot;Taxes&quot; are any value-added, country or local sales, use or similar taxes assessed by any taxing authority,
                and any telecommunications excise taxes.
              </p>
              <p>15. &quot;User&quot; is any Person, other than the Customer, who uses the PeopleVine Application.</p>
              <p>
                16. &quot;Work Product&quot;is all information, data, deliverables, materials, documents, documentation,works of authorship,
                computer programs, software, designs, specifications,processes, procedures, techniques and all tangible embodiments of the
                foregoing and all Intellectual Property Rights created, developed or prepared, documented or delivered by PeopleVine, in
                providing the Services.
              </p>
            </div>
            <div>
              <h2>RIGHTS,OBLIGATIONS AND WARRANTIES OF PeopleVine.</h2>
              <p>
                01. Generally. PeopleVine agrees to provide the Services to Customer in a good and workmanlike manner throughout the term of
                this Agreement. The Services may include access and use of the PeopleVine Application, certain hosted solutions, support,
                deliverables,and other items which are described within this Agreement.
              </p>
              <p>
                02. PeopleVine Representations and Warranties. PeopleVine represents and warrants to Customer at all times during the term
                of this Agreement that:
              </p>
              <ul>
                <li>
                  <p>
                    neither it nor any of its employees, agents, officers, directors will disclose, or share any of Customer’s Confidential
                    Information during the term of confidentiality as defined above;
                  </p>
                </li>
                <li>
                  <p>
                    it owns, or has the right to use under valid and enforceable agreements, all Intellectual Property Rights reasonably
                    necessary for and related to the performance of the Services, including its delivery or other provision of any Software
                    to Customer;
                  </p>
                </li>
                <li>
                  <p>that the Services will be provided in good faith and in a professional manner;</p>
                </li>
                <li>
                  <p>no Illicit Code has been or will be introduced into any Software; and</p>
                </li>
                <li>
                  <p>it will provide the Services in compliance with all Laws.</p>
                </li>
              </ul>
              <p>
                03. Support and Maintenance; Additional Services. PeopleVine agrees to provide reasonable updates, improvements,
                modifications or enhancements to the Services as determined by PeopleVine in its sole discretion during the term of this
                Agreement. Updates and feature requests that PeopleVine does not determine to fall within the scope of the ordinary
                maintenance of the Services may be completed as a custom project for Customer, at Customer’s cost, agreed upon by the
                Parties. PeopleVine personnel will promptly respond to Software or PeopleVine Application issues reported by Customer to
                support@PeopleVine.com but in no case later than two (2) business days from the time the issue is reported.
              </p>
              <p>
                04. Disruption of Services. Customer acknowledges and agrees that from time to time the Services and PeopleVine Application
                may be inaccessible or inoperable due to equipment malfunctions, periodic maintenance procedures, repairs or upgrades which
                PeopleVine may undertake from time to time, service malfunctions and causes beyond the reasonable control of PeopleVine or
                which are not reasonably foreseeable by PeopleVine, including, without limitation, interruption or failure of
                telecommunication or digital transmission links, including delays or failures due to Customer’s hardware, Internet service
                provider, hostile network attacks, network congestion or other failures.
              </p>
            </div>
            <div>
              <h2>MUTUAL AGREEMENTS AND WARRANTIES.</h2>
              <p>
                01. Ownership. As between PeopleVine and Customer, Customer’s Confidential Information shall be owned by Customer, and
                PeopleVine’ Confidential Information shall be owned by PeopleVine. PeopleVine owns all right, title, and interest in and to
                all Intellectual Property Rights arising out of all Software, layouts, specifications, data visualizations, screens, report
                designs, images, designs, videos, all other visual and technical arrangements displayed on or in connection with any
                Application, Software or Services and all Work Product, together with all improvements or bug fixes thereto, whether
                improvements were conceived by PeopleVine or conceived, suggested by, or commissioned by Customer or others. For the
                avoidance of doubt, no Services provided hereunder shall be deemed to be &qout;work for hire&qout; services and nothing
                herein shall be construed so as to vest any rights or ownership in Customer to any Application, Software or Services made
                available to Customer hereunder. All data gathered for any Customer data query shall be co-owned by the Parties.
              </p>
              <p>
                02. All experiences, content, downloads, digital items, etc. (&qout;ITEMS&qout;) are provided to you &apos;as is,&apos;
                without warranty of any kind either express or implied. Neither PeopleVine nor its employees, agents, third-party
                information providers, merchants, licensors or the like warrant that the ITEMS or its operation will be accurate, reliable,
                uninterrupted or error-free. No agent or representative has the authority to create any warranty regarding the ITEMS on
                behalf of PeopleVine. PeopleVine reserves the right to change or discontinue at any time any aspect or feature of the ITEMS.
              </p>
              <p>
                03. Mutual Representations and Warranties. Each Party hereby represents, warrants and covenants to the other at all times
                during the term of this Agreement that the execution,delivery and performance of this Agreement by such Party:
              </p>
              <ul>
                <li>
                  <p>has been duly authorized by all necessary corporate action or approval on the part of such Party;</p>
                </li>
                <li>
                  <p>
                    does not conflict with, or otherwise violate the articles of incorporation, articles of organization, bylaws, or
                    operating agreement of such Party;
                  </p>
                </li>
                <li>
                  <p>
                    does not violate the terms of, and shall not (with or without the giving of notice, the lapse of time, or both) result
                    in a breach of, constitute a default under or otherwise give rise to a right of termination by any other party to any
                    material agreement by which such Party is bound;
                  </p>
                </li>
                <li>
                  <p>does not violate any Laws of any Governmental Authority having jurisdiction over such Party; and</p>
                </li>
                <li>
                  <p>constitutes the valid and legally binding obligation of such Party enforceable in accordance with its terms.</p>
                </li>
              </ul>
            </div>
            <div>
              <h2>PAYMENTS</h2>
              <p>
                01. Fees. Fees and discounts, if any, for Services will be determined as set forth on the Fee Schedule within the
                Application. Customer agrees to pay to PeopleVine all Fees due under this Agreement within thirty (30) days of receiving an
                invoice from PeopleVine. All payments shall be paid in United States currency and may be made through credit card, check or
                wire transfer, in each case to the order or credit of &quot;PeopleVine&quot; and remitted to 222 Merchandise Mart Suite
                1212, Chicago, IL 60654. PeopleVine reserves the right to change the payment address and instructions at its discretion in
                the future.
              </p>
              <p>
                02. Overdue Accounts. PeopleVine may charge a late fee on delinquent payments at the lesser of (i) the maximum amount
                chargeable by law or (ii) one and one-half percent (1½%) per month on the unpaid balance due from Customer. Failure to
                timely pay invoices may cause interruption of Service, in the discretion of PeopleVine. Dispute of apportion of Customer’s
                invoice(s) does not suspend or limit Customer’s obligation to pay the undisputed portion. If Customer fails to make any
                payment within fifteen (15) days after the due date, PeopleVine reserves the right to immediately terminate the term of the
                Agreement and to suspend the Services to Customer, and in such event the Customer’s payment and other obligations hereunder
                shall survive such termination. Customer will review invoices promptly and will identify any disputed items or seek any
                credit or adjustment which Customer claims, in writing, not later than sixty (60) days following the receipt of each
                invoice.
              </p>
              <p>
                03. Taxes. Except to the extent otherwise provided in this Agreement, each Party agrees to be responsible for its own Taxes
                applicable under all Laws, including Taxes based on a Party’s income, unemployment, social security and other payroll and
                wage Taxes. Customer will pay all sales or services Taxes due on the transactions hereunder or provide PeopleVine customary
                proof that the transactions are exempt from such Taxes.
              </p>
            </div>
            <div>
              <h2>WARRANTIES, DISCLAIMERS, INDEMNIFICATION.</h2>
              <p>
                01. Limited Warranty; Disclaimer of Warranties. Except for the limited warranties set forth in this Agreement, THE SERVICES
                ARE PROVIDED ‘AS IS’ WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
                PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TIMELINESS OF DATA TRANSMISSION, ACCURACY OF DATA OR DATA
                SETS, OR UPTIME AVAILABILITY. PeopleVine DOES NOT WARRANT THAT THE SOFTWARE OR THE SERVICES WILL BE FREE FROM ALL BUGS,
                ERRORS, OR OMISSIONS. PeopleVine SPECIFICALLY DISCLAIMS ANY WARRANTY AS TO THE ACCURACY OF ANY THIRD-PARTY DATA, AND
                CUSTOMER ACKNOWLEDGES THAT SUCH THIRD-PARTY DATA IS OUTSIDE OF PeopleVine’ CONTROL. My HubPass DOES NOT WARRANT THAT THE
                SERVICES WILL ACCOMPLISH ANY SPECIFIC OBJECTIVE OF CUSTOMER OR WILL OPERATE ERROR FREE. CUSTOMER ACKNOWLEDGES AND AGREES
                THAT IT HAS RELIED ON NO WARRANTIES OTHER THAN THE LIMITED, EXPRESS WARRANTIES OF THIS AGREEMENT. THIS DISCLAIMER AND
                EXCLUSION SHALL APPLY EVEN IF THE LIMITED, EXPRESS WARRANTIES SET FORTH HEREIN FAIL THEIR ESSENTIAL PURPOSE. CUSTOMER
                FURTHER AGREES THAT NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR OTHERWISE, CUSTOMER WILL NOT HOLD
                PeopleVine LIABLE FOR ANY FAILURE OF THE SOFTWARE OR SERVICES. CUSTOMER AGREES THAT IT WILL NOT HOLD PeopleVine LIABLE FOR
                ANY THIRD-PARTY INFORMATION EVEN IF SUCH INFORMATION IS DISPLAYED ON, THROUGH, OR IN CONNECTION WITH THE SERVICES OR
                SOFTWARE. CUSTOMER AGREES THAT PeopleVine SHALL NOT BE RESPONSIBLE TO CUSTOMER FOR ANY DAMAGES CAUSED BY DATA PROVIDED BY
                THIRD PARTIES, DELAYS RESULTING FROM HARDWARE AND SYSTEMS OWNED AND CONTROLLED BY THIRD PARTIES (INCLUDING WITHOUT
                LIMITATION THE CUSTOMER’S OWN DATA TRANSMISSION SPEEDS), DATA ENTRY ERRORS, USER ERRORS, OR ANY OTHER LIMITATIONS, ERRORS,
                OR DELAYS, THAT ARISE DURING THE TERM OF THIS AGREEMENT THAT CANNOT BE PREVENTED OR MITIGATED BY PeopleVine.
              </p>
              <p>
                02. Limitation of Liability. WITH THE EXCEPTION OF AMOUNTS ARISING OUT OF THE OBLIGATIONS IN SECTIONS 7.3 AND 7.4, IN NO
                EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY, OR TO ANY THIRD PARTY FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL,
                INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SERVICES, EVEN IF A PARTY HAS BEEN ADVISED,
                KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. CUSTOMER’S EXCLUSIVE REMEDY FOR BREACH BY PeopleVine OF ANY
                LIMITED WARRANTY CONTAINED HEREIN SHALL BE TO DISCONTINUE USE OF THE SERVICES AND TERMINATE THE TERM OF THIS AGREEMENT. TO
                THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, A PARTY’S LIABILITY UNDER THIS AGREEMENT SHALL NOT EXCEED THE AGGREGATE
                DOLLAR AMOUNT PAID BY CUSTOMER TO PeopleVine IN THE TWELVE(12) MONTHS PRIOR TO THE CLAIMED INJURY OR DAMAGE (EXCEPT WITH
                RESPECT TO CUSTOMER’S PAYMENT OBLIGATIONS UNDER SECTION 6 OF THIS AGREEMENT WHICH SHALL NOT BE LIMITED BY THIS SECTION).
                MULTIPLE CLAIMS WILL NOT EXPAND THIS LIMITATION. IN JURISDICTIONS THAT DO NOT ALLOW THE LIMITATION OR EXCLUSION OF IMPLIED
                WARRANTIES OR LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, THE LIMITATIONS CONTAINED IN SECTIONS 7.1, AND 7.2 WILL
                ONLY APPLY TO THE EXTENT THAT APPLICABLE LAW PERMITS SUCH LIMITATIONS AND THOSE SECTIONS WILL NOT IN SUCH CASE AFFECT THE
                VALIDITY OR ENFORCEABILITY OF THE REMAINING TERMS OF THIS AGREEMENT.
              </p>
              <p>
                03. Mutual Indemnification. Each Party agrees to defend, indemnify and hold harmless the other party, its affiliates,
                employees, officers, agents, licensors and permitted successors and assigns from all damages and liability including,
                without limitation, reasonable attorneys’ fees, incurred as a result of such Party’s violation of its obligations under this
                Agreement, or the grossly negligent or willful acts of such Party. Each Party’s obligation to indemnify under this provision
                shall survive termination of the term of this Agreement.
              </p>
              <p>
                04. PeopleVine Indemnification. PeopleVine shall defend or settle any claim, suit, or action against Customer based on an
                allegation that the Services or Software infringes any third party’s patent or copyright. In defending or settling any
                infringement claim, PeopleVine may, at its sole option and expense (i) procure the right to continue offering the Services
                or Software, (ii) modify the Services or Software so that they are non infringing, (iii) procure a replacement product that
                has substantially the same functionality, or (iv) if none of the above options is reasonably available, terminate the term
                of this Agreement and Customer’s right to use the Services or Software hereunder. Notwithstanding the foregoing, PeopleVine’
                indemnification obligation shall not apply unless Customer delivers to PeopleVine written notice of a claim or threatened
                claim promptly after Customer has notice or knowledge of such claim or threat and PeopleVine is given the opportunity to
                control the defense of such action. Additionally, PeopleVine’ indemnification obligation shall not apply if the Services or
                Software involved have been altered in any way by Customer or others and such infringement or claimed infringement would not
                have been alleged but for the alteration. THIS SECTION STATES PeopleVine’S ENTIRE LIABILITY AND CUSTOMER’S EXCLUSIVE REMEDY
                WITH RESPECT TO ANY CLAIM, SUIT, OR ACTION ALLEGING INFRINGEMENT OF ANY THIRD-PARTY PATENT OR COPYRIGHT.
              </p>
              <p>
                05. Customer Indemnification. Customer agrees to defend, indemnify and hold harmless PeopleVine, its affiliates, employees,
                officers, agents, managers, members and successors and assigns from all damages and liability such Persons may incur,
                including, without limitation, reasonable attorneys’ fees, arising from or as a result of(i) Customer’s, or its employees’,
                agents’, or a third party’s use (whether or not such use is approved) of the Services or Software under the Customer’s
                account, or (ii) any violation of Law by the Customer, its employees, or its agents.
              </p>
            </div>
            <div>
              <h2>TERMINATION</h2>
              <p>
                01. Term. The Term of this Agreement will commence on the Effective Date and will continue on a month to month basis until
                terminated by either Party upon written notice to the other.
              </p>
              <p>
                02. Termination for Default. Except as provided in Section 4.2, in the event that either Party materially defaults in the
                performance of any of its duties or obligations under this Agreement and does not substantially cure such default, or
                commence a cure,within thirty (30) days after being given written notice specifying the default, the non-defaulting Party
                may, by giving written notice thereof to the defaulting Party, immediately terminate the term of this Agreement. Except as
                provided in this Section 8, termination of the term by PeopleVine for Customer’s payment or other breach does not relieve
                the Customer from its obligations, including payment obligations, pursuant to this Agreement.
              </p>
              <p>
                03. Bankruptcy; Insolvency. If either Party becomes or is declared insolvent or bankrupt, admits in writing of its inability
                to pay its debts generally as they become due, is the subject of any proceedings relating to its liquidation or insolvency
                or for the appointment of a receiver for it, makes an assignment for the benefit of all or substantially all of its
                creditors, or enters into an agreement for the composition, extension, or readjustment of all or substantially all of its
                obligations, then the other Party may, by giving written notice thereof to such Party, terminate the term of this Agreement
                as of a date specified in such notice of termination.
              </p>
              <p>
                04. Effect of Termination. Upon termination of the term of this Agreement, (i) any rights or licenses granted to Customer
                under this Agreement shall be immediately terminated without further notice or action by PeopleVine, and (ii) each Party
                will return to the other Party within five (5) business days after receipt of the termination notice all Confidential
                Information of the other Party and all copies thereof or, at the option of the owner of the Confidential Information,will
                destroy the Confidential Information and provide to the other Party certificates evidencing the destruction.
              </p>
            </div>
            <div>
              <h2>GENERAL PROVISIONS.</h2>
              <p>
                01.Notice. Any notice required or permitted to be given hereunder shall be in writing and be delivered by overnight mail
                courier, facsimile, U.S. Mail (certified; properly addressed; postage prepaid) or hand delivery, and will be deemed to have
                been delivered upon receipt or on the second business day following the date the notice is sent if it is not accepted by the
                addressee. The addresses for such communication shall be at the address first stated in this Agreement or at such other
                address as may be given by either Party to the other in writing.
              </p>
              <p>
                02. Independent Contractors.Each Party is an independent contractor and the parties shall not have the authority to bind,
                represent or commit the other to any agreement or contract without the express written permission of the other, in advance.
                Nothing in this Agreement shall be deemed or construed to create a joint venture, partnership or agency relationship between
                the Parties.
              </p>
              <p>
                03. Entire Agreement; Amendment. This Agreement constitutes the entire agreement between PeopleVine and Customer with
                respect to the subject matter hereof, and replaces, amends and supersedes any prior agreements or oral discussions or
                negotiations between PeopleVine and Customer pertaining to the subject matter hereof. No amendment, change, waiver,or
                discharge hereof shall be valid unless in writing and signed by both Parties.
              </p>
              <p>
                04. Governing Law. This Agreement will be governed and construed under the laws of the State of Illinois without regard to
                conflict of laws rules.
              </p>
              <p>
                05. Waiver/Severability. The waiver or failure of either Party to exercise any right in any respect provided for herein
                shall not be deemed a waiver of any further right hereunder. If any provision of this Agreement is determined to be invalid
                or unenforceable under any applicable statute or rule of law, it is to that extent to be deemed omitted or limited to the
                fullest extent permitted by Law, and the balance of the Agreement shall remain enforceable.
              </p>
              <p>
                06. Force Measure. Except for the payments due by a Party to the other and the Confidentiality and Intellectual Property
                provisions herein, if the performance of any part of this Agreement is prevented, hindered, delayed or otherwise made
                impracticable by reason of any cause or event not within the reasonable control of such Party and without its fault or
                negligence, that Party shall be excused from such to the extent that it is prevented, hindered or delayed by such causes.
              </p>
              <p>
                07. Representation of Counsel; Mutual Negotiation. The language of this Agreement will in all cases be construed simply, as
                a whole and in accordance with its fair meaning and not strictly for or against any Party. The Parties agree that this
                Agreement has been prepared jointly and has been the subject of arm’s length and careful negotiation. Each Party has been
                given the opportunity to independently review this Agreement with legal counsel and other consultants, and each Party has
                the requisite experience and sophistication to understand, interpret and agree to the particular language of the provisions.
                Accordingly, notwithstanding the general rules of construction, each Party agrees that in the event of an ambiguity in or
                dispute regarding the interpretation of this Agreement, the drafting of the language of this Agreement will not be
                attributed to either Party.
              </p>
              <p>
                08. Assignment and Transfer; Delegation. Customer may not assign, without the prior written consent of PeopleVine, its
                rights, duties or obligations under this Agreement to any person or entity, in whole or in part. Upon written notice to
                Customer, PeopleVine may delegate any portion of its duties or obligations under this Agreement to any third party that it
                deems, in its sole discretion, qualified to perform such duties and obligations.
              </p>
              <p>
                09. Counterparts. This Agreement may be executed in counterparts, each of which shall constitute an original, and all of
                which shall constitute one and the same instrument.
              </p>
            </div>
          </div>
        </main>
      </section>
    </DefaultLayout>
  )
}

export default TermsPage
