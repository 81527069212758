import { FC } from 'react'
import DefaultLayout from 'src/layouts'
import Hero from 'src/sections/helpPageSections/Hero/Hero'

const HelpPage: FC = () => {
  return (
    <DefaultLayout isHeaderFixed={true} isFooterAnimated={false}>
      <Hero />
    </DefaultLayout>
  )
}

export default HelpPage
