import 'src/components/Form/Button.scss'

import { FC, ReactText, SVGProps } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from 'src/assets/icons/button-arrow.svg'

type ButtonProps = Pick<LinkProps, 'target' | 'onClickCapture' | 'to' | 'className' | 'onClick'> & {
  width: ReactText
  ghost: boolean
  icon: FC<SVGProps<SVGSVGElement>>
}

const Button: FC<Partial<ButtonProps>> = ({ width, className, icon, onClickCapture, onClick, to = '', ...props }) => {
  const Icon = icon ?? (() => <ArrowIcon />)

  return (
    <Link
      {...props}
      to={to}
      onClickCapture={onClickCapture}
      onClick={onClick}
      className={`button ${className ?? ''} ${icon && !props.children ? 'icon' : ''}`}
      style={{ width }}
    >
      {props.children}
      <Icon />
    </Link>
  )
}

export default Button
