import { ClientProps } from 'src/pages/Capterra/crm/Capterra'
import './TickerImagesClientBar.scss'
import { useContent } from '../App/Content'
import { FC } from 'react'


const TickerImagesClientBar: FC = () => {

  const clients = useContent('client-ticker')
  const images = clients?.cards || []

  const getImagesComponents = (client: ClientProps) => {

    const name = client?.title?.trim().toLowerCase().replace(/ /g, '-')

    return (
      <img
        key={name} src={client?.media?.url} alt={client?.title || ''}
        className={`image__${name}`}
      />
    )
  }

  return (
    <div className="ticker-container">
      <div className="ticker-wrapper img-ticker">
        {images?.map(client => (getImagesComponents(client as ClientProps)))}
        {images?.map(client => (getImagesComponents(client as ClientProps)))}
        {images?.map(client => (getImagesComponents(client as ClientProps)))}
      </div>
    </div>
  )
}

export default TickerImagesClientBar