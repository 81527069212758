import './Drawer.scss'

import Dialog from 'rc-dialog'
import { IDialogPropTypes } from 'rc-dialog/lib/IDialogPropTypes'
import { FC } from 'react'

type DrawerProps = Pick<IDialogPropTypes, 'title' | 'visible' | 'onClose'> & {
  subtitle: string
}

const Drawer: FC<Partial<DrawerProps>> = ({ title, subtitle, children, ...props }) => {
  return (
    <Dialog {...props} title={undefined} className='ppv-modal'>
      <div className='drawer__heading'>
        {title && <h2 className='title'>{title}</h2>}
        {subtitle && <p>{subtitle}</p>}
      </div>
      {children}
    </Dialog>
  )
}

export default Drawer
