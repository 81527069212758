import './Post.scss'

import { FC, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import BackLink from 'src/components/BackLink/BackLink'

import { PostProps } from '../Blog/Blog'

function readingTime(content: string) {
  const wordsPerMinute = 225
  const words = content.trim().split(/\s+/).length
  return Math.ceil(words / wordsPerMinute)
}

const Post: FC<PostProps> = ({ content = '', ...props }) => {
  const timeToRead = readingTime(content)
  useEffect(() => {
    document
      .querySelector('.markdown')
      ?.querySelectorAll('a')
      .forEach(item => (item.target = '_blank'))
  })
  return (
    <div className='post'>
      <BackLink />
      <div className='post__wrapper'>
        <h1>{props.title}</h1>
        <div className='post__inner'>
          {props.author?.map((item, index) => (
            <div className='post__header' key={index}>
              <div className='author'>
                {item.image && (
                  <div className='author__image'>
                    <img src={item.image} alt={item.name} className='image' />
                  </div>
                )}
                <div className='author__description'>
                  <p className='author__name'>{item.name}</p>
                  {item.position && <p className='author__position'>{item.position}</p>}
                </div>
              </div>
              <div className='post__details'>
                <p>{timeToRead} mins read</p>
                {props.source && (
                  <a href={props.link} target='_blank' rel='noreferrer' className='source'>
                    Source: {props.source}
                  </a>
                )}
              </div>
            </div>
          ))}
          <div className='post__content'>
            <ReactMarkdown className={'markdown'}>{content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
