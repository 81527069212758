import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useMetaQuery } from '../../graphql'

type MetaProps = { name: string; content: string }

const defaultValue: ContextProps = { title: 'Peoplevine | The Premier Membership Platform', pathname: '/', meta: [] }
type ContextProps = Partial<{ title: string; pathname: string; meta: MetaProps[] }>

const Context = createContext<ContextProps>(defaultValue)

function mergeMetaNames(...metas: MetaProps[][]): MetaProps[] {
  return Object.values(
    metas
      .flat(1)
      ?.map((it, index, self) => {
        const override = self.find(i => i?.name !== it?.name)
        return { name: `${it?.name ?? override?.name ?? ''}`, content: it?.content ?? override?.content ?? '' }
      })
      ?.reduce((all, { name, ...props }) => {
        /**
         * This function required just because of regular expression in Strapi for enumerated names do not allow using dashes and semicolons in names
         */
        const normalizeMetaName = (name: string) => name.replace(/-/g, '_').replace(/:/g, '_')

        return { ...all, [name]: { ...props, name: normalizeMetaName(name) } }
      }, {}),
  )
}

type SeoProviderProps = { value: ContextProps; setValue: Dispatch<SetStateAction<ContextProps>> }
const SeoProvider: FC<PropsWithChildren<Pick<SeoProviderProps, 'value'>>> = ({ children, value: initialValue = defaultValue }) => {
  return (
    <Context.Provider value={initialValue}>
      <Helmet title={initialValue.title} defaultTitle={initialValue.title} meta={initialValue.meta} async />
      {children}
    </Context.Provider>
  )
}

const useSeo: (value: ContextProps) => SeoProviderProps = initialValue => {
  const [value, setValue] = useState(initialValue)
  const { data, loading } = useMetaQuery({
    variables: {
      where: { pathname_in: [...new Set([initialValue.pathname, defaultValue.pathname])] },
    },
  })

  useEffect(() => {
    data?.metas &&
      setValue(
        prevState =>
          ({
            ...prevState,
            ...data.metas?.[0],
            ...data.metas?.[1],
            meta: mergeMetaNames(prevState?.meta ?? [], data?.metas?.[0]?.meta as MetaProps[], data?.metas?.[1]?.meta as MetaProps[]),
          } as ContextProps),
      )
  }, [data?.metas, setValue])
  if (loading && !value) return { value: defaultValue, setValue: () => undefined }
  return { value, setValue }
}
export { SeoProvider, useSeo }

function withSeo<T = PropsWithChildren<ReactNode>>(Wrapped: FC<T>): FC<T> {
  return props => {
    const { pathname } = useLocation()
    return (
      <SeoProvider value={{ pathname }}>
        <Wrapped {...props}>{props.children}</Wrapped>
      </SeoProvider>
    )
  }
}

export default withSeo
