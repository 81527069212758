import 'src/components/Footer/Footer.scss'

import { FC } from 'react'
import stars from 'src/assets/icons/stars.png'
import { ReactComponent as LogoIcon } from 'src/components/App/images/logo-icon.svg'
import { ReactComponent as LogoText } from 'src/components/App/images/text-icon.svg'
import Menu from 'src/components/Menu'
import { useFooterContent } from '../App/Footer'
import { SOCIAL_MEDIA } from '../constants'
import Linkedin from 'src/assets/icons/socials/LinkedinIcon'

type FooterType = {
  isFooterAnimated: boolean
  isShort: boolean
  footerContent: boolean
  isFooterHasMenu: boolean
}

const Copyright: FC = () => (
  <div className='footer-secondary-block'>
    <div className='footer-built-by'>
      <h5 className='footer-built-by__title capture'>Founded in Chicago</h5>
      <div className='footer-stars-box'>
        <img src={stars} alt='stars' className='footer-stars-box__image' />
      </div>
    </div>
  </div>
)

export const Footer: FC<FooterType> = ({ isFooterAnimated, isShort, isFooterHasMenu, footerContent }) => {
  const footerCont = useFooterContent();
  const currentYear = new Date().getFullYear();
  return (
    <footer role={`${isFooterAnimated ? 'presentation' : ''}`} className={`footer ${isShort ? 'footer--short' : ''}`}>
      <div className='container'>
        <div className='footer__wrapper'>
          {isShort ? (
            <></>
          ) : (
            <div className='footer-logo'>
              <a href='/' className='footer-logo__link'>
                <LogoIcon className='footer-logo__icon' />
                <LogoText className='footer-logo__icon' />
              </a>
            </div>
          )}
          <div className='footer-main-block'>
            <div className='footer-main-block__item'>
              {isShort ? (
                <div className='footer-privacy'>
                  <a
                    href={'https://peoplevine.com/privacy-policy'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    className='footer-privacy__link'
                  >
                    Privacy policy
                  </a>
                  <a
                    href={'https://peoplevine.com/terms-of-use'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    className='footer-privacy__item'
                  >
                    {footerCont?.[0]?.copyrights}
                  </a>
                </div>
              ) : (
                <>
                  {isFooterHasMenu ? (
                    <nav className='footer-navigation'>
                      <Menu prefixCls={'footer'} />
                    </nav>
                  ) : (
                    <a href='https://peoplevine.com/' rel={'noreferrer'} target='_blank' className='footer-navigation-list__link'>
                      MAIN WEBSITE
                    </a>
                  )}
                </>
              )}
            </div>
            <div className='footer-main-block__item'>
              {isShort ? (
                <Copyright />
              ) : (
                <div className='footer-company-info'>
                  <h5 className='footer-company-info__title'>{footerCont?.[0]?.contactsTitle}</h5>
                  <a href={`tel:${footerCont?.[0]?.phone}`} className='footer-company-info__phone'>
                    {footerCont?.[0]?.phone}
                  </a>
                  <a href={`footerCont?.[0]?.Address_map_link`} target='_blank' rel='noreferrer' className='footer-company-info__address'>
                    {footerCont?.[0]?.address}
                  </a>
                  <a href={`mailto:${footerCont?.[0]?.emails?.[0]?.email}`} className='footer-company-info__email'>
                    {footerCont?.[0]?.emails?.[0]?.email}
                  </a>
                </div>
              )}
            </div>
            {!footerContent ? (
              <div className='footer-main-block__item' />
            ) : (
              <div className='footer-main-block__item'>
                <ul className='footer-socials-list'>
                  {SOCIAL_MEDIA.map((item, index) => (
                    <li className='footer-socials-list__item' key={index}>
                      <a href={`${item.url}`} target='_blank' rel='noreferrer' className='footer-socials-list__link'>
                        {item.icon}
                      </a>
                    </li>
                  ))}
                </ul>
                {isShort ? (
                  <></>
                ) : (
                  <div className='footer-privacy'>
                    <a
                      href={'https://peoplevine.com/privacy-policy'}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                      className='footer-privacy__link'
                    >
                      {footerCont?.[0]?.privacy?.title}
                    </a>
                    <a
                      href={`footerCont?.[0]?.Privacy?.Title`}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                      className='footer-privacy__item'
                    >
                      {footerCont?.[0]?.copyrights}
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
          {!isShort && <Copyright />}
        </div>
      </div>
    </footer>
  )
}
