import { useState } from 'react'
import auth from 'src/data/api-credentials.json'

const mailURL = `https://api.peoplevine.com/survey.asmx/answerSurvey`

type ScalarValue = string | number | boolean

type PayloadData = { [key: number]: ScalarValue }

type State = {
  success: boolean | undefined
  requestSent: boolean
  error: string | undefined
  sendEmail: (id: number, data: PayloadData, request?: RequestInit) => void
}

type SurveyPayload = {
  survey_no: number
  survey_field_no: number
  survey_answer: ScalarValue
}[]

/**
 * Wraps request body into Survey API entity and sends POST request to add email into queue
 * @param id Survey ID created in CRM with required mail template
 */
export function useSurveyApi(): State {
  const [success, setSuccess] = useState<boolean>()
  const [error, setError] = useState<string>()
  const [requestSent, setRequestSent] = useState(false)

  const sendEmail = (id: number, data: PayloadData, options?: RequestInit) => {
    const answers: SurveyPayload = []

    Object.entries(data).forEach(([key, value]) =>
      answers.push({
        survey_no: id,
        survey_field_no: Number.parseInt(key),
        survey_answer: value,
      }),
    )

    const payload = { answers, auth }

    const request: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      ...options,
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`Sending email with payload: ${request.body}`)
      setSuccess(true)
      setError('Stub error text')
    } else {
      answers.length > 0 &&
        fetch(mailURL, request)
          .then(response => setSuccess(response.ok))
          .catch(reason => {
            console.log(reason)
            setError(reason.toString())
          })
    }
    return setRequestSent(true)
  }

  return { sendEmail, success, error, requestSent }
}
