import { useContent } from 'src/components/App/Content'
import './ExclusiveBenefitsSection.scss'
import { patternGenerator } from 'src/components/Patterns/patternsGenerator'

interface ExclusiveBenefitsSectionProps {
    slug: string
}


const ExclusiveBenefitsSection = ({
    slug
}: ExclusiveBenefitsSectionProps) => {
    const patterns = patternGenerator({ quantity: 6, theme: 'dark' })
    const exclusiveBenefitsContent = useContent(slug)

    return (
        <section className="exclusive-benefits-section">
            <div className="container">
                <div className="exclusive-benefits_wrapper">
                    <h1 className="exclusive-benefits_title">{exclusiveBenefitsContent?.title}</h1>
                    <div className="exclusive-benefits_steps-wrapper">
                        {exclusiveBenefitsContent?.cards?.map( card => (
                            <div className="exclusive-benefits_step">
                                <p>{card?.title}</p>
                                <p>{card?.subTitle}</p>
                            </div>
                        ))}
                    </div>
                    <p className="exclusive-benefits_description">{exclusiveBenefitsContent?.description}</p>
                </div>
            </div>
        </section>
    )
}

export default ExclusiveBenefitsSection