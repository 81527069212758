import { useMemo } from 'react'

import { ContentFragment, useContentQuery } from '../../graphql'
export type ContentProps = { data: ContentFragment }
/**
 * Returns single content block by slug
 * @param {ContentFragment} slug
 */
function useContent(slug: string): ContentFragment | null | undefined {
  const { loading, data } = useContentQuery()
  const content = useMemo(() => (!loading && data?.contentBlocks ? data.contentBlocks : []), [loading, data])
  return content.find(it => it?.slug === slug)
}

export { useContent }
