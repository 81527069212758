import './Hero.scss'

import { FC } from 'react'
import heroImg from 'src/assets/images/common/help-hero.png'

import Button from '../../../components/Form/Button'
import { useContent } from '../../../components/App/Content'

const Hero: FC = () => {
  const content = useContent('peoplevine-help')

  return (
    <section className='help-hero'>
      <div className='container'>
        <div className='wrapper'>
          <div className='item'>
            <div className='item-wrapper'>
              <div className={'block'}>
                <h1 className={'main-title'}>{content?.title}</h1>
                <p>{content?.description}</p>
                <Button to={'/help/support'}>Go to support center</Button>
              </div>
              <div className={`block`}>
                <img src={heroImg} alt='hero-image' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
