import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { FC, ReactNode, useEffect } from 'react'
import { ReactComponent as Baia } from 'src/assets/images/clients/baia.svg'
import { ReactComponent as Core } from 'src/assets/images/clients/core.svg'
import { ReactComponent as McGregor } from 'src/assets/images/clients/mcgregorsquare.svg'
import { ReactComponent as Mhub } from 'src/assets/images/clients/mhub.svg'
import { ReactComponent as Nocatee } from 'src/assets/images/clients/nocatee.svg'
import { ReactComponent as ZeroBond } from 'src/assets/images/clients/zeroBond.svg'
import crm2 from 'src/assets/images/crm-preview/integrated-platforms.png'
import crm1 from 'src/assets/images/crm-preview/people-list.png'
import slide1 from 'src/assets/images/crm-preview/people-list.png'
import { ReactComponent as Auth } from 'src/assets/images/partners/authorize_net.svg'
import { ReactComponent as Braintree } from 'src/assets/images/partners/braintree.svg'
import { ReactComponent as Cardconnect } from 'src/assets/images/partners/cardconnect.svg'
import { ReactComponent as Facebook } from 'src/assets/images/partners/facebook.svg'
import { ReactComponent as Aloha } from 'src/assets/images/partners/ncr-aloha.svg'
import { ReactComponent as Paypal } from 'src/assets/images/partners/paypal.svg'
import { ReactComponent as Square } from 'src/assets/images/partners/square.svg'
import { ReactComponent as Stripe } from 'src/assets/images/partners/stripe.svg'
import { ReactComponent as Twitter } from 'src/assets/images/partners/twitter.svg'
import slide2 from 'src/assets/images/people/persons (7).png'
import Hero from 'src/sections/capterra/Hero'
import Resources from 'src/sections/capterra/Resources'
import { Touchpoints } from 'src/sections/capterra/Touchpoints'
import { TwoSidesPlatformSection } from 'src/sections/capterra/TwoSidesPlatformSection'
import { ClientsSection } from 'src/sections/commonSections/ClientsSection/ClientsSection'
import { FeedbackSection } from 'src/sections/homePageSections/FeedbackSection/FeedbackSection'
import { ServicesSection } from 'src/sections/homePageSections/ServicesSection/ServicesSection'
import membersPattern1 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-1.png'
import membersPattern2 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-2.png'
import membersPattern3 from 'src/sections/productPageSections/ProductItemSection/images/patterns/members-pattern-3.png'
import { CustomerCase } from '../../../sections/capterra/CustomerCase'
import { IClientType } from '../../../sections/clientsPageSections/ClientTypeSection/ClientTypeSection'
import { client } from '../membershipManagement/Capterra'
import { benefits, resourcesData } from '../hospitalityProperty/Capterra'
import { Pricing } from '../../../sections/capterra/Pricing'
import { ContactForm } from '../../../sections/capterra/ContactForm'
import { Maybe, SectionFragment } from '../../../graphql'

export const product = {
  title: 'end-to-End Membership Management',
  about: 'PeopleVine allows you to manage and engage with your member base while creating a self-service experience, so your staff can focus on growth.',
  list: [
    {
      image: crm1,
      list: [
        {
          title: 'A Single Dashboard',
          items: ['Application processing and onboarding', 'Member self-service tools', 'Branded member portal', 'Searchable member directory'],
        },
        {
          title: 'Member Interaction Tracking',
          items: ['Triggered, automated outreach', 'Email and SMS newsletters', 'Affiliate program tracking', 'Multi-location support'],
        },
      ],
    },
    {
      image: crm2,
      list: [
        {
          title: 'Integrated Payment and Accounting',
          items: ['Automated subscription payments', 'Billing and receipt management', 'Cardless payments', 'Membership dues processing'],
        },
        {
          title: 'Automated Marketing',
          items: ['Drip and date-based campaigns', 'Automatic triggers', 'Chatbots on Twitter and SMS', 'Third-party integrations and APIs'],
        },
      ],
    },
  ],
  themeColor: 'black',
  additionalClass: 'members',
  key: 'management',
  patterns: [
    {
      image: membersPattern1,
      styles: {
        top: '10%',
        right: '0',
      },
    },
    {
      image: membersPattern2,
      styles: {
        top: '15%',
        left: '5%',
      },
    },
    {
      image: membersPattern3,
      styles: {
        top: '35%',
        left: '0',
      },
    },
  ],
  index: 1,
}

export type ClientProps = Partial<SectionFragment> & Partial<SectionFragment> & { image: ReactNode }

export const clients: ClientProps[] = [
  {
    title: 'Private club',
    image: <ZeroBond />,
  },
  {
    title: 'Boutique Hotel',
    image: <Baia />,
  },
  {
    title: 'Luxury Residential',
    image: <Nocatee />,
  },
  {
    title: 'Coworking ',
    image: <Mhub />,
  },
  {
    title: 'PRIVATE CLUB',
    image: <Core />,
  },
  {
    title: 'Hospitality',
    image: <McGregor />,
  },
]

const heroSlides = [
  {
    title: 'One place for all member data',
    subtitle:
      'View your member’s engagements, responses and transactions in one place. Segment members based on preferences, spend and engagement activity (touchpoints) to create targeted and personalized campaigns.',
    img: slide1,
  },
  {
    title: 'The premier membership platform',
    subtitle: 'Designed from the ground up for lifestyle hospitality businesses to create elevated member experiences and build forever transactions.',
    img: slide2,
  },
]

export const integrations = [
  {
    image: <Auth />,
  },
  {
    image: <Braintree />,
  },
  {
    image: <Cardconnect />,
  },
  {
    image: <Stripe />,
  },
  {
    image: <Aloha />,
  },
  {
    image: <Square />,
  },
  {
    image: <Paypal />,
  },
  {
    image: <Twitter />,
  },
  {
    image: <Facebook />,
  },
]

const Capterra: FC = () => {
  useEffect(() => {
    const twoSidesAnimation = () => {
      ScrollTrigger.matchMedia({
        '(min-width: 1200px)': () => {
          gsap.to('.two-sides-platform-part--dark', {
            scrollTrigger: {
              trigger: '.two-sides-platform-part--dark',
              start: '15% 90%',
              end: '70% 30%',
              scrub: 1,
            },
            ease: 'none',
            clipPath: 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
          })
        },
      })
    }

    window.addEventListener('load', () => twoSidesAnimation())
    return () => {
      window.removeEventListener('load', () => twoSidesAnimation())
    }
  })

  return (
    <section className='capterra'>
      <Hero slides={heroSlides} />
      <ClientsSection theme={'light'} isHomePage={true} hasSeeMore={false} hasReadMore={false} clients={clients} />
      <ServicesSection theme={'grey'} titleMaxWidth={950} title={'CRM, Member Experience, Management Platform'} />
      <Touchpoints />
      <TwoSidesPlatformSection hasList={true} />
      <Resources theme={'light'} title={'key ops team benefits'} data={resourcesData} />
      <Resources theme={'light'} title={'key member\nbenefits'} data={benefits} />
      <ClientsSection title={'Integrations'} theme={'grey'} centered={true} isHomePage={true} hasSeeMore={false} hasReadMore={false} clients={integrations} />
      <Pricing />
      <CustomerCase theme={'light'} data={client as IClientType} />
      <FeedbackSection hasTitle={true} theme={'grey'} />
      <ContactForm />
    </section>
  )
}

export default Capterra
