import { useContent } from 'src/components/App/Content'
import './RequirementsSection.scss'

interface RequirementsSectionProps {
  slug: string;
}

const RequirementsSection = ({
  slug
}: RequirementsSectionProps) => {

  const requirementSectionContent = useContent(slug)

  return (
    <section className="requirements-section">
      <div className="container">
        <h1 className="requirements-section_title">{requirementSectionContent?.title}</h1>
        <div className="requirements-section_items-wrapper">
          {requirementSectionContent?.cards?.map(card => (
            <div className="requirements-section_item">
              <h3 className="requirements-section_item-title">{card?.title}</h3>
              <div className="requirements-section_item-image">
                <img src={card?.media?.url} alt={card?.title || ''} />
              </div>
              <p className="requirements-section_item-text">{card?.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default RequirementsSection