import { FC } from 'react'
import { Link, Outlet } from 'react-router-dom'

import withSeo from '../components/Seo'
import { DefaultLayout } from '.'

const BlogLayout: FC = () => (
  <DefaultLayout
    isHeaderFixed={true}
    isHeaderIndependent={false}
    isFooter={true}
    hasSignup={true}
    isHeaderShort={true}
    isFooterAnimated={false}
    isFooterShort={true}
    hasVideoBanner={false}
    customLogo={
      <div className="header-panel-button header-panel-button-left">
        <Link to={'https://rsvp.clients.peoplevine.com/membership/1257/3406'} className="login-button-rsvp" target='_blank'>
          JOIN RSVP
          {/* <ExitIcon /> */}
        </Link>
      </div>
    }
  >
    <Outlet />
  </DefaultLayout>
)

export { BlogLayout }
