import 'src/components/NavMenu/NavMenu.scss'

import { FC, HTMLProps } from 'react'
import { ReactComponent as FacebookIcon } from 'src/assets/icons/socials/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/icons/socials/instagram-icon.svg'
import { ReactComponent as LinkedinIcon } from 'src/assets/icons/socials/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from 'src/assets/icons/socials/twitter-icon.svg'
import { ReactComponent as VimeoIcon } from 'src/assets/icons/socials/vimeo-icon.svg'
import Menu from 'src/components/Menu'

import contacts from '../../data/contacts.json'
import Buttons from '../../components/button'

type NavMenuProps = {
  renderSocials: boolean
}

export const NavMenu: FC<HTMLProps<Element> & NavMenuProps> = props => {
  return (
    <div className='nav-menu'>
      <div className='container'>
        <div className='nav-menu__wrapper'>
          <div className='nav-menu-block'>
            <div className='nav-menu-block__item'>
              <nav className='navigation'>
                <Menu />
              </nav>
            </div>
            <div className='nav-menu-block__item'>
              <div className='nav-menu-block-contacts'>
                <h5 className='nav-menu-block-contacts__title capture'>Contacts</h5>
                <a href={`tel:${contacts.phone}`} className='nav-menu-block-contacts__phone'>
                  {contacts.phone}
                </a>
                <a href={contacts.address.mapLink} rel='noreferrer' target='_blank' className='nav-menu-block-contacts__address'>
                  {contacts.address.text}
                </a>
                <a href={`mailto:${contacts.emails.info}`} className='nav-menu-block-contacts__email'>
                  {contacts.emails.info}
                </a>
              </div>
              <Buttons.RequestDemo />
            </div>
          </div>
          {props.renderSocials && (
            <div className='nav-menu-socials'>
              <a href='https://www.instagram.com/peoplevine' rel='noreferrer' target='_blank' className='nav-menu-socials__link'>
                <InstagramIcon />
              </a>
              <a href='https://www.linkedin.com/company/peoplevine/' rel='noreferrer' target='_blank' className='nav-menu-socials__link'>
                <LinkedinIcon />
              </a>
              <a href='https://www.facebook.com/PeopleVine/' rel='noreferrer' target='_blank' className='nav-menu-socials__link'>
                <FacebookIcon />
              </a>
              {/* <a href='https://twitter.com/peoplevine' rel='noreferrer' target='_blank' className='nav-menu-socials__link'>
                <TwitterIcon />
              </a> */}
              <a href='https://vimeo.com/peoplevine' rel='noreferrer' target='_blank' className='nav-menu-socials__link'>
                <VimeoIcon />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
