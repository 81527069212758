import 'src/sections/productPageSections/DoYouIntegrateSection/DoYouIntegrateSection.scss'

import { FC } from 'react'

import Buttons from '../../../components/button'

type DoYouIntegrateSectionProps = {
  text?: string
}

export const DoYouIntegrateSection: FC<DoYouIntegrateSectionProps> = props => {
  const text = props?.text ?? 'Do you integrate with'

  return (
    <div className='do-you-integrate'>
      <div className='container'>
        <div className='do-you-integrate__wrapper'>
          <div className='do-you-integrate-block'>
            <h2 className='do-you-integrate-block__title main-title'>
              {text}
              <span className='do-you-integrate-block__title-linebreak'>______ ?</span>
            </h2>
          </div>
          <div className='do-you-integrate-block'>
            <Buttons.RequestDemo title={'Submit a request'} />
          </div>
        </div>
      </div>
    </div>
  )
}
