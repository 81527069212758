import './App.scss'

import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { createContext, FC, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom'
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop'
import BlogPage from 'src/pages/BlogPage/BlogPage'
import CapterraExperience from 'src/pages/Capterra/clubExperience/Capterra'
import CapterraCRM from 'src/pages/Capterra/crm/Capterra'
import CapterraHospitality from 'src/pages/Capterra/hospitalityProperty/Capterra'
import CapterraMembership from 'src/pages/Capterra/membershipManagement/Capterra'
import ClientsPage from 'src/pages/ClientsPage/ClientsPage'
import DeveloperPage from 'src/pages/DeveloperPage/DeveloperPage'
import FormPage from 'src/pages/FormPage/FormPage'
import HelpPage from 'src/pages/HelpPage/HelpPage'
import HomePage from 'src/pages/HomePage/HomePage'
import PostPage from 'src/pages/PostPage/PostPage'
import Leadership from 'src/pages/PostPage/posts/Leadership'
import PricingPage from 'src/pages/PricingPage/PricingPage'
import ProductPage from 'src/pages/ProductPage/ProductPage'
import PrivacyPage from 'src/pages/RightsPage/PrivacyPage'
import TermsPage from 'src/pages/RightsPage/TermsPage'
import StatusPage from 'src/pages/StatusPage/StatusPage'
import TeamPage from 'src/pages/TeamPage/TeamPage'
import routeMap from 'src/route-map.json'
import { PostProps } from 'src/sections/blogPageSections/Blog/Blog'

import { usePostsQuery } from '../../graphql'

export const MenuContext = createContext({
  onClick: (): void => undefined,
})

function Post() {
  const { id } = useParams()
  const { data, loading, error } = usePostsQuery({ variables: { where: { slug: id } } })
  if (!loading && (error?.message || data?.posts?.length === 0)) return <StatusPage.NotFound />
  const post = data?.posts?.[0]

  const props: PostProps = {
    id: `${post?.id}`,
    author:
      post?.authors?.map(it => ({
        position: it?.occupation?.name,
        image: it?.photo?.url,
        name: `${it?.firstName} ${it?.lastName}`,
      })) ?? [],
    category: `${post?.category?.name}`,
    content: `${post?.text}`,
    link: post?.source?.url,
    source: post?.source?.text,
    preview: `${post?.previews?.url}`,
    title: `${post?.name}`,
  }

  return post && post.slug.startsWith('membership-is-the-future') ? <Leadership {...props} /> : <PostPage {...props} />
}

/**
 * Used to serve all legacy and existing link redirects as of https://peoplevine.com
 * @constructor
 */
function StaticRouteMap(): JSX.Element {
  const { pathname, search } = useLocation()
  const redirectUrl = (routeMap as { [key: string]: string })[pathname] ?? `https://blog.peoplevine.com/${pathname.concat(search)}`
  useEffect(() => window.location.replace(redirectUrl.endsWith(search) ? redirectUrl : redirectUrl.concat(search)))
  return redirectUrl ? <StatusPage.Redirect url={redirectUrl} /> : <StatusPage.NotFound />
}

function useApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: (import.meta.env.WEBSITE_API_URL ?? `https://${window.location.host.includes('stage') ? 'stage.' : ''}cms.peoplevine.com/graphql`) as string,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  })
}

export const App: FC = () => {
  const client = useMemo(useApolloClient, [])

  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route element={<Outlet />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/form' element={<FormPage />} />
            <Route path='/product' element={<ProductPage />} />
            <Route path='/clients' element={<ClientsPage />} />
            <Route path='/pricing' element={<PricingPage />} />
            <Route path='/team' element={<TeamPage />} />
            <Route path='/rsvp' element={<BlogPage />} />
            <Route path='/developers-resources' element={<DeveloperPage />} />
            <Route path='/help' element={<HelpPage />} />
            <Route path='/rsvp/:id' element={<Post />} />
            <Route path='/terms-of-use' element={<TermsPage />} />
            <Route path='/privacy-policy' element={<PrivacyPage />} />
            <Route path={'/capterra/powerful-crm-for-member-based-lifestyle-hospitality-businesses'} element={<CapterraCRM />} />
            <Route path={'/capterra/powerful-membership-management'} element={<CapterraMembership />} />
            <Route path={'/capterra/powerful-club-management'} element={<CapterraExperience />} />
            <Route path={'/capterra/powerful-member-experience-platform-for-hotels-and-resorts'} element={<CapterraHospitality />} />
            <Route path={'/success'} element={<StatusPage.Success />} />
            <Route path={'/success/:slug'} element={<StatusPage.Success />} />
            <Route path={'/error'} element={<StatusPage.Error />} />
            <Route path={'/*'} element={<StaticRouteMap />} />
          </Route>
        </Routes>
      </Router>
    </ApolloProvider>
  )
}
