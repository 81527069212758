import { Products } from "src/hooks/products"
import { ProductItemSection } from "src/sections/productPageSections/ProductItemSection/ProductItemSection"

export const ProductsSection = () => {
    const products = Products()
    return (
        <div className='product-core-block'>
            <div className='product-core-block__wrapper'>
                {products.map((product, index) => {
                    return <ProductItemSection key={product.id} index={index} {...product} />
                })}
            </div>
        </div>
    )
}
