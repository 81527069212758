import { FC } from 'react'
import { TeamMembersSection } from 'src/sections/teamPageSections/TeamMembersSection/TeamMembersSection'
import { TeamStorySection } from 'src/sections/teamPageSections/TeamStorySection/TeamStorySection'

import { useContent } from '../../components/App/Content'
import { DefaultLayout } from '../../layouts'

const TeamPage: FC = () => {
  const teamStoryData = useContent('team-story')
  const teamMembersData = useContent('team-members')
  if (!(teamStoryData && teamMembersData)) return null
  return (
    <DefaultLayout>
      <TeamStorySection data={teamStoryData} />
      <TeamMembersSection data={teamMembersData} />
    </DefaultLayout>
  )
}

export default TeamPage
