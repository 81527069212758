import './Rigths.scss'

import { FC } from 'react'

import { DefaultLayout } from '../../layouts'

const PrivacyPage: FC = () => {
  return (
    <DefaultLayout theme={'light'} isHeaderFixed={true} isFooterAnimated={false} isFooterShort={true}>
      <section className='rights'>
        <h1>Privacy Policy</h1>
        <main>
          <div>
            <p>
              Peoplevine makes the same firm commitment to privacy for our Web site visitors as for our customers using our web-hosted
              service. We created this Online Privacy Policy (“Privacy Policy”) to give you confidence as you visit and use the Peoplevine
              Web site, located at peoplevine.com (the “Web site”) and to demonstrate our commitment to the protection of your privacy. This
              Privacy Policy is only applicable to the Web site, and not to any other Web sites that you may be able to access from the Web
              site, each of which may have data collection and use practices and policies that differ materially from this Privacy Policy.
              By using the Peoplevine Web site, you are agreeing to the terms of the Privacy Policy.
            </p>
          </div>
          <div>
            <div>
              <h2>Notification of Privacy Policy Changes</h2>
              <p>
                We reserve the right to change the Privacy Policy at any time. If we decide to revise the Privacy Policy, we will post a
                notice at least thirty days prior to making any material changes. If we make material changes to this policy, we will notify
                you here, by email, or by means of a notice on our home page. Your continued use of the Web site, including use after the
                posting of any changes to these terms, will be deemed acceptance by you of the Privacy Policy.
              </p>
            </div>
            <div>
              <h2>Notice Concerning Children</h2>
              <p>
                PLEASE NOTE: We are a general audience site, and do not direct any of our content specifically at children under 13 years of
                age. We understand and are committed to respecting the sensitive nature of children’s privacy online. If we learn or have
                reason to suspect that a Peoplevine Web site visitor is under age 13, we will promptly delete any personal information
                regarding that visitor.
              </p>
            </div>
            <div>
              <h2>What Basic Information Does Peoplevine Collect?</h2>
              <p>
                You can visit the Web site to read information about our company, products, and services without telling us who you are and
                without revealing any personal information. In operating the Web site, Peoplevine includes optional information request
                forms such as White Paper or Webinar Request forms, Newsletter subscription forms, and general contact request forms that
                request your name, company, title, phone number, email address, URL, address and other information to help us determine your
                needs related to our products and solutions and in order for you to download or view documents or other information through
                the Peoplevine Web site. We use this information to send updates about our company and to contact you if requested or
                necessary. Peoplevine also collects billing information such as credits and eCheck data via our partner Paypal. Depending
                upon your request, Peoplevine may also ask for additional information, such as how you heard about us. This information is
                provided by you on a voluntary basis only and is not required by us in order for you to use and enjoy the Web site.
                Peoplevine uses the information that we collect on the Web site to contact you to further discuss your interest in our
                company, our services, and to send information regarding our company or partners, such as marketing promotions and events.
                This information may also be used to improve the services we provide you. We use this information to improve the way that we
                provide our services to our clients, to relay administrative information, and to verify login information. The information
                is collected and stored in a manner that is appropriate for the nature of the data that we collect, and the need to fulfill
                your request. Peoplevine does not share, sell, rent, or trade PII with third parties for their promotional purposes.
              </p>
            </div>
            <div>
              <h2>Tell-A-Friend</h2>
              <p>
                If you choose to use our referral service to tell a friend about our site, we will ask you for your friend’s name and email
                address. We will automatically send your friend a one-time email inviting him or her to visit the site. PeopleVine stores
                this information for the sole purpose of sending this one-time email and tracking the success of our referral program.
              </p>
            </div>
            <div>
              <h2>PeopleVine Clients</h2>
              <p>
                If you are a client of PeopleVine (or work on behalf of a PeopleVine client), through our secure web interface we also
                collect your username and password, and any other information that you provide to us on behalf of the client that you are
                representing pursuant to the terms of a license or other agreement between the applicable client and PeopleVine (the “Client
                Agreement”). You should refer to that Client Agreement in addition to this Privacy Policy with regard to your use of the
                PeopleVine Web site and PeopleVine’s privacy obligations.
              </p>
            </div>
            <div>
              <h2>Automatic Collection</h2>
              <p>
                Our servers automatically recognize visitors’ IP addresses (the number assigned to computers on the Internet), domain names,
                the activity of the visitor’s browser when the visitor is viewing the Web site; the site path of visitors; the time visitors
                come to the Web site and length of visit. No personal information about you is revealed in the process. The Web site may
                also gather anonymous traffic data that does not personally identify you, but that may be helpful for marketing purposes or
                for improving the services we offer and PeopleVine reserves the right to publish such anonymous, aggregate summary
                information regarding its visitors for promotional purposes.
              </p>
              <p>
                PeopleVine uses &quot;cookies&quot; and &quot;web beacons&quot; to collect data. A cookie is a small text file containing a
                random and unique identifier that either PeopleVine or its third party web analytics provider on behalf of PeopleVine
                transfer to your computer’s hard drive through your web browser (unless you set your browser to reject cookies) that enables
                PeopleVine or its third party web analytics provider’s system to recognize your browser. PeopleVine uses cookies to track
                your movement through the Web site and verify the login status of client using products or services linked directly within
                the PeopleVine Web site. Cookies are also used to track point of entry to point of registration for those users
                participating in our affiliate signup programs or to track and measure the success of a particular marketing campaign. Web
                beacons (also known as clear gifs) are typically a transparent graphic image (usually 1 pixel x 1 pixel) that is placed on a
                site and used by PeopleVine and its third party web analytics provider in combination with cookies to help us understand how
                you with the PeopleVine Web site. We use web beacons to measure visitor behavior, to help us improve visitor experience, and
                to manage site content.
              </p>
              <p>
                You may opt out of receiving future mailings; see the Updating and Choice/Opt-out section below. When you receive an updated
                profile form, that information comes from our database.
              </p>
            </div>
            <div>
              <h2>Cookies</h2>
              <p>
                We use cookies to try and give you a better experience. Our privacy policy details how we use these cookies. If you
                don&apos;t like cookies, you can disable them altogether too. Please note that our service relies heavily on these cookies,
                and some portions of our site may not work properly if you disable cookies. Cookies allow us, among other things, to store
                your preferences and settings; enable you to sign-in; provide interest-based advertising; combat fraud; and analyze how our
                services are performing.
              </p>
              <p>
                By continuing to use our site, you agree to the way we use cookies and are ok with it as described in our Privacy Policy,
                unless you choose to disable them altogether through your browser. You have a variety of tools to control cookies, web
                beacons and similar technologies, including browser controls to block and delete cookies and controls from some third-party
                analytics service providers to opt out of data collection through web beacons. Your browser and other choices may impact
                your experiences with our services.
              </p>
            </div>
            <div>
              <h2>Security and Privacy</h2>
              <p>
                PeopleVines’ web site uses security measures to help protect against the loss, misuse, and alteration of the information
                under our control. We store the information in two redundant databases in two secure environments at different locations
                with our data hosting service provider, Savvies Communications.
              </p>
              <p>
                PeopleVine takes care to reinforce the importance of our web site visitors’ security and privacy among our employees.
                PeopleVine requires unique usernames and passwords that must be entered each time a user logs on to PeopleVine’s client
                interface. These safeguards help prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of all
                data. The bottom line is that, because our business involves hosting application accounts, data security is paramount. We’re
                constantly reevaluating and deploying new technologies to enhance our security.
              </p>
              <p>
                In order to accommodate changes in our business, we may sell or buy portions of our company or other companies or assets. In
                such transactions, visitor information may be one of the transferred assets. In the event that all or substantially all of
                PeopleVine’s assets are acquired in a transaction, our visitor information may be an asset that is reviewed and transferred.
                Such a transaction will be disclosed per the &quot;Notification of Privacy Policy Changes&quot; guidelines above. Also, as
                with the data we collect on behalf of our clients, we reserve the right to disclose any data we collect at our Web site if
                required by law or valid order of a court or other governmental authority or to protect the health and safety of
                PeopleVine’s employees or the general public.
              </p>
              <p>
                PeopleVine is not responsible for the privacy practices or the content of any other web sites to which we link. We encourage
                our visitors to read the privacy statements of each and every web site that they visit.
              </p>
              <p>
                The security of your personal information is important to us. When you enter sensitive information (such as credit card
                number and/or social security number) on our registration or order forms, we encrypt that information using secure socket
                layer technology (SSL). We follow generally accepted industry standards to protect the personal information submitted to us,
                both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic
                storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personal
                information, we cannot guarantee its absolute security.
              </p>
            </div>
            <div>
              <h2>Updating and Choice/Opt-Out</h2>
              <p>
                Visitors who request information on our web site have the following options for reviewing, modifying and deleting any
                personally identifiable information that has previously been provided to PeopleVine, as well as updating their profiles and
                unsubscribing (opting out) to avoid receiving future communications from us:
              </p>
              <p>
                Submit a ticket to our support department. We’ll reply within 30 days of receiving your request confirming any corrections
                or updates requested.
              </p>
              <p>
                If you are subscribed to receive newsletter or commercial emails from us, you may send us a request to unsubscribe from
                receiving such emails at any time by following the unsubscribe instructions within the email. We will comply with your
                unsubscribe request within 24 hours after receipt of such request (or shorter time period, if required by law).
              </p>
            </div>
            <div>
              <h2>Notifications and One-Time E-mails</h2>
              <p>
                Upon successfully submitting information to us such as making a purchase, booking a reservation, entering a sweepstakes,
                completing a survey or any other related action where you voluntarily provide us information, we reserve the right to send
                you an e-mail notification (either a single message or a finite campaign) pertaining to that event where no opt-out is
                required.
              </p>
            </div>
            <div>
              <h2>Blog disclosure</h2>
              <p>
                If you use a bulletin board, blog, or chat room on this Web site, you should be aware that any personally identifiable
                information you submit there can be read, collected, or used by other users of these forums, and could be used to send you
                unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these
                forums.
              </p>
            </div>
            <div>
              <h2>Testimonials</h2>
              <p>
                We post customer testimonials on our web site which may contain personally identifiable information such as the
                customer&apos;s name. We do obtain the customer&apos;s consent prior to posting the testimonial to post their name along
                with their testimonial.
              </p>
            </div>
            <div>
              <h2>Links to Other Sites</h2>
              <p>
                This Web site contains links to other sites that are not owned or controlled by PeopleVine Please be aware that we,
                PeopleVine, are not responsible for the privacy practices of such other sites. We encourage you to be aware when you leave
                our site and to read the privacy statements of each and every Web site that collects personally identifiable information.
                This privacy statement applies only to information collected by this Web site. PeopleVine owns and operates some websites
                that have the same look and feel of PeopleVine.com. Please be aware that this privacy statement only covers PeopleVine.com.
              </p>
            </div>
            <div>
              <h2>Legal disclosure</h2>
              <p>
                We reserve the right to disclose your personally identifiable information as required by law and when we believe that
                disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served
                on our Web site.
              </p>
            </div>
            <div>
              <h2>Contacting Us</h2>
              <p>
                If you have any questions about this privacy statement or this web site, please contact us directly at
                peoplevine.com/contact
              </p>
            </div>
          </div>
        </main>
      </section>
    </DefaultLayout>
  )
}

export default PrivacyPage
