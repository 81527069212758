import 'src/sections/teamPageSections/TeamStorySection/TeamStorySection.scss'

import { FC } from 'react'
import teamStoryImage from 'src/assets/images/common/picture (14).png'

import { ContentProps } from '../../../components/App/Content'

export const TeamStorySection: FC<ContentProps> = ({ data }) => {
  return (
    <section className='team-story' role={'presentation'}>
      <div className='container'>
        <div className='team-story__wrapper'>
          <div className='team-story-block'>
            <h1 className='team-story-block__title main-title'>{data?.cards?.[0]?.title}</h1>
            {data?.cards?.[0]?.text?.split('/')?.map(item => (
              <p className={'team-story-block__text'} key={item}>
                {item}
              </p>
            ))}
          </div>
          <div className='team-story-block'>
            <div className='team-story-block__picture'>
              <img src={teamStoryImage} alt='image' className='team-story-block__image' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
