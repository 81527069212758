import { FC, useEffect, useRef, useState } from 'react'
import './VideoElevateDiningSection.scss'

interface VideoElevateDiningSectionProps {
    urlVideo?: string;
    width?: number;
    height?: number;
    coverImage?: string;
}

const VideoElevateDiningSection: FC<VideoElevateDiningSectionProps> = ({
    urlVideo,
    width = 750,
    height = 500,
    coverImage,
}) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

    console.log({ coverImage, isVideoLoaded })
    return (
        <section className="video-elevate-dining-section">
            <img className="cover-image" src={coverImage} alt="Elevate Dining" />
            {urlVideo && (
                <video
                    width={width}
                    height={height}
                    autoPlay
                    muted
                    playsInline
                    loop
                    onLoadedData={() => setIsVideoLoaded(true)}
                >
                    <source src={urlVideo} type="video/mp4" />
                </video>
            )}
            <div className="scroll-arrow">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
    );
}

export default VideoElevateDiningSection
