import { ReactNode } from "react";
import FacebookIcon from "src/assets/icons/socials/FacebookIcon";
import InstagramIcon from "src/assets/icons/socials/InstagramIcon";
import LinkedinIcon from "src/assets/icons/socials/LinkedinIcon";
import TwitterIcon from "src/assets/icons/socials/TwitterIcon";
import VimeoIcon from "src/assets/icons/socials/VimeoIcon";
import YoutubeIcon from "src/assets/icons/socials/YoutubeIcon";

type SocialMedia = {
    name: string;
    icon: ReactNode;
    url: string;
}

export const SOCIAL_MEDIA: SocialMedia[] = [
    {
        name: "Instagram",
        icon: <InstagramIcon />,
        url: "https://www.instagram.com/peoplevine"
    },
    {
        name: "LinkedIn",
        icon: <LinkedinIcon />,
        url: "https://www.linkedin.com/company/peoplevine"
    },
    {
        name: "Vimeo",
        icon: <VimeoIcon />,
        url: "https://vimeo.com/peoplevine"
    },
    {
        name: "Facebook",
        icon: <FacebookIcon />,
        url: "https://www.facebook.com/PeopleVine"
    },
    {
        name: "Youtube",
        icon: <YoutubeIcon />,
        url: "https://www.youtube.com/@peoplevine"
    },
    {
        name: "Twitter",
        icon: <TwitterIcon />,
        url: "https://twitter.com/peoplevine"
    }
]