import './Resources.scss'

import { FC } from 'react'

import platformPattern1 from './images/platform-pattern-2.png'
import platformPattern2 from './images/platform-pattern-2.png'

export type ResourcesProps = {
  title: string
  data: {
    title?: string
    subtitle?: string
    image?: string
  }[]
  theme?: 'light' | 'grey' | 'dark'
}

const Resources: FC<ResourcesProps> = ({ theme = 'grey', title, data }) => {
  return (
    <section className={`resources-capterra resources-capterra-${theme}`} id={title.toLowerCase().trim().split(' ').join('-')}>
      <div className='ornament-block'>
        <img src={platformPattern1} alt='ornament' className='ornament-block__image' />
        <img src={platformPattern2} alt='ornament' className='ornament-block__image' />
      </div>
      <div className='container'>
        <div className='resources__wrapper'>
          <h2 className='resources__title section-main-title'>{title}</h2>
          <ul className='resources-list four-items'>
            {data.map(item => (
              <li key={item.subtitle}>
                {item.title && <h3>{item.title}</h3>}
                {item.image && (
                  <div className='platforms-list-item__picture'>
                    <img src={item.image} alt='platform-logo' className='platforms-list-item__image' />
                  </div>
                )}
                {item.subtitle && <p>{item.subtitle}</p>}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Resources
