import { useParams } from 'react-router'
import { PostFragment, usePostsQuery } from '../../graphql'
import StatusPage from '../StatusPage/StatusPage'
import { PostProps } from '../../sections/blogPageSections/Blog/Blog'
import Leadership from '../PostPage/posts/Leadership'
import PostPage from '../PostPage/PostPage'
import { FC, useEffect, useState } from 'react'
import { usePost, usePosts } from 'src/components/App/Posts'
import { Loader } from 'src/components/Loader/Loader'

const BlogPostPage: FC = () => {
  const { id } = useParams()
  const { data, loading, error } = usePostsQuery({ variables: { where: { slug: id } } })
  if (id?.startsWith('membership-is-the-future')) return <Leadership />
  const post = data?.posts?.[0]

  const props: PostProps = {
    id: `${post?.id}`,
    author:
      post?.authors?.map(it => ({
        position: it?.occupation?.name,
        image: it?.photo?.url,
        name: `${it?.firstName} ${it?.lastName}`,
      })) ?? [],
    category: `${post?.category?.name}`,
    content: `${post?.text}`,
    link: post?.source?.url,
    source: post?.source?.text,
    preview: post?.previews?.url,
    meta: post?.meta ?? [],
    title: `${post?.name}`,
  }
  if (!loading && (error?.message || data?.posts?.length === 0)) return <StatusPage.NotFound />
  return <PostPage {...props} />
}

export { BlogPostPage as default }
