import './Hero.scss'

import { FC } from 'react'
import heroImg from 'src/assets/images/common/developers-hero.jpg'

import Button from '../../../components/Form/Button'
import { useContent } from '../../../components/App/Content'

const Hero: FC = () => {
  const content = useContent('developers-page')
  return (
    <section className='developers-hero'>
      <div className='container'>
        <div className='wrapper'>
          <div className='item'>
            <div className='item-wrapper'>
              <div className={'block'}>
                <h1 className={'main-title'}>{content?.cards?.[0]?.title}</h1>
                <p>{content?.cards?.[0]?.subTitle}</p>
                <Button width={275} target={'_blank'} to={'/developer/register'}>
                  {content?.cards?.[0]?.subDescription}
                </Button>
              </div>

              <div className={`block`}>
                <img src={heroImg} alt='hero-image' />
              </div>
            </div>
          </div>
          <div className='item support-tools'>
            <h3>Support tools</h3>
            <ul>
              <li>for designers</li>
              <li>for developers</li>
              <li>for clients</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
