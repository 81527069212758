import 'src/sections/homePageSections/FeatureSection/FeatureSection.scss'

import { FC } from 'react'
import crmScale from 'src/assets/images/crm-preview/people-list.png'
import crmTransact from 'src/assets/images/crm-preview/dining-left.png'
import crmEngage from 'src/assets/images/crm-preview/events-right.png'
import crmIntegrate from 'src/assets/images/crm-preview/integrated-platforms.png'
import crmEnroll from 'src/assets/images/crm-preview/membership-app-mobile.png'
import crmAnalyze from 'src/assets/images/crm-preview/subscriptions.png'
import opsTeamsFirstPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/ops-teams-first-pattern-1.png'
import opsTeamsFirstPattern2 from 'src/sections/homePageSections/FeatureSection/images/patterns/ops-teams-first-pattern-3.png'
import opsTeamsSecondPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/ops-teams-second-pattern-2.png'
import opsTeamsSecondPattern2 from 'src/sections/homePageSections/FeatureSection/images/patterns/ops-teams-second-pattern-3.png'
import opsTeamsThirdPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/ops-teams-third-pattern-2.png'
import portalFirstPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-first-pattern-1.png'
import portalFirstPattern2 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-first-pattern-2.png'
import portalFirstPattern3 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-first-pattern-4.png'
import portalSecondPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-second-pattern-2.png'
import portalSecondPattern2 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-second-pattern-3.png'
import portalThirdPattern1 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-third-pattern-1.png'
import portalThirdPattern2 from 'src/sections/homePageSections/FeatureSection/images/patterns/portal-third-pattern-2.png'
import PlatformsList from './components/PlatformsList'

interface IFeature {
  title?: string | undefined | null
  image?: string | undefined
  about?: string | undefined
  additionalClass: string | null | undefined
  pattern1?: string
  pattern2?: string
  pattern3?: string
  list: {
    listItemTitle: string | null | undefined
    listItemAbout: string | null | undefined
  }[]
  backgroundColor?: string | null | undefined
  titleColor?: string
  textColor?: string
  index: number
  customComponent?: any
}

const opsTeamsFeatures = [
  {
    title: 'Scale',
    image: `${crmScale}`,
    additionalClass: 'ops-teams',
    pattern1: opsTeamsFirstPattern1,
    pattern2: opsTeamsFirstPattern2,
    about:
      'Increase operational efficiency and elevate your hospitality through a collection of digital touchpoints that appeal to your entire membership base.',
    list: [
      {
        listItemTitle: 'CRM',
        listItemAbout:
          'The central point of truth for understanding your members. Get the full scope of how your members engage and transact with your business.',
      },
      {
        listItemTitle: 'Billings',
        listItemAbout:
          'Automatically bill members on a recurring basis and sync all transactions to QuickBooks Online or other third-party platforms for accounting and reconciliation.',
      },
      {
        listItemTitle: 'Reservations',
        listItemAbout: 'Offer amenities and services for members to reserve. Set up automated text and email reminders.',
      },
      {
        listItemTitle: 'Events',
        listItemAbout:
          'Bring members together. Create a list of events for members to discover and attend. Set pricing based on membership tiers. Send confirmation and reminders via automated SMS and email.',
      },
      {
        listItemTitle: 'Dining',
        listItemAbout:
          'Members can reserve a table from their portal or app. They can also place contactless orders from their mobile app for pick-up, delivery, or tableside dining.',
      },
      {
        listItemTitle: 'Perks',
        listItemAbout: 'Surprise and delight members with perks and privileges based on their level of membership.',
      },
      // {
      //   listItemTitle: 'sell',
      //   listItemAbout: 'Sell products in a branded online shop and increase your bottom line.',
      // },
      {
        listItemTitle: 'Check-in',
        listItemAbout:
          'Make your members’ presence known. Check in members with their digital ID or by name, phone number, or ID number. Communicate their arrival through chits to the BoH and SMS texts to staff all while updates are sent to Sevenrooms.',
      },
    ],
    backgroundColor: '#fff',
    titleColor: '#2f3034',
    textColor: '#81848e',
  },
  {
    title: 'Integrate',
    // image: `${crmIntegrate}`,
    customComponent: <PlatformsList />,
    pattern1: opsTeamsSecondPattern1,
    pattern2: opsTeamsSecondPattern2,
    additionalClass: 'ops-teams',
    about:
      'Play well with others. Unlike other CRM platforms, PeopleVine is innately equipped for third-party integrations so you can consolidate your business efforts and see a holistic view of your members  across all your systems',
    list: [
      {
        listItemTitle: 'point of sale (POS)',
        listItemAbout:
          'We integrate with multiple modern POS systems that enable real-time syncing of transaction data and tie back to members’ CRM profiles.',
      },
      {
        listItemTitle: 'Member & Guest Check-in',
        listItemAbout:
          'Make your members’ presence known. Check in members with our POX app using their digital wallet ID or by name, phone number, or ID number. Upon member arrival, send SMS text to select staff and chit with member’s preferences to the host.',
      },
      {
        listItemTitle: 'Property Management Systems (PMS)',
        listItemAbout:
          'Make room booking available via the member portal and mobile app. Apply member discounts, and sync all bookings to their CRM profile so you can personalize future hospitality.',
      },
      {
        listItemTitle: 'Reservations Systems',
        listItemAbout: 'Reap the holistic benefits of PeopleVine’s dynamic CRM synced to Sevenrooms’ best-in-class table management.',
      },
      {
        listItemTitle: 'Payment Processors',
        listItemAbout:
          'Integrate with any of these payment processors to ensure all billing and payment flow directly to your bank account.',
      },
      {
        listItemTitle: 'Access Control',
        listItemAbout:
          'Auto enroll members into keycard access and track all scan in activity. Turn access on and off based on members’ active status.',
      },
    ],
    backgroundColor: '#fff',
    titleColor: '#2f3034',
    textColor: '#81848e',
  },
  {
    title: 'Analyze',
    image: `${crmAnalyze}`,
    pattern1: opsTeamsThirdPattern1,
    additionalClass: 'ops-teams',
    about:
      'Know your members like never before. Track member demographics, preferences and touchpoints.Build informed marketing campaigns and identify opportunities to further personalise the engagement',
    list: [
      {
        listItemTitle: 'Reporting',
        listItemAbout: 'Generate reports on member activity and spending  to gain insight into your P&L and inform your marketing strategy',
      },
      {
        listItemTitle: 'Segmentation',
        listItemAbout:
          'See members by cohort (new, cancelled, upgrades), demographics, and spend to create moments to surprise and delight',
      },
    ],
    backgroundColor: '#fff',
    titleColor: '#2f3034',
    textColor: '#81848e',
  },
]

const MemberPortalSection: FC = () => {
  // Getting content from strapi 14.12.2021 vb


  const memberPortalFeatures = [
    {
      title: 'Enroll',
      image: `${crmEnroll}`,
      additionalClass: 'portal',
      pattern1: portalFirstPattern1,
      pattern2: portalFirstPattern2,
      pattern3: portalFirstPattern3,
      about: 'Meet your members. Capture prospective member information, collect payment details, and manage the application process.',
      list: [
        {
          listItemTitle: 'Market',
          listItemAbout: 'Invite prospects with email campaigns and landing pages that make it easy to apply.',
        },
        {
          listItemTitle: 'Apply',
          listItemAbout: 'Create your membership application and collect personal and payment info on prospective members.',
        },
        {
          listItemTitle: 'Approve',
          listItemAbout:
            'Manage the application process and design automated emails to communicate with applicants at each stage of the approval process.',
        },
      ],
      backgroundColor: '#202123',
      titleColor: '#fefefe',
      textColor: '#c1c3c9',
    },
    {
      title: 'Engage',
      image: `${crmEngage}`,
      additionalClass: 'portal',
      pattern1: portalSecondPattern1,
      pattern2: portalSecondPattern2,
      about: 'Cultivate a relationship with your members.',
      list: [
        {
          listItemTitle: 'Portal',
          listItemAbout:
            'Give your members a home. The member portal provides a branded experience where members can manage their account, make dining reservations, book amenities, and access events and exclusive content.',
        },
        {
          listItemTitle: 'Mobile app',
          listItemAbout:
            'Your members’ home while they roam. Provide a branded app with native functionality and push notification capabilities.',
        },
        {
          listItemTitle: 'Email',
          listItemAbout: 'Stay in touch. Send email and automated newsletters to maintain communication with your members.',
        },
        {
          listItemTitle: 'Messaging',
          listItemAbout: 'Send SMS messages as a more immediate form communication.',
        },
      ],
      backgroundColor: '#202123',
      titleColor: '#fefefe',
      textColor: '#c1c3c9',
    },
    {
      title: 'Transact',
      image: `${crmTransact}`,
      additionalClass: 'portal',
      pattern1: portalThirdPattern1,
      pattern2: portalThirdPattern2,
      about: 'Collect money faster through automatic billing, and card-on-file functionality.',
      list: [
        {
          listItemTitle: 'Payments',
          listItemAbout: 'Provide a quick and seamless checkout experience through secure card-on-file functionality.',
        },
        {
          listItemTitle: 'Digital Wallet ID',
          listItemAbout: 'Digitize your member ID cards and allow your members to check in and access controlled spaces.',
        },
      ],
      backgroundColor: '#202123',
      titleColor: '#fefefe',
      textColor: '#c1c3c9',
    },
  ]
  return (
    <>
      {memberPortalFeatures.map((feature, index) => (
        <FeatureSection key={index} index={index} {...feature} />
      ))}
    </>
  )
}
const OpsTeamsSection: FC = () => (
  <>
    {opsTeamsFeatures.map((feature, index) => (
      <FeatureSection key={feature.title} index={index} {...feature} />
    ))}
  </>
)
const FeatureSection: FC<IFeature> = ({
  title = '',
  image,
  additionalClass,
  pattern1,
  pattern2,
  pattern3,
  about,
  list,
  backgroundColor,
  titleColor,
  textColor,
  index,
  customComponent
}) => {
  const listElementColor = window.innerWidth < 576 ? textColor : titleColor
  return (
    <section className={`feature feature--${index + 1} feature--${additionalClass}`} style={{ backgroundColor: `${backgroundColor}` }}>
      <div className='ornament-block'>
        {pattern1 && <img src={pattern1} alt='pattern' className='ornament-block__image' />}
        {pattern2 && <img src={pattern2} alt='pattern' className='ornament-block__image' />}
        {pattern3 && <img src={pattern3} alt='pattern' className='ornament-block__image' />}
      </div>
      <div className='container'>
        <div className='feature__wrapper'>
          <div className={'feature-block feature-block--sticky'}>
            <h2 className='feature-block__title section-main-title' style={{ color: titleColor }}>
              {title}
            </h2>
            <p className='feature-block__text feature-block__text--desktop' style={{ color: textColor }}>
              {about}
            </p>
          </div>
          <div className='feature-block'>
            {
              image && (
                <div className={`feature-block-picture feature-block-picture--${index + 1}`}>
                  <img src={`${image}`} alt='dashboard' className='feature-block-picture__image feature-block-picture__image--primary' />
                </div>
              )
            }
            {
              customComponent && (
                <div className='feature-block-component'>
                  {customComponent}
                </div>
              )
            }
            <p className='feature-block__text feature-block__text--mobile' style={{ color: textColor }}>
              {about}
            </p>
            <ul className='feature-block-list'>
              {list.map((item, i) => (
                <li key={i} className='feature-block-list-item'>
                  <h6 className='feature-block-list-item__title' style={{ color: listElementColor }}>
                    {item.listItemTitle}
                  </h6>
                  <p className='feature-block-list-item__text' style={{ color: textColor }}>
                    {item.listItemAbout}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
export default { OpsTeamsSection, MemberPortalSection }
